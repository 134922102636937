export default {
    toast(vm,title="",message= "",variant='danger',toaster = 'b-toaster-top-center', append = false)
    {
        vm.$bvToast.toast(message, {
            title: title,
            toaster: toaster,
            variant:variant,
            solid: true,
            appendToast: append
        })
    }
}
