<template>
    <div class="publish-modal">
        <!-- Modal -->
        <div class="modal fade" id="publishModal" data-backdrop="static" data-keyboard="false"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg popup">
                <div class="modal-content">
                    <div class="modal-header justify-content-center position-relative">
                        <h5 class="modal-title" id="staticBackdropLabel">Select Categories</h5>
                        <button type="button" class="close d-inline-block" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-3 px-5">
                        <div class="category-tags-wrapper">
                            <div class="category_tag w-25">
                                <label class="ml-4  title mb-none">Category Tags</label>
                            </div>
                            <CountrySelect customClass="publishClassIcon" :isCustomHomePage="false"
                                           @updateCountyId="updateCountyId"/>
                        </div>
                        <hr class="line"/>
                        <div class="row pt-2 mb-3">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="form-label fst-italic">
                                        <img src="../../assets/images/globe.png"
                                             class="icon ml-2" alt="icon" width="20px">
                                        tages</label>
                                    <MultiSelect v-model="selectWorldCategories"
                                                 deselectLabel=""
                                                 selectLabel=""
                                                 :hideSelected="true"
                                                 :close-on-select="true"
                                                 :max="4"
                                                 placeholder="Search Category Tags" label="name" track-by="code"
                                                 :options="worldCategories" :multiple="true" :taggable="true"
                                                 @tag="addWorldCategories"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <label class="form-label fst-italic">
                                    <img v-if="selectedFlag.id!==1" :src="selectedFlag.flag"
                                         :class="defaultCountry.id===1 ? 'icon ml-2 country-icon-publish icon-grayscale':'icon ml-2 country-icon-publish '"
                                         alt="icon " width="20px">
                                    tages</label>
                                <MultiSelect v-model="selectCategories"
                                             deselectLabel=""
                                             selectLabel=""
                                             :max="4"
                                             :disabled="isDisabled"
                                             placeholder="Search Category Tags" label="name" track-by="code"
                                             :options="countryCategories" :multiple="true" :taggable="true"
                                             @tag="addCategories"/>
                            </div>
                        </div>
                        <div class="row pt-5">
                            <div class="col-12">
                                <div class="category_tag w-25">
                                    <label class="ml-4  title mb-none">Hashtags</label>
                                </div>
                                <hr class="line"/>
                            </div>
                        </div>
                        <div class="row pt-2 mb-3">
                            <div class="col-lg-6 ">
                                <div class="form-group">
                                    <label class="form-label fst-italic">
                                        <img src="../../assets/images/globe.png"
                                             class="icon ml-2" alt="icon" width="20px">
                                        tages</label>
                                    <MultiSelect v-model="selectWorldHashTag"
                                                 deselectLabel=""
                                                 selectLabel=""
                                                 :max="4"
                                                 placeholder="Search Story Tags" label="name" track-by="code"
                                                 :options="worldHashTags" :multiple="true" :taggable="true"
                                                 @tag="addWorldHashTags"/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label class="form-label fst-italic">
                                    <img v-if="selectedFlag.id!==1" :src="selectedFlag.flag"
                                         :class="defaultCountry.id===1 ? 'icon ml-2 country-icon-publish icon-grayscale':'icon ml-2 country-icon-publish '"
                                         alt="icon" width="20px">
                                    tages</label>
                                <MultiSelect v-model="selectHashTag"
                                             deselectLabel=""
                                             selectLabel=""
                                             :max="4"
                                             :disabled="isDisabled"
                                             placeholder="Search Story Tags" label="name" track-by="code"
                                             :options="countryHashTags" :multiple="true" :taggable="true"
                                             @tag="addHashTag"/>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer ">
                        <button type="button" @click="emitSubmitPublishModal" class="btn btn-success"
                                id="publish_button">{{isUpdate ? 'Update': 'Publish' }}
                        </button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import MultiSelect from 'vue-multiselect'
    import CountrySelect from '../CountrySelect/CountrySelect';
    import {mapGetters} from 'vuex';
    import $ from 'jquery';
    import 'bootstrap'
    import '@popperjs/core';
    import Helper from "../../helper";


    export default {
        name: 'PublishModal',
        components: {
            MultiSelect,
            CountrySelect
        },
        props: {
            isSubmit: Boolean,
            isUpdate: Boolean,
            type: String,
        },
        data() {
            return {
                selectWorldCategories: [],
                selectWorldHashTag: [],
                selectCategories: [],
                selectHashTag: [],
                isDisabled: true,
            }
        },
        computed: {
            ...mapGetters({
                worldCategories: "category/getWorldCategories",
                worldHashTags: "category/getWorldHashTags",
                countryCategories: "category/getCountryCategories",
                countryHashTags: "category/getCountryHashTags",
                selectedFlag: "country/selectedFlag",
                defaultCountry: "country/defaultCountry",
                cardData: "article/getCardData",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),

        },
        methods: {
            addWorldCategories(newTag) {
                let tag = newTag.replace(/^\s+|\s+$/gm, '');
                if (tag.length > 14) {
                    Helper.toast(this, 'Alert', 'Category tags cannot exceed 14 Characters in length.', 'danger')
                    return
                }
                const categoryObject = this.worldCategories.filter(x => x.name.split(" - ")[0].toLowerCase() === tag.toLowerCase())
                if (categoryObject.length > 0) {
                    let index = this.selectWorldCategories.findIndex(x => x.code === categoryObject[0].code)
                    if (index === -1) {
                        this.selectWorldCategories.push(categoryObject[0])
                    }
                    return;
                }
                let index = this.selectWorldCategories.findIndex(x => x.name.toLowerCase() === tag.toLowerCase())
                if (index === -1) {
                    this.selectWorldCategories.push({name: tag, code: -1})
                }
            },
            getWorldCategories() {
                this.$store.dispatch('category/getWorldCategories', 1)
            },
            addWorldHashTags(newTag) {
                let tag = newTag.replace(/^\s+|\s+$/gm, '');
                if (tag.length > 21) {
                    Helper.toast(this, 'Alert', 'Hashtags cannot exceed 21 characters in length.', 'danger')
                    return
                }
                const hashtagObject = this.worldHashTags.filter(x => x.name.split(" - ")[0].toLowerCase() === tag.toLowerCase())
                if (hashtagObject.length > 0) {
                    let index = this.selectWorldHashTag.findIndex(x => x.code === hashtagObject[0].code)
                    if (index === -1) {
                        this.selectWorldHashTag.push(hashtagObject[0])
                    }
                    return;
                }
                let index = this.selectWorldHashTag.findIndex(x => x.name.toLowerCase() === tag.toLowerCase())
                if (index === -1) {
                    this.selectWorldHashTag.push({name: tag, code: -1})
                }
            },
            getWorldHashTags() {
                this.$store.dispatch('category/getWorldHashTags', 1)
            },
            addHashTag(newTag) {
                let tag = newTag.replace(/^\s+|\s+$/gm, '');

                if (tag.length > 21) {
                    Helper.toast(this, 'Alert', 'Hashtags cannot exceed 21 characters in length.', 'danger')
                    return
                }
                const hashtagObject = this.countryHashTags.filter(x => x.name.split(" - ")[0].toLowerCase() === tag.toLowerCase())
                if (hashtagObject.length > 0) {
                    let index = this.selectHashTag.findIndex(x => x.code === hashtagObject[0].code)
                    if (index === -1) {
                        this.selectHashTag.push(hashtagObject[0])
                    }
                    return;
                }
                let index = this.selectHashTag.findIndex(x => x.name.toLowerCase() === tag.toLowerCase())
                if (index === -1) {
                    this.selectHashTag.push({name: tag, code: -1})
                }
            },
            addCategories(newTag) {
                let tag = newTag.replace(/^\s+|\s+$/gm, '');
                if (tag.length > 14) {
                    Helper.toast(this, 'Alert', 'Category tags cannot exceed 14 Characters in length', 'danger')
                    return
                }
                const categoryObject = this.countryCategories.filter(x => x.name.split(" - ")[0].toLowerCase() === tag.toLowerCase())
                if (categoryObject.length > 0) {
                    let index = this.selectCategories.findIndex(x => x.code === categoryObject[0].code)
                    if (index === -1) {
                        this.selectCategories.push(categoryObject[0])
                    }
                    return;
                }
                let index = this.selectCategories.findIndex(x => x.name === tag)
                if (index === -1) {
                    this.selectCategories.push({name: tag, code: -1})
                }
            },
            updateCountyId(id) {
                this.isDisabled = id === 1;
                if (id === 1) {
                    this.selectCategories = []
                    this.selectHashTag = []
                }

                this.getWorldCategories()
                this.getWorldHashTags()
                this.getCountryCategories(id)
                this.getCountryHashTags(id)
            },
            editArticleCategories(id){
                this.isDisabled = id === 1;
            },
            emitSubmitPublishModal: function () {
                if (this.defaultCountry.id === 1) {
                    if (this.selectWorldCategories.length === 0) {
                        console.log('please select world category')
                        return
                    }
                    if (this.selectWorldHashTag.length === 0) {
                        console.log('please select world hash tag')
                        return;
                    }
                }
                if (this.defaultCountry.id > 1) {
                    if (this.selectCategories.length === 0) {
                        console.log('please select world category')
                        return
                    }
                    if (this.selectHashTag.length === 0) {
                        console.log('please select world hash tag')
                        return;
                    }
                }

                this.$emit("emitSubmitPublishModal", true)
                this.$emit('emitSelectWorldCategories', this.selectWorldCategories)
                this.$emit('emitSelectWorldHashTag', this.selectWorldHashTag)
                this.$emit('emitSelectCategories', this.selectCategories)
                this.$emit('emitSelectHashTag', this.selectHashTag)
                this.closeModal()
            },
            getCountryCategories(id) {
                this.$store.dispatch('category/getCountryCategories', id)
            },
            getCountryHashTags(id) {
                this.$store.dispatch('category/getCountryHashTags', id)

            },
            checkCountry() {
                this.isDisabled = this.defaultCountry.id === 1
            },
            closeModal() {
                $('#staticBackdrop').modal('hide');
                let removeClass = $(".modal-backdrop");
                removeClass.removeClass('show');
                removeClass.removeClass('fade')
                removeClass.removeClass('modal-backdrop')
            },

            edit() {
                if (this.cardData.id !== "") {
                    let data = this.cardData;
                    this.selectWorldCategories = [];
                    this.selectWorldHashTag = [];
                    this.selectCategories = [];
                    this.selectHashTag = [];
                    data.article_category.map(x => {
                        if (x.category.country_id === 1) {
                            let index = this.selectWorldCategories.findIndex(y => y.code === x.category.id);
                            if (index === -1) {
                                let categoryName = x.category.category_name + ` - (${x.category.subscribers} subscribers)`;
                                this.selectWorldCategories.push({
                                    code: x.category.id,
                                    name: categoryName.trim()
                                })
                            }
                        }
                        if (x.category.country_id > 1) {
                            let index = this.selectCategories.findIndex(y => y.code === x.category.id);
                            if (index === -1) {
                                let categoryName = x.category.category_name + ` - (${x.category.subscribers} subscribers)`;
                                this.selectCategories.push({code: x.category.id, name: categoryName.trim()})
                            }

                            this.$store.dispatch("country/selectedFlag", x.category.country_id).then(()=>{
                                this.editArticleCategories(x.category.country_id);
                                $(".country-icon-publish").removeClass('icon-grayscale');
                            });

                        }

                        if(x.hashtag){
                            if (x.hashtag.country_id === 1) {
                                let index = this.selectWorldHashTag.findIndex(y => y.code === x.hashtag.id);
                                if (index === -1) {
                                    let hashTagName = x.hashtag.hash_tag_name + ` - (${x.hashtag.stories} stories)`
                                    this.selectWorldHashTag.push({code: x.hashtag.id, name: hashTagName.trim()})
                                }
                            }
                            if (x.hashtag.country_id > 1) {
                                let index = this.selectHashTag.findIndex(y => y.code === x.hashtag.id);
                                if (index === -1) {
                                    let hashTagName = x.hashtag.hash_tag_name + ` - (${x.hashtag.stories} stories)`
                                    this.selectHashTag.push({code: x.hashtag.id, name: hashTagName.trim()})
                                }
                                this.$store.dispatch("country/selectedFlag", x.hashtag.country_id).then(()=>{
                                    this.editArticleCategories(x.hashtag.country_id);
                                    $(".country-icon-publish").removeClass('icon-grayscale');
                                });

                            }
                        }


                    })
                }
            }
            ,

        },

        mounted() {
            this.edit();
            this.getWorldCategories()
            this.getWorldHashTags()
            if (this.defaultCountry) {
                this.getCountryCategories(this.defaultCountry.id)
                this.getCountryHashTags(this.defaultCountry.id)
            }
            this.checkCountry()
        }
        ,
        watch: {
            isUpdate(val) {
                this.isUpdate = val
            }
            ,
        }
    }
</script>

<style scoped>
    #publish_button {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
        padding: 0 14px;
        margin-right: 2px;
        border-radius: 5px;
    }

    .title {
        font-size: 15px;
    }

    .popup {
        width: 700px;

    }

    .line {
        width: 100%;
        margin-block: 0;
    }

    .category-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 15px;
    }

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>