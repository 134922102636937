<template>
    <div class="profile-nav mt-4">
        <div class="homeTabsWrapper profileNav">

            <b-tabs>
                <b-tab
                        @click="activeTab(navBarData[0])"
                        :key="navBarData[0].id+'_'+Math.random()"
                        :title="navBarData[0].title">
                    <b-card-text>
                        <articlePost :slug="slug"/>
                    </b-card-text>
                </b-tab>
                <b-tab
                        @click="activeTab(navBarData[1])"
                        :key="navBarData[1].id+'_'+Math.random()"
                        :title="navBarData[1].title">
                    <b-card-text>
                        <headingRequest :slug="slug"/>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import articlePost from "../../views/articlePost";
    import headingRequest from "../../views/headingRequest";

    export default {
        name: "ArticleNavbar",
        props: {
            navBarData: Array,
            slug:String
        },
        data() {
            return {
                componentPages: 1,
                title: ""
            };
        },
        methods: {
            activeTab(nav) {
                this.componentPages = nav.id;
            },
            articleTitle(val) {
                this.title = val
            }
        },
        components: {
            articlePost,
            headingRequest,
        },
    };
</script>
<style scoped>
    .active {
        background-color: transparent !important;
    }
</style>

