<template>
    <div class="hide">
        <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-8 mx-auto">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"
                />
                <div class="">
                    <h1 class="first-heading" :style="articleDetail.font_family ? 'font-family:'+articleDetail.font_family.title+'!important; color: '+articleDetail.font_family.title_color+'!important;':''">{{ articleDetail.title }}</h1>
                    <!--                    <h2 class="secondHeading mb-2">{{articleDetail.subTitle}}</h2>-->

                </div>

            </div>


            <div class="col-lg-8 col-md-12 col-sm-8  mx-auto sec-div">

                <!---Footer--->
                <div v-if="upcomingArticle !== 'UpcomingDetail'" class="social-icon  social-icon-blog d-flex  mt-sm-0">
                    <div class="mb-0 w-25 social-views">{{articleDetail.total_views_skim}} Views</div>
                    <div class="w-50 position-relative progress-bar-wrapper">
                        <ProgressBar :totalPercentage="articleDetail.total_percentage"/>
                    </div>
                </div>
                <ProgressBarPercentage :totalPercentage="articleDetail.total_percentage"/>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import ProgressBar from "../ArticleDetail/ProgressBar";
    import ProgressBarPercentage from "../ArticleDetail/ProgressBarPercentage";

    export default {
        name: "Hide",
        components: {userProfileDetail, ProgressBar,ProgressBarPercentage},
        props: {
            articleDetail: Object,
            upcomingArticle:String
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            profile() {
                this.$store.dispatch('auth/updateUserProfileDetail',this.cardData.user).then(()=>{
                    this.$router.push({name:'userProfile'})
                })
            },
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        }
    };
</script>


<style scoped>
    .blog-info .shareLinks {
        display: inline-flex;
        align-items: center;
    }

    .blog-info .blog-small-image {
        width: 20px;
    }

    .social-icon {
        font-size: 15px;
    }

    .iconShare {
        font-size: 5px;
    }

    .shareText {
        font-size: 12px;
        color: gray;
    }

    .firstHeading {
        margin-top: 7px;
        overflow-wrap: break-word;
        font-size: 32px;
        max-height: 140px;
        font-family: "Merriweather";
        font-weight: 400;
        list-style:1.2;
    }

    .secondHeading {
        overflow-wrap: break-word;
        font-size: 28px;
        height: 84px;
        margin-bottom: 0px !important;
        font-family: Oswald !important;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .box-image a {
        display: inline-block;

    }

    .box-image img {
        height: 721px;
    }

    .blog-info .social-icon .fa-brands {
        color: gray;
        width: 20px;
        font-size: 14px;
        text-align: center;
    }


    .social-icon .view {
        font-size: 16px;
        color: gray;
        top: -1.8em;
    }

    .socail-icon {
        align-items: flex-end;
    }

    .social-icon i {
        color: gray;
        width: 17px;
        font-size: 15px;
        text-align: center;
    }

    .socail-link {
        width: 25%;
        text-align: end;
    }

    .title {
        font-size: 12px;
    }

    .small-head .badge {
        font-size: 10px !important;
    }

    .badge-detail {
        display: flex;
        justify-content: center;
    }

    .social-icon {
        font-size: 14px;
        font-family: Roboto;
    }

    .social-icon-blog {
        justify-content: space-between;
    }

    @media only screen and (min-width: 280px) and (max-width: 319px) {
        .small-head .badge {
            font-size: 9px !important;
        }
    }
</style>