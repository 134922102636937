<template>
  <div class="wallet_modal">
    <div class="customize_modal">
      <!-- Modal -->
      <div
        class="modal fade pr-0"
        data-backdrop="static"
        data-keyboard="false"
        id="walletModalLong"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true">
      
        <div class="modal-dialog modal-lg wallet-dialog" role="document">
          <div class="modal-content" style="max-width: 950px">
            <!---header ----->
            <div class="modal-header p-3">
              <h4 class="modal-title wallet-top-heading m-auto">
                All Transactions
              </h4>
            </div>
            <!---modal-body---->
            <div class="modal-body">
                <WalletNavbar :navBarData="navBarArray"/>
            </div>

            <!-----footer----->

            <div class="modal-footer">
              <button
                type="button walletBtn"
                data-dismiss="modal"
                aria-label="Close"
                class="text-dark"
                id="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WalletNavbar from "../Navbars/WalletNavbar";
export default {
  name: "WalletModal",
  components: {
      WalletNavbar,
   
  },
  data() {
    return {
      navBarArray: [{id:1,title:"Wallet"} ,{id:2,title: "Paypal"}],
    };
  },
  methods: {

  },
  watch: {
  },
};
</script>

<style scoped>
.wallet-top-heading {
  font-size: 21px!important;
  font-weight: 500;
}
.wallet-dialog{
  max-width: 950px;
  width: 950px;
}
.modal-title{
  font-size: 17px;
}
#top-heading {
  font-size: 21px !important;
}

.model_card {
  column-gap: 10px;
}

.radio-text {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #212529;
}

.model_card img {
  width: 136px;
  height: 160px;
}

.modal_heading {
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: bold;
}

.para {
  font-size: 0.9rem;
  line-height: 1.6;
}

.modal-footer{
  padding: 0rem;
}
#button {
  background: none;
  color: #343a40;
  font: 12px!important;;
  height: 38px;
  letter-spacing: inherit;
  padding: 0 16px;
  text-transform: inherit;
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
  border:none;
}

.modal-footer::before{
  content: "";
  display: block;
  background-color: #dee2e6;
  width:100%;
  height: 1px;
}


.modal-footer {
  border-top: 1px solid transparent;
}


</style>
