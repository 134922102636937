<template>
    <div class="showNotes-modal">
        <!-- Modal -->
        <div class="modal fade pr-0" id="showNotes-modal" data-backdrop="static" data-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-scrollable mobile-model-width modal-dialog-sm-centered " role="document">
                <div class="modal-content show-modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Notes</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul class="show-list">
                            <li class="show-item" v-for="note in showNotes" :key="note.id+'_'+Math.random()">{{note.title}}</li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ShowNotesModal",
        props:{
            notes: {
                type: Array,
                Default : []
            }
        },
        computed:{
            showNotes() {
                return this.notes.slice().reverse()
            },
        }
    }
</script>

<style>
.showNotes-modal .show-list {
    padding-left: 26px;
}
</style>