<template>
    <div :id="element">
        <slot></slot>
    </div>
</template>
<script>
    // eslint-disable-next-line no-unused-vars
    import $ from "jquery";
    import "summernote/dist/summernote-lite.css";
    import "summernote/dist/summernote-lite.js";
    import Helper from "../helper";
    import {mapGetters} from "vuex";

    export default {
        name: "Summernote",
        components: {},
        data() {
            return {
                element: '',
                setupContext: null,
            };
        },
        props: {
            id:String,
            config: {
                type: Object,
            },
            ready: {
                type: Function,
            },
            tweetEmbed: String,
            isTweet: {
                type: Boolean,
                default: false
            },
            isTweetId:String
        },
        computed: {
            ...mapGetters({
                articleDetail: "article/getArticleDetails",
            }),
        },
        mounted() {
            if(this.id){
                this.element = this.id
            }else{
                this.element = `summernote_id`;
            }
            this.$nextTick(() => {
                let defaultConfig = {
                    //lang: "es-ES",
                    // placeholder: 'Enter Text',
                    height: 1000,
                    blockquoteBreakingLevel:2,
                    // width:730,
                    disableDragAndDrop: true,
                    focus: false,
                    buttons: {
                        h1: () => {
                            const summernote = this;
                            const ui = this.ui();
                            const buttonNew = ui.button({
                                contents: '<b>H1</b>',
                                container: false,
                                tooltip: 'Header 1',
                                click: function () {
                                    // context.invoke('editor.formatH1')
                                    summernote.summernote('editor.createRange');
                                    let h1 = summernote.summernote('editor.formatH1');
                                    summernote.summernote('editor.styleFromNode', h1);
                                }
                            });

                            return buttonNew.render();   // return button as jquery object
                        },
                        h2: () => {
                            const summernote = this;
                            const ui = this.ui();
                            const buttonNew = ui.button({
                                contents: '<b>H2</b>',
                                container: false,
                                tooltip: 'Header 2',
                                click: function () {
                                    // context.invoke('editor.formatH2')
                                    summernote.summernote('editor.createRange');
                                    let h2 = summernote.summernote('editor.formatH2');
                                    summernote.summernote('editor.styleFromNode', h2);
                                }
                            });

                            return buttonNew.render();   // return button as jquery object
                        },
                        plain: () => {
                            const summernote = this;
                            const ui = this.ui();
                            const buttonNew = ui.button({
                                contents: '<b>PT</b>',
                                container: false,
                                tooltip: 'plain text',
                                click: function () {
                                    // context.invoke('editor.formatH5')
                                    summernote.summernote('editor.createRange');
                                    let h5 = summernote.summernote('editor.formatH5');
                                    summernote.summernote('editor.styleFromNode', h5);
                                }
                            });

                            return buttonNew.render();   // return button as jquery object
                        },
                        quote: (context) => {
                            const ui = this.ui();
                            const button = ui.button({
                                contents: '<b>Quote</b>',
                                container: false,
                                tooltip: 'BlockQuote',
                                click: function () {
                                    context.invoke('editor.formatBlock', 'BlockQuote');
                                }
                            });
                            return button.render();
                        },
                        collapse: (context) => {
                            const ui = this.ui();
                            const button = ui.button({
                                contents: '<?xml version="1.0" standalone="no"?>\n' +
                                    '<!DOCTYPE SVG PUBLIC "-//W3C//DTD SVG 20010904//EN"\n' +
                                    ' "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">\n' +
                                    '<SVG version="1.0" xmlns="http://www.w3.org/2000/svg"\n' +
                                    ' width="18" height="18" viewBox="0 0 205.000000 219.000000"\n' +
                                    ' preserveAspectRatio="xMidYMid meet">\n' +
                                    '<metadata>\n' +
                                    'Created by potrace 1.16, written by Peter Selinger 2001-2019\n' +
                                    '</metadata>\n' +
                                    '<g transform="translate(0.000000,219.000000) scale(0.100000,-0.100000)"\n' +
                                    'fill="#000000" stroke="none">\n' +
                                    '<path d="M0 1095 l0 -1095 1025 0 1025 0 0 1095 0 1095 -1025 0 -1025 0 0\n' +
                                    '-1095z m1920 825 l0 -140 -895 0 -895 0 0 140 0 140 895 0 895 0 0 -140z m0\n' +
                                    '-1030 l0 -760 -895 0 -895 0 0 760 0 760 895 0 895 0 0 -760z"/>\n' +
                                    '<path d="M930 1013 l0 -438 -128 128 -128 127 -44 -45 -44 -45 169 -170 170\n' +
                                    '-170 75 0 75 0 168 168 167 167 -48 48 -47 47 -120 -120 c-66 -66 -123 -120\n' +
                                    '-128 -120 -4 0 -6 194 -5 430 l3 430 -67 0 -68 0 0 -437z"/>\n' +
                                    '</g>\n' +
                                    '</SVG>\n',
                                container: false,
                                tooltip: 'Collapse',
                                click: function () {
                                    var d = new Date();
                                    var n = d.getTime();
                                    var id = "texto" + n.toString();
                                    var node = document.createElement('span');
                                    var range = context.invoke('editor.createRange');
                                    if(range.toString().length!==0){
                                        node.innerHTML = '<br><collapse type="button" data-toggle="collapse" aria-expanded="true" style="padding:5px 10px;width:100%;background-color: #f6f6f6" data-target="#' + id + '">'+range.toString()+' &nbsp;<i class="fa fa-angle-double-down" style="float:right;font-size:12px;margin-top:10px;" aria-hidden="true"></i></collapse>' +
                                            '<div id="' + id +'" class="collapse show" style="padding:5px!important;border: lightgrey 1px solid !important;"><div><p>Insert Text here...</p></div></div><br><br>';
                                        context.invoke('editor.insertNode', node);
                                    }
                                    // var range = context.invoke('editor.createRange');
                                    // context.invoke('editor.pasteHTML', '<COLLAPSE>' + range.toString() + '</COLLAPSE>');

                                }
                            });
                            return button.render();
                        },
                        tweet: (context) => {
                            const ui = this.ui();
                            let vm = this;
                            const button = ui.button({
                                contents: '<i class="fas fa-laptop-code"></i>',
                                container: false,
                                tooltip: 'Tweet',
                                click: function () {
                                    console.log("test1",vm.isTweetId)
                                    vm.setupContext = context;
                                    if(vm.isTweetId === 'tweetModal'){
                                        $('#tweetModal').modal('show');
                                    }else{
                                        $('#'+vm.isTweetId).modal('show');
                                    }
                                }
                            });

                            return button.render();
                        },
                        f: (context) => {
                            const ui = this.ui();
                            let vm = this;
                            const button = ui.button({
                                contents: '<b>f</b>',
                                container: false,
                                tooltip: 'Tweet',
                                click: function () {
                                    if (vm.articleDetail.id === "") {
                                        return Helper.toast(vm, 'Alert', 'Please enter the title first', 'danger')
                                    }
                                    vm.setupContext = context;
                                    $('#fontModal').modal('show');
                                }
                            });

                            return button.render();
                        },
                        notes: () => {
                            const ui = this.ui();
                            let vm = this;
                            const button = ui.button({
                                contents: '<i class="far fa-file-alt" style="font-size: 17px"></i>',
                                container: false,
                                tooltip: 'Highlight Text',
                                click: function () {
                                    if (vm.articleDetail.id === "") {
                                        return Helper.toast(vm, 'Alert', 'Please enter the title first', 'danger')
                                    }
                                    $('#noteModal').modal('show');
                                }
                            });
                            return button.render();
                        },

                        skim: (context) => {
                            const summernote = this;
                            const ui = this.ui();
                            const buttonNew = ui.button({
                                contents: '<b>Skim</b>',
                                container: false,
                                tooltip: 'Highlight Text',
                                click: function () {
                                    var range = context.invoke('editor.createRange');
                                    summernote.summernote('editor.insertText', '<SKIM>' + range.toString() + '</SKIM>');
                                }
                            });
                            return buttonNew.render();
                        }

                    },
                    callbacks: {
                        onInit: () => {
                            this.$emit("ready", this);
                        },
                        onChange: (contents) => {
                            this.$emit("change", contents);
                        },
                        onEnter: () => {
                            this.$emit("enter");
                        },
                        onFocus: () => {
                            this.$emit("focus");
                        },
                        onBlur: () => {
                            this.$emit("blur");
                        },
                        onKeyup: (e) => {
                            let t = e.currentTarget.innerText;
                            $("#maxContentPost").text("characters: " + t.trim().length);
                            this.$emit("keyup", e);
                        },
                        onKeydown: (e) => {
                            let t = e.currentTarget.innerText;
                            if (t.trim().length >= 40000) {
                                if (
                                    e.keyCode !== 8 &&
                                    !(e.keyCode >= 37 && e.keyCode <= 40) &&
                                    e.keyCode !== 46 &&
                                    !(e.keyCode === 88 && e.ctrlKey) &&
                                    !(e.keyCode === 67 && e.ctrlKey)
                                )
                                    e.preventDefault();
                            }
                            this.$emit("keydown", e);
                        },
                        onPaste: (e) => {
                            let t = e.currentTarget.innerText;
                            let bufferText = (
                                (e.originalEvent || e).clipboardData || window.clipboardData
                            ).getData("Text");
                            e.preventDefault();
                            var maxPaste = bufferText.length;
                            if (t.length + bufferText.length > 40000) {
                                maxPaste = 40000 - t.length;
                            }
                            if (maxPaste > 0) {
                                document.execCommand(
                                    "insertText",
                                    false,
                                    bufferText.substring(0, maxPaste)
                                );
                            }
                            $("#maxContentPost").text("characters: " + t.length);
                            this.$emit("paste", e);

                        },
                        onImageUpload: (files) => {
                            var fileLimit = 5120;
                            var fileSize = files[0].size;
                            var fileSizeInKB = (fileSize / 1024);
                            if (fileSizeInKB > fileLimit) {
                                Helper.toast(this, 'Alert', 'The image size is too big. You can upload an Image maximum size limit of 2MB!!', 'danger')
                                return
                            }
                            const summernote = this;
                            var imgId = 'img-' + (Math.random() + 1).toString(36).substring(3);
                            let image = files[0];
                            // var reader = new FileReader();
                            // reader.onload = function (e) {
                            //     var imgNode = document.createElement('img');
                            //     imgNode.src = e.target.result;
                            //     imgNode.width = '515';
                            //     imgNode.id = imgId;
                            //     imgNode.style.cssText = "max-height: 600px;object-fit: contain;text-align: center;margin: auto;display: block";
                            //     imgNode.setAttribute('class', imgId);
                            //     summernote.summernote('insertNode', imgNode);
                            //     summernote.summernote('insertNode', document.createElement('br'));
                            //
                            // };
                            // reader.readAsDataURL(files[0]);
                            let data = new FormData();
                            data.append('image', image);
                            if (image) {
                                this.$store.dispatch('article/uploadEditorImage', data).then((response) => {
                                    var imgNode = document.createElement('img');
                                    imgNode.src = response.data.url;
                                    imgNode.id = imgId;
                                    imgNode.style.cssText = "max-height: 600px;object-fit: contain;text-align: center;margin: auto;display: block;padding: 10px 10px 0px 10px;width: 75%";
                                    imgNode.setAttribute('class', imgId);
                                    summernote.summernote('insertNode', imgNode);
                                    summernote.summernote('insertNode', document.createElement('br'));
                                    let ImageId = $("#" + imgId);
                                    ImageId.attr('src', response.data.url)
                                    ImageId.attr('class', 'img-' + response.data.randomno)
                                    ImageId.attr('id', 'img-' + response.data.randomno);
                                    this.$emit('fileUpload', true);
                                });
                            }
                        },
                    },
                };
                let config = Object.assign({}, defaultConfig, this.config);
                $("#" + this.element).summernote(config);
            });
            // if ($('div').attr('aria-expanded').val() == 'true') {
            //     alert('ss');
            //     $("div i").attr("class", "fa-angle-double-down");
            // } else {
            //     alert('ss');
            //     $("div i").attr("class", "fa-angle-double-up");
            // }
        },
        methods: {
            summernote(...args) {
                $("#" + this.element).summernote(...args);
            },
            ui() {
                return $.summernote.ui;
            },
        },

        watch: {
            isTweet: { //<-- Edited
                handler: function (val) {
                    if (val === true && this.tweetEmbed !== "") {
                        if (this.setupContext) {
                            // this.setupContext.invoke('editor.getLastRange');
                            // this.setupContext.invoke('editor.pasteHTML', this.tweetEmbed + '<br>');
                            const range = this.setupContext.invoke('editor.getLastRange');
                            range.pasteHTML(this.tweetEmbed);
                            this.setupContext.invoke('editor.insertNode',document.createElement('br'));
                            this.$emit("emitIsTweet", false)
                        }
                    }
                },
            },

    }
    };
</script>
<style scoped>
    .summernote-img {
        max-width: 100%;
    }

    .note-editor {
        margin-bottom: 100px !important;
    }

</style>
