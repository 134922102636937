<template>
    <div class="allPublished">
        <div class="row mt-4">
            <div :style="' left:'+mainHome+'px'"
                 class="menu-home  col-lg-4 col-m-12 col-sm-12 order-lg-last order-md-first">
                <div class="bottomScroll scrollid" id="isScrollId">
                    <CountrySelect :isCustom="getType" :isLogin="isLogin" @updateCountyId="updateCountyId"/>
                    <br>
                    <BlogCategory @emitActiveCategory="emitActiveCategory"/>
                </div>
            </div>
            <div class="main-home main-home-fixed col-lg-8 col-md-12 col-sm-12 order-lg-first order-md-last">
                <div v-if="(getType==='home' || getType==='customHomePage') && activeCategory.id!==0">
                    <br>
                    <Subscriber :isLogin="isLogin" :category="activeCategory" :subscriber="activeCategory.category_name"
                                :total="activeCategory.subscribers+' subscribers '"/>
                </div>
                <SubHeading category="Breaking" link=""/>
                <br>
                <div v-for="(cardData,index) in getAllBreakingArticles" :key="cardData.id+'_'+Math.random()">
                    <BlogCard :cardData="cardData" :isEdit="false" @emitSkimOpenModel="emitSkimOpenModel"/>
                    <hr v-if="index !== getAllBreakingArticles.length-1" class="hr-progressbar">

                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getAllPublishedForBreaking"
                ></infinite-loading>
            </div>
        </div>
        <SkimModal type="breaking" :isSkim="isSkim" :article="cardData" :isLogin="isLogin"
                   :getAuthUser="getAuthUser" @emitIsSkimData="emitIsSkimData"
                   @emitCloseModel="emitCloseModel"/>
    </div>
</template>

<script>
    // import Card from "../components/Cards/Card";
    import BlogCard from "../components/BlogCard/BlogCard";
    import SubHeading from "../components/Headings/SubHeading";
    import BlogCategory from '../components/BlogCategory/BlogCategory'
    import CountrySelect from '../components/CountrySelect/CountrySelect'
    import SkimModal from "../components/Modals/SkimModal";


    import InfiniteLoading from "vue-infinite-loading";
    import {mapGetters} from 'vuex';
    import $ from "jquery";
    import Subscriber from "../components/Subcribers/Subscriber";
    import helpers from "../helpers";

    export default {
        name: "published",
        metaInfo: {
            meta: [
                {name: 'viewport', content: 'width=device-width , initial-scale=1.0'},
            ],
            title: 'The Worded',
            titleTemplate: '%s | Breaking Article'
        },
        components: {
            BlogCard,
            SubHeading,
            InfiniteLoading,
            BlogCategory,
            CountrySelect,
            SkimModal,
            Subscriber
        },
        data() {
            return {
                // type: "home",
                windowWidth: 0,
                mainHome: 0,
                isSkim: false,
                cardData: {
                    id: 0,
                    slug: "",
                    total_percentage_skim: 0,
                    user:{
                        id:0,
                        slug:"",
                        profile_pic:"",
                        sec_1: '',
                        name: '',
                        total_user_subscribers: 0,
                        is_subscribe:false,
                        description: '',
                        social_user: {
                            fb_url: '',
                            twitter_url: '',
                            youtube_url: ''
                        },
                    }
                },
                skimData: []
                // category: {
                //     "id": 0,
                //     "user_id": 0,
                //     "country_id": 0,
                //     "category_name": "home",
                //     "level": 0
                // },
            };
        },
        computed: {
            ...mapGetters({
                defaultCountry: "country/defaultCountry",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                getAllBreakingArticles: 'article/getAllBreakingArticles',
                getAllBreakingPage: 'article/getAllBreakingPage',
                activeCategory: "category/getActiveCategory",
                getHomeCategory: "category/getHomeCategory",
                getType: "category/getType"
            })
        },
        created() {
            document.body.style = ''
        },
        methods: {
            getTrendingHashtag() {
                let payload = {
                    countryId: this.defaultCountry.id,
                    categoryId: this.activeCategory.id,
                    type: this.getType
                }
                this.$store.dispatch('category/getTrendingHashTags', payload);
            },
            getAllPublishedForBreaking($state) {
                let payload = {
                    page: this.getAllBreakingPage,
                    $state: $state,
                    countryId: this.defaultCountry.id,
                    categoryId: this.activeCategory.id,
                    type: this.getType
                }
                this.$store.dispatch('article/getAllBreakingArticles', payload)
            },
            emitActiveCategory() {
                this.$store.dispatch('category/updateActiveCategory', this.getHomeCategory).then(() => {
                    this.$router.push('/home')
                });

                // this.$store.dispatch("article/setCategoryPageNumber", 1);
                // this.$store.dispatch("article/setBreakingPageNumber", 1);
                // this.getAllPublishedForBreaking();
            },
            updateCountyId() {
                this.$store.dispatch('category/updateActiveCategory', this.getHomeCategory).then(() => {
                    this.$router.push('/home')
                });

                // this.emptyCategories();
                // this.selectedCategories(this.defaultCountry.id)
                // this.$store.dispatch("article/setBreakingPageNumber", 1);
                // this.$store.dispatch("article/setCategoryPageNumber", 1);
                // this.getTrendingHashtag();
                // this.getAllPublishedForBreaking()
            },
            selectedCategories(countryId) {
                let payload = {
                    id: countryId,
                    type: this.getType
                }
                this.$store.dispatch("category/getCategories", payload);
            },
            emptyCategories() {
                this.$store.dispatch("category/emptyCategories");
            },
            emptyTrendingHashtag() {
                this.$store.dispatch("category/emptyTrendingHashtag");
            },
            calculate() {
                this.windowWidth = window.innerWidth;
                let divWidth = $(".main-home");
                if (divWidth.offset()) {
                    this.mainHome = divWidth.offset().left + divWidth.width() + 55;
                }
            },
            emitSkimOpenModel(article) {
                this.isSkim = true;
                this.cardData = article;
                $('#skim-modal').modal('show');
            },
            emitCloseModel() {
                $('#skim-modal').modal('hide');
                this.isSkim = false
            },
            emitIsSkimData(data) {
                this.cardData.total_views_skim = data.total_views_skim
            },
            checkCardLine(index, total) {
                if (total >= 4 && index === 3) {
                    return false;
                }
                if (total === 3 && index === 2) {
                    return false;
                }
                if (total === 2 && index === 1) {
                    return false;
                }
                if (total === 1 && index === 0) {
                    return false;
                }
                return true;
            },
        },
        mounted() {
            this.emptyCategories();
            this.emptyTrendingHashtag();
            this.selectedCategories(this.defaultCountry.id)
            this.$store.dispatch("article/setCategoryPageNumber", 1);
            this.$store.dispatch("article/setBreakingPageNumber", 1);
            this.getTrendingHashtag();
            this.calculate();
            helpers.fixedSideBarCategory(this,'scrollid','nav-container','isScrollId')
            setInterval(() => {
                this.calculate();
            }, 100)
        }
    };
</script>

