export default {
    getCategories: state => {
        return state.categories;
    },
    getCustomCategories: state => {
        return state.customCategories
    },
    getWorldCategories: state => {
        return state.worldCategories;
    },
    getWorldHashTags: state => {
        return state.worldHashTags;
    },
    getCountryCategories: state => {
        return state.countryCategories;
    },
    getCountryHashTags: state => {
        return state.countryHashTags;
    },
    getCustomSelectedCategory: state => {
        return state.customSelectedCategory;
    },
    getActiveCategory: state => {
        return state.activeCategory;
    },
    getTrendingHashTags: state => {
        return state.trendingHashTags;
    },
    getSettingTrending: state => {
        return state.settingTrending;
    },
    getType: state => {
        return state.type;
    },
    getSettings: state =>{
        return state.settings
    },
    getMobileSettings: state =>{
        return state.settingsMobile
    },
    getHomeCategory: state =>{
        return state.homeCategory;
    }
}
