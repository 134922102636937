export default {
    GET_COUNTRIES(state, response) {
        state.countryOptions = [];
        state.countries = response.data.data;
        response.data.data.map(x => {
            state.countryOptions.push({id: x.id, text: x.name});
        })
    },
    GET_COUNTRIES_FOR_CUSTOM(state, response) {
        state.countryCustomOptions = []
        state.countries = response.data.data;
        response.data.data.map(x => {
            state.countryCustomOptions.push({id: x.id, text: x.name});
        })
    },
    SELECTED_FLAG(state,response){
        if(response.data.data.id !== 1){
            state.selectedFlag = response.data.data
        }
        // let index  = state.countries.findIndex(x => x.id === countryId);
        // if(index !== -1){
        //     state.selectedFlag = state.countries[index];
        // }
    },
    CUSTOM_SELECTED_FLAG(state,countryId){
        let index  = state.countries.findIndex(x => x.id === countryId);
        if(index !== -1){
            state.customSelectedFlag = state.countries[index];
        }
    },
    SELECTED_COUNTRY(state,response){
        if(response.data.data.id !== 1){
            state.selectedFlag = response.data.data
        }
    },
    SELECTED_COUNTRY_FOR_CUSTOM(state,response){
        state.customSelectedFlag = response.data.data
    },
    UPDATE_DEFAULT_COUNTRY(state){
        let country = JSON.parse(localStorage.getItem("country"));
        if (!country) {
            localStorage.setItem("country", JSON.stringify({ id: 1, text: "world" }));
            country = JSON.parse(localStorage.getItem("country"));
        }
        state.defaultCountry = country


    }
}