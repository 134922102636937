<template>
    <div id="blog">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
                <VideoOrImageFrame
                        :videoUrl="articleDetail.video_url"
                        :imageUrl="articleDetail.featured_image"
                        imageClass="default-view-image"
                        mainClass="box-image"/>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"/>
                <div class="blog-heading default-blog-heading">
                    <h1 class="first-heading" :style="articleDetail.font_family ? 'font-family:'+articleDetail.font_family.title+'!important; color: '+articleDetail.font_family.title_color+'!important;':''">{{ articleDetail.title }}</h1>
                </div>

                <!---Footer--->
                <div v-if="upcomingArticle !== 'UpcomingDetail'" class="social-icon social-icon-blog d-flex mt-lg-2  mt-md-4 mt-sm-4">
                    <div class="view mb-0 ">{{articleDetail.total_views_skim}} Views</div>
                    <div class="w-50 position-relative progress-bar-wrapper">
                        <ProgressBar :totalPercentage="articleDetail.total_percentage"/>
                        <div class="justify-content-end">
                            <ProgressBarPercentage :totalPercentage="articleDetail.total_percentage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";
    import ProgressBar from "../ArticleDetail/ProgressBar";
    import ProgressBarPercentage from "../ArticleDetail/ProgressBarPercentage";


    export default {
        name: "BlogPage",
        components: {
            userProfileDetail,
            VideoOrImageFrame,
            ProgressBar,
            ProgressBarPercentage,
        },
        props: {
            articleDetail: Object,
            upcomingArticle:String,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            shareArticle(article) {
                let url = 'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Ftheworded.com%2F&details/';
                window.location.href = url + article.slug;
                window.open('href', '_blank');
            },
            profile() {
                localStorage.setItem('profile', JSON.stringify(this.articleDetail.user))
                this.$router.push('/user/profile');
            },

        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
            isValidateButton() {
                return this.type === '' && this.commentTag === "" && this.comment === ""
            }
        },
    };
</script>

<style scoped>

</style>
