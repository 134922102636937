<template>
    <div class="insert-notes">
        <!-- Modal -->
        <div class="modal fade pr-0" :id="'articleSuggestionNote'+articleSuggestion.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg  modal-dialog-sm-centered mobile-model-width" role="document">
                <div class="modal-content insert-content-modal" style="overflow-y:auto;height: 600px;">
                    <div class="modal-header">
                        <h4 class="modal-title nodeTitle" id="exampleModalLabel">{{showTitle()}}</h4>
                        <button @click="clickButtonToCloseModal('articleSuggestionNote'+articleSuggestion.id)" type="button" class="close"
                            data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form method="post" action="javascript:void(0)" autocomplete="off">
                        <div class="modal-body" style="overflow-y:auto;max-height:450px">
                            <div class="form-group mb-0">
                                <textarea v-model="title" rows="3"  maxlength="1000"
                                    class="note form-control textarea"></textarea>
                                <div class="d-flex">
                                    <div class="characters">
                                        (Min: {{title.length}} Max: 1000)
                                    </div>
                                </div>
                                <button :disabled="isDisabled" @click="saveNote" type="submit" class="tweetBtn createBtn btn-secondary text-muted">{{
                                    isEdit ?
                                    'Update' : 'Save' }}
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer" style="padding-inline:16px;"></div>
                    </form>
                    <div v-if="articleSuggestion.notes.length >0">
                        <div data-v-3417ebcc="" class="commentDetail p-3">
                            <div data-v-3417ebcc="" v-for="note in articleSuggestion.notes" :key="note.id" class="d-flex align-items-start mb-2">
                                <div data-v-3417ebcc="" class="img">
                                    <img  :src="note.user.profile_pic" alt="user-profile" width="50px" class="rounded-circle comment-detail-img">
                                </div>
                                <div data-v-3417ebcc="" class="details w-100">
                                    <div data-v-3417ebcc="">
                                        <span data-v-3417ebcc="" class="fw-bold ml-2 username user-profile-name">{{note.user.name}}</span>
                                        <span data-v-3417ebcc="" class="dot comment-dot mr-1 ml-1"></span>
                                        <label data-v-3417ebcc="" class="top-text comment-date mb-0 ml-xxl-0 ml-lg-0 ml-sm-0">{{note.created_at}}</label>
                                    </div>
                                    <div  class="read-more mb-12 d-block" style="--line-clamp:3;">
                                        <p class="mb-2 right-text ml-2" style="font-weight:500;">{{note.note}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import {mapGetters} from "vuex";
export default {
    name: "ArticleSuggestionNoteModal",
    data() {
        return {
            isEdit: false,
            title: ''
        }
    },
    props:{
        articleSuggestion:Object
    },
    computed:{
        ...mapGetters({
            getAuthUser: 'auth/getAuthUser',
        }),
        isDisabled(){
            return this.title===''
        }
    },
    methods: {
        showTitle(){
            return 'Insert Note';
        },
        saveNote(){
            this.$emit('emitSubmitSuggestionNote',this.title);
            this.title='';
            // this.clickButtonToCloseModal(this.articleSuggestion.id);
        },
        clickButtonToCloseModal(id) {
            $("#articleSuggestionNote" + id).modal('hide');
            $(".modal-backdrop").remove();
            $('body').removeClass('modal-open');
        },
    },
    // mounted() {
    //     if(this.articleSuggestion.note){
    //         this.title = this.articleSuggestion.note.note
    //     }
    // },
    watch: {}
}
</script>

<style scoped>
.modal-footer {
    padding-top: 0;
}

.modal-body {
    padding-bottom: 0;
}

.insert-notes .textarea {
    word-break: break-word;
    resize: none;
}

.nodeTitle {
    font-size: 1.35rem;
    font-weight: 500;
}

.note {
    height: 150px;
}

.createBtn {
    float: right;
    background-color: #f5f5f5 !important;
    border: 1px solid #007bff !important;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    border-radius: 0.25rem
}

.note-list {
    clear: both;
}

.note-list .note-item {
    word-break: break-word;
}

.main-list {
    display: flex;
}

.list-id {
    font-weight: bold;
    color: black;
    cursor: pointer;
}

.copy-icon {
    display: none;
}

.copy-icon .copyIcon {
    color: #00C642;
}

.main-list:hover .copy-icon {
    display: inline-block !important;

}
</style>