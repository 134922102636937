<template>
  <div class="">
      <router-link :to="'/hashtag/'+trending.slug" @click.native.capture="getDetailHashTag">
            <div class="trending-inner pt-2 pb-2">
                <div class="trending-main">
                    <div class="trending-detail">
                        <div class="d-flex align-items-center justify-content-between">
                         <span  class="trending-text d-block">{{trending.stories}} Stories</span>
                         <span v-if="type==='customHomePage'" class="trending-text">Trending in {{trending.country.name}}</span>
                        </div>
                        <span class="trending-heading d-block">{{trending.hash_tag_name}}</span>
                    </div>
                </div>
            </div>
      </router-link>
    </div>
</template>

<script>
export default {
    name: "Trending",
    data(){
      return{
      }
    },
    props:{
      trending:Object,
        type:String
    },
    computed:{

    },
    methods:{
        getDetailHashTag(){
            this.$store.dispatch('article/setHashTagData',this.trending)
        }

    },
    mounted() {
    }
}
</script>


