import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
// import Pusher from "pusher-js";

import "@popperjs/core";
import 'bootstrap';
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
import App from './App.vue'
import router from './router'
import store from './store/store'
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// import Select2 from  'v-select2-component';
// Vue.component('Select2', Select2);
import VueMeta from 'vue-meta'
Vue.use(VueMeta);

import VueCookieLaw from 'vue-cookie-law'
Vue.use(VueCookieLaw)


Vue.config.productionTip = false

//set globe variable
let country = JSON.parse(localStorage.getItem("country"));
if (!country) {
  localStorage.setItem("country", JSON.stringify({ id: 1, text: "world" }));
  country = JSON.parse(localStorage.getItem("country"));
}
Vue.prototype.$country = country
Vue.prototype.$sharePath = window.location.origin+'/details/'
Vue.prototype.$sharePathUpcoming = window.location.origin+'/upcoming/'

//set country for custom home page//

let customCountry = JSON.parse(localStorage.getItem("customCountry"));
if (!customCountry) {
  localStorage.setItem("customCountry", JSON.stringify({ id: 1, text: "world" }));
  customCountry = JSON.parse(localStorage.getItem("customCountry"));
}

// let user = JSON.parse(localStorage.getItem("user"));
// if (!user) {
//   user = JSON.parse(localStorage.getItem("user"));
// }
// Vue.prototype.$customCountry = customCountry
// let path = "http://127.0.0.1:8000/api/v1/";
// let pusher = new Pusher("6abef2879d15fbea5be4", {
//   authEndpoint: path+'notification/subscribe/channel',
//   cluster: "ap2",
//   encrypted: true
// });
//
// console.log("check pusher data",pusher);
// // Pusher Subscribe Channel
// Vue.prototype.$channel = pusher.subscribe(
//     "the-worded"
// );


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
