<template>
    <div class="blog-category">
        <div class="row">
            <div class="col-12 col-sm-12 first-top-category">
                <BlogHeading headingClass="category" category="Categories"/>
            </div>
            <div class="wraper col-12 col-sm-12 pt-3 category-blog slider-category">
                <div id="category" class="home-page-category">
                    <VueSlickCarousel class="desktop-category d-none d-xxl-block  d-lg-block d-md-none d-sm-none " v-bind="getSettings" :arrows="true" v-if="getCategories.length>0">
                        <div class="category-slide-inner" v-for="categories in getCategories"
                             :key="categories.id+'_'+Math.random()">
                            <button v-for="category in categories" :key="category.id+'_'+Math.random()"
                                    @click="activeClass(category)"
                                    :class="activeCategory.id === category.id? 'category-btn active':'category-btn' ">
                                {{category.category_name}}
                            </button>
                        </div>
                    </VueSlickCarousel>
                      
                    <div class="mobile-category d-flex d-lg-none  d-md-block d-md-flex">
                   <div  class="category-slide-inner" v-for="category in getMobileCategories"
                             :key="category.id+'_'+Math.random()">
                            <button  @click="activeClass(category)"
                                     :class="activeCategory.id === category.id? 'category-btn active':'category-btn' ">
                                {{category.category_name}}
                            </button>
                        </div>
                </div>
                </div>
            </div>

            <!-- Category for Mobile start -->
            <div class="col-12 col-sm-12">
                <!-- <div class="mobile d-flex">
                   <div  class="category-slide-inner" v-for="category in getMobileCategories"
                             :key="category.id+'_'+Math.random()">
                            <button  @click="activeClass(category)"
                                     :class="activeCategory.id === category.id? 'category-btn active':'category-btn' ">
                                {{category.category_name}}
                            </button>
                        </div>
                </div> -->
            </div>
             <!-- Category for Mobile end -->


            <div class="col-12 col-sm-12  d-xxl-block d-lg-block d-sm-none d-none">
                <BlogHeading headingClass="trending" category="Trending"/>
                <div id="trending bg-info" class="trending-categories">
                    <VueSlickCarousel  :arrows="true" v-bind="getSettingTrending" v-if="trendingHashTags.length>0">
                        <div v-for="(trendingData) in trendingHashTags" :key="trendingData.id+'_'+Math.random()">
                            <Trending v-for="trending in trendingData" :key="trending.id+'_'+Math.random()"
                                      :trending="trending" :type="type"/>
                        </div>
                    </VueSlickCarousel>
                </div>
                <!-- <br><br> -->
            </div>
        </div>
    </div>
</template>

<script>
    import BlogHeading from "../BlogHeading/BlogHeading";
    import Trending from "../Trending/Trending";
    // import Pagination from 'vue-pagination-2'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


    import {mapGetters} from "vuex";

    export default {
        name: "BlogCategory",
        components: {
            BlogHeading,
            Trending,
            // Pagination,
            VueSlickCarousel
        },
        props: {
            type: String
        },
        data() {
            return {
                page: 1,
                perPage: 3,
                options: {
                    edgeNavigation: false
                },
                updatedCategories: [],
            }
        },
        computed: {
            ...mapGetters({
                getCategories: "category/getCategories",
                activeCategory: "category/getActiveCategory",
                trendingHashTags: "category/getTrendingHashTags",
                getSettingTrending: "category/getSettingTrending",
                getSettings: "category/getSettings",
                getMobileSettings: "category/getMobileSettings",
            }),
            getMobileCategories: function () {
                const categoriesArray = [];
                if (this.getCategories.length > 0) {
                    this.getCategories.map(categores => {
                        categores.map(x => {
                            categoriesArray.push(x)
                        })
                    })
                }
                return categoriesArray
            },
        },
        methods: {
            activeClass(category) {
                this.$store.dispatch("category/updateActiveCategory", category).then(() => {
                    this.$emit('emitActiveCategory', category);
                });
            },
            callback(page) {
                this.updatedCategories = []
                this.updatedCategories.push(...this.paginate(this.getCategories, page))
            },
            paginate(array, page_number) {
                return array.slice((page_number - 1) * this.perPage, page_number * this.perPage)
            },
        },
        mounted() {
            // if(this.getCategories.length>0){
            //     this.updatedCategories.push(...this.paginate(this.getCategories, 1))
            // }

        }

    }
</script>

<style>
.wraper {
padding-bottom: 24px;
    }
.mobile-category {
 overflow-x:scroll;
             
 }
.mobile-category::-webkit-scrollbar{
       /*overflow-x:scroll;*/
  display: none;
}
.mobile-category-list  .category-items{
        list-style: none;
        margin-right: 0!important;
}
.article-content{
    word-break: break-all;

}
</style>   