<template>
    <div class="user">
        <div class="mt-4" v-for="user in getSearchUsers" :key="user.id+'_'+Math.random()">
            <div class="col-9 col-xxl-5 col-lg-5 col-md-8  col-sm-8  mx-auto">
                <div class="row">
                    <div class="userImage col-3 col-xxl-3 col-lg-3 col-md-3 col-sm-3">
                        <router-link :to="{name:'userProfile',params:{slug:user.slug}}" @click.native.capture="profile(user)" target="_top"
                                     class="cursor">
                            <img v-if="user.profile_pic" alt="user-profile" class="user-profile mr-5 rounded-circle"
                                 :src="user.profile_pic"
                            >
                        </router-link>
                    </div>
                    <div class="userName col-9 col-xxl-9 col-lg-9  col-md-9 col-sm-9">
                        <router-link :to="{name:'userProfile',params:{slug:user.slug}}" @click.native.capture="profile(user)" target="_top"
                                     class="cursor">
                            <h3 class="mb-0" style="color: black">{{user.name}}</h3>
                        </router-link>
                        <div v-if="isLogin">
                            <Subscriber type="searchUser"
                                        :loginUserId="getAuthUser.id"
                                        :userId="user.id"
                                        :totalUserSubscribers="user.total_user_subscribers"
                                        :IsSubscribe="user.is_subscribe"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Subscriber from "../components/User/Subscriber";

    export default {

        name: 'user',
        data() {
            return {}
        },
        components: {Subscriber},
        computed: {
            ...mapGetters({
                getSearchUsers: "article/getSearchUsers",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        },
        methods: {
            profile(user) {
                this.$store.dispatch('auth/updateUserProfileDetail', user).then(() => {
                    this.$router.push({name: 'userProfile',params:{slug:user.slug}})
                })
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>