<template>
    <div class="">
        <!-- Modal -->
        <div class="modal fade pr-0" id="headingRequestModel" data-backdrop="static" data-keyboard="false"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg popup">
                <div class="modal-content">
                    <div class="modal-header justify-content-center position-relative">
                        <h5 class="modal-title" id="staticBackdropLabel">Select Categories</h5>
                        <button type="button" class="close d-inline-block" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-3 px-5">
                        <div class="category-tags-wrapper">
                            <div class="category_tag w-25">
                                <label class="ml-4  title mb-none">Category Tags</label>
                            </div>
                            <CountrySelect customCountrySelectId="headingRequestCountryId" customClass="publishClassIcon" :isCustomHomePage="false"/>
                        </div>
                        <hr class="line"/>
                        <div class="row pt-2 mb-3">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="form-label fst-italic">
                                        <img src="../../assets/images/globe.png"
                                             class="icon ml-2" alt="icon" width="20px">
                                        tages</label>
                                    <MultiSelect v-model="selectWorldCategories"
                                                 deselectLabel=""
                                                 selectLabel=""
                                                 :hideSelected="true"
                                                 :close-on-select="true"
                                                 :max="4"
                                                 placeholder="Search Category Tags" label="name" track-by="code"
                                                 :options="worldCategories" :multiple="true" :taggable="true"
                                                 />
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <label class="form-label fst-italic">
                                    <img v-if="selectedFlag.id!==1" :src="selectedFlag.flag"
                                         :class="defaultCountry.id===1 ? 'icon ml-2 country-icon-publish icon-grayscale':'icon ml-2 country-icon-publish '"
                                         alt="icon " width="20px">
                                    tages</label>
                                <MultiSelect v-model="selectCountryCategories"
                                             deselectLabel=""
                                             selectLabel=""
                                             :max="4"
                                             placeholder="Search Category Tags" label="name" track-by="code"
                                             :options="countryCategories" :multiple="true" :taggable="true"
                                             />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="emitSubmitHeadingRequest()" class="btn btn-success"
                                id="publish_button">save
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import CountrySelect from '../CountrySelect/CountrySelect';
    import MultiSelect from 'vue-multiselect'
    import {mapGetters} from 'vuex';
    import 'bootstrap'
    import '@popperjs/core';
    import Helper from "../../helper";
    import $ from "jquery";


    export default {
        name: 'HeadingRequestCategoriesModal',
        components: {
            MultiSelect,
            CountrySelect
        },
        props: {
        },
        data() {
            return {
                selectWorldCategories: [],
                selectCountryCategories: [],
                isDisabled: true,
            }
        },
        computed: {
            ...mapGetters({
                worldCategories: "category/getWorldCategories",
                countryCategories: "category/getCountryCategories",
                selectedFlag: "country/selectedFlag",
                defaultCountry: "country/defaultCountry",
                cardData: "article/getCardData",
            }),
        },
        methods: {
            getWorldCategories() {
                this.$store.dispatch('category/getWorldCategories', 1)
            },
            getCountryCategories(id) {
                this.$store.dispatch('category/getCountryCategories', id)
            },
            emitSubmitHeadingRequest(){
                    if (this.selectWorldCategories.length === 0 && this.selectCountryCategories.length ===0) {
                        return Helper.toast(this, 'Alert', 'Please add category first', 'danger')
                    }
                this.$emit('emitSelectWorldCategories',this.selectWorldCategories);
                this.$emit('emitSelectCountryCategories',this.selectCountryCategories);
                this.closeModal()
            },
            closeModal() {
                $('#headingRequestModel').modal('hide');
                let removeClass = $(".modal-backdrop");
                removeClass.removeClass('show');
                removeClass.removeClass('fade')
                removeClass.removeClass('modal-backdrop')
            },
        },
    }
</script>

<style scoped>
    #publish_button {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
        padding: 0 14px;
        margin-right: 2px;
        border-radius: 5px;
    }

    .title {
        font-size: 15px;
    }

    .popup {
        width: 700px;

    }

    .line {
        width: 100%;
        margin-block: 0;
    }

    .category-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 15px;
    }

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>