<template>
    <div class="blogHeading">
        <div class="child-tag mb-1">
            <h5 class="m-0 heading">
                <b>
                    <p :class="headingClass">{{category}}</p>
                </b>
            </h5>
        </div>
    </div>

</template>

<script>
    export default {
        name: "BogHeading",
        props: {
            category: String,
            headingClass:String
        }
    }
</script>

<style>
    .heading .category {
        color: #aaa;
    }
    .heading .trending {
        color: #aaa;
    }

    .category {
        font-weight: 500;
        font-size: 16px !important;
    }

    .category::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #eaeef1;
        display: inline-block;
        position: absolute;
        margin-top: 11px;
        margin-left: 5px;
    }
    .trending {
        font-weight: 500;
        font-size: 16px !important;
    }

    .trending::after {
        content: "";
        width: 100%;
        height: 2px;
        background-color: #eaeef1;
        display: inline-block;
        position: absolute;
        margin-top: 11px;
        margin-left: 5px;
    }
</style>