<template>
  <div class="image-modal">
    <!-- Button trigger modal -->
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="upload-image-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
               
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body imagesUploadPopup">
                <div class="row justify-content-between">
                <div class="col-12 col-md-3 col-sm-12">
                 <div class="bf-info">
                    <input type="file" />
                    <div>
                        <img src="../../assets/images/no-image.png"  alt="upload-image"  width="100%">
                    </div>
                 </div>
                </div>
                <div class="col-12 col-md-3 col-sm-12">
                    <div>
                        <input type="file" />
                        <div>
                            <img src="../../assets/images/no-image.png" alt="upload-image" width="100%">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-sm-12">
                    <div>
                        <input type="file" />
                        <div>
                            <img src="../../assets/images/no-image.png" alt="upload-image" width="100%">
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 col-sm-12">
                    <div>
                        <input type="file" />
                        <div>
                            <img src="../../assets/images/no-image.png" alt="upload-image" width="100%">
                        </div>
                    </div>
                </div>
            </div>
                <div class="mt-5 parallax-effect-wrapper position-relative">
                    <div class="parallax-one">
                        <img src="https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/clouds4.png" alt="" />
                    </div>
                    <div class="parallax-two">
                        <img src="https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/mountain.png" alt="" />
                    </div>
                    <div class="parallax-three">
                        <img src="https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/forest.png" alt="" />
                    </div>
                    <div class="parallax-four">
                        <img src="https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/bridge.png" alt="" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>
  </div>
</template>

<script>
    import $ from 'jquery';
export default {
    data(){
      return{
          scrollTop:0
      }
    },
    mounted() {
        $('.imagesUploadPopup').scroll(function () {
            this.scrollTop = $(this).scrollTop();
            // this.scrollTop = scrollTop;
            $('.parallax-one').css('transform','translateY('+ (-this.scrollTop+(0.20*this.scrollTop))+'px)');
            $('.parallax-two').css('transform','translateY('+ (-this.scrollTop+(0.18*this.scrollTop))+'px)');
            $('.parallax-three').css('transform','translateY('+ (-this.scrollTop+(0.17*this.scrollTop))+'px)');
            $('.parallax-four').css('transform','translateY('+ (-this.scrollTop+(0.20*this.scrollTop))+'px)');
        })
    }
}
</script>

<style scoped>
    .imagesUploadPopup {
        max-height: 600px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .parallax-effect-wrapper {
        overflow: hidden;
    }
    .parallax-effect-wrapper .parallax-one,
    .parallax-effect-wrapper .parallax-two,
    .parallax-effect-wrapper .parallax-three,
    .parallax-effect-wrapper .parallax-four {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;

        background-attachment: fixed;
    }
    .parallax-effect-wrapper img {
        width: 100%;
        height: 700px;
        object-fit: cover;
        position: relative;
        overflow: hidden;
    }
    .parallax-effect-wrapper .parallax-two,
    .parallax-effect-wrapper .parallax-three,
    .parallax-effect-wrapper .parallax-four {
        position: absolute;
        top: 0;
    }
    /*.parallax-one {*/
    /*    background-image: url('https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/clouds.png');*/
    /*}*/
    /*.parallax-two {*/
    /*    background-image: url('https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/forest.png');*/
    /*}*/
    /*.parallax-three {*/
    /*    background-image: url('https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/mountain.png');*/
    /*}*/
    /*.parallax-four {*/
    /*    background-image: url('https://raw.githubusercontent.com/EugeneBurlak/assets/master/scroll-parallax/bridge.png');*/
    /*}*/
</style>