<template>
    <div class="row top-heading-request">
        <div class="col-12 mb-4">
            <SubHeading category='Heading Requests' link=""/>
        </div>
        <div class="col-12 col-lg-10 col-md-12 col-sm-12 m-auto position-relative">
            <div v-for="suggestions in allHeadingRequest" :key="suggestions.id+'_'+Math.random()">
                <HeadingRequestList :suggestion="suggestions" :getAuthUser="getAuthUser" :headingDetail="true"/>
            </div>
        </div>
    </div>
</template>
<script>
    import HeadingRequestList from "../components/HeadingRequest/HeadingRequestList";
    import SubHeading from "../components/Headings/SubHeading";
    import {mapGetters} from "vuex";
    export default {
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
        ],
        title: 'The Worded',
        titleTemplate: '%s | Heading Request'
    },
        name:"HeadingRequestDetail",
        data(){
            return {
                headingDetail:Boolean
            }
        },
        components:{
            HeadingRequestList,SubHeading
        },
        computed:{
            ...mapGetters({
                allHeadingRequest: "article/getAllHeadingRequest",
                getAuthUser: 'auth/getAuthUser',
            })
        },
        created() {
            document.body.style = ''
        },
        methods:{
            getHeadingRequest(){
                this.$store.dispatch("article/getHeadingRequestAccordingToUser");
            },

        },
        mounted() {
            this.getHeadingRequest();
        }
    }
</script>
<style scoped>
.top-heading-request{
    margin-top: 70px;
}
</style>