export default {
    getAuthUser: state=>{
        return state.user;
    },
    getUserProfile: state=>{
        return state.profile;
    },
    getIsUserLogin : state=>{
        return state.isUserLogin;
    },
    getAllUsers : state=>{
        return state.allUsers
    }
}
