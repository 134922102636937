<template>
    <div class="row">
        <div class="col-12 col-sm-12 offset-lg-4 col-lg-8  text-end">
            <div class="progress-date d-flex  float-right">
                <div v-for="(percentage,index) in totalPercentage" :key="Math.random()+index" :title="getTitle(index)" >
                    <span v-if="percentage>0" :class="getClass(index)">{{percentage}}%</span>
                    <span v-else :class="getClass(index)"><i style="font-size: 20px;" :class="getClassPercentage(index)"></i></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ProgressBarPercentage",
        props: {
            totalPercentage: Array
        },
        methods: {
            getClassPercentage(index) {
                if (index === 0) {
                    return 'fa-solid fa-minus text-success'
                }
                if (index === 1) {
                    return 'fa-solid fa-minus text-dark'
                }
                if (index === 2) {
                    return 'fa-solid fa-minus text-danger'
                }

            },
            getClass(index) {
                if (index === 0) {
                    return 'text-success'
                }
                if (index === 1) {
                    return 'neutral'
                }
                if (index === 2) {
                    return 'text-danger'
                }

            },
            getTitle(index) {
                if (index === 0) {
                    return 'Supportive'
                }
                if (index === 1) {
                    return 'Neutral'
                }
                if (index === 2) {
                    return 'Critique'
                }

            },

        }
    }

</script>
<style scoped>
.progress-date i{
        width: 17px;
        font-size: 15px;
        text-align: center;
    }
    .neutral{
        color: darkgray;
    }
</style>