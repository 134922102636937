<template>
    <div class="hide">
        <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-8 mx-auto">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"/>
                <div class="">
                    <h1 class="first-heading" :style="articleDetail.font_family ? 'font-family:'+articleDetail.font_family.title+'!important; color: '+articleDetail.font_family.title_color+'!important;':''">{{ articleDetail.title }}</h1>
                    <!-- <h2 class="secondHeading mb-2">{{articleDetail.subTitle}}</h2>-->
                </div>

                <!---Footer--->
                <div v-if="upcomingArticle !== 'UpcomingDetail'"  class="social-icon social-icon-blog d-flex mt-lg-3 mt-sm-0">
                    <div class="view mb-0 w-25">{{articleDetail.total_views_skim}} Views</div>
                    <div class="w-50 position-relative progress-bar-wrapper">
                        <ProgressBar :totalPercentage="articleDetail.total_percentage"/>
                        <ProgressBarPercentage :totalPercentage="articleDetail.total_percentage"/>
                    </div>

                </div>
            </div>
            <!---image---->
            <div class="col-lg-12 col-sm-12 col-12">
                <VideoOrImageFrame
                        :videoUrl="articleDetail.video_url"
                        :imageUrl="articleDetail.featured_image"
                        imageClass="default-view-image"
                        mainClass="oversize-image box-image"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import ProgressBar from "../ArticleDetail/ProgressBar";
    import ProgressBarPercentage from "../ArticleDetail/ProgressBarPercentage";


    export default {
        name: "OverSized",
        components: {userProfileDetail, VideoOrImageFrame, ProgressBar,ProgressBarPercentage},
        props: {
            articleDetail: Object,
            upcomingArticle:String,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            profile() {
                this.$store.dispatch('auth/updateUserProfileDetail',this.cardData.user).then(()=>{
                    this.$router.push({name:'userProfile'})
                })
            },
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        }
    };
</script>
<style scoped>
    .firstHeading {
        margin-top: 7px;
        overflow-wrap: break-word;
        font-size: 32px;
        max-height: 140px;
        font-family: "Merriweather";
        font-weight: 400;
        list-style: 1.2;
    }

    .social-icon-blog {
        justify-content: space-between;
    }
</style>
