<template>
    <div class="privacyPolicy" style="margin-top: 50px;">
        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="top-heading">
                    <h1>RnD Tree, Inc. Privacy Policy</h1>
                    <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://www.theworded.com (the “Site”).</p>
                </div>

                <div class="info">
                    <h3>PERSONAL INFORMATION WE COLLECT</h3><br/>
                    <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
                    </p>
                    <p>
                        We collect Device Information using the following technologies:</p>
                    <p class="cookies">- “Cookies” are data files that are placed on your device or computer and often
                        include an
                        anonymous unique identifier. For more information about cookies, and how to disable cookies,
                        visit <a
                                href="http://www.allaboutcookies.org" target="_blank">http://www.allaboutcookies.org</a>.
                    </p>
                    <p>- “Log files” track actions occurring on the Site, and collect data including your IP address,
                        browser type,
                        Internet service provider, referring/exit pages, and date/time stamps.</p>
                    <p>- “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how
                        you browse
                        the Site.</p>
                    <p>
                        Additionally when you make a purchase or attempt to make a purchase through the Site,
                        we collect certain information from you, including your name, billing address, shipping address,
                        payment
                        information (including credit & debit card numbers, bank account information),
                        email address, and phone number. We refer to this information as “Order Information.”
                    </p>
                    <p>
                        When we talk about “Personal Information” in this Privacy Policy, we are talking both about
                        Device
                        Information and Order Information.
                    </p>
                </div>

                <div class="info">
                    <h3>HOW DO WE USE YOUR PERSONAL INFORMATION?</h3><br/>
                    <p>
                        We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:
                    </p>
                    <p>Communicate with you;</p><br/>
                    <p>Screen our orders for potential risk or fraud; and</p><br/>
                    <p>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</p>
                    <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p><br/>
                    <p>We use Device Information (your IP address & cookies) to show you relevant data that you would be most interested in, save your selection history, and show relevant/targeted ads.</p>
                    <p>To be in accordance with CANSPAM, we agree to the following:</p><br/>

                    <li>Not use false or misleading subjects or email addresses.</li>
                    <li>Identify the message as an advertisement in some reasonable way.</li>
                    <li>Include the physical address of our business or site headquarters.</li>
                    <li>Monitor third-party email marketing services for compliance, if one is used.</li>
                    <li>Honor opt-out/unsubscribe requests quickly.</li>
                    <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>

                </div>

                <div class="info">
                    <h3>SHARING YOUR PERSONAL INFORMATION</h3><br/>
                    <p class="stripe">
                        We share your Personal Information with third parties to help us use your Personal Information, as described above.  For example, we use Stripe to power our online store--you can read more about how Stripe uses your Personal Information here:  https://stripe.com/privacy.  We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.</p>
                    <p>
                        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                    </p>
                    <p>We may share your personal information with various ad networks to serve you relevant/targeted ads.</p>
                </div>

                <div class="info">
                    <h3>BEHAVIOURAL ADVERTISING</h3><br/>
                    <p>
                        As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.<p>
                    </p>
                    <p>You can opt out of targeted advertising by:</p>
                    <p>    Facebook - https://www.facebook.com/settings/?tab=ads</p>
                    <p>        Google - https://www.google.com/settings/ads/anonymous</p>
                    <p> Bing - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</p><br/>
                    <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/.</p>
                </div>

                <div class="info">
                    <h3>DO NOT TRACK</h3><br/>
                    <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>
                    <p>
                        Users in Europe and Worldwide will see a accept cookies banner at the bottom of the page which they should accept in order to get full benefits of the website.
                    </p>
                </div>

                <div class="info">
                    <h3>YOUR RIGHTS</h3><br/>
                    <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
                    <p>
                        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
                    </p>
                </div>

                <div class="info">
                    <h3>DATA RETENTION</h3><br/>
                    <p>
                        When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                    </p>
                </div>

                <div class="info">
                    <h3>MINORS</h3><br/>
                    <p>The Site is not intended for individuals under the age of 13.</p>
                </div>

                <div class="info">
                    <h3>CHANGES</h3><br/>
                    <p>
                        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                    </p>
                </div>

                <div class="info-contact">
                    <h3>CONTACT US</h3><br/>
                    <p>
                        For more information about our privacy practices, if you have questions, or if you would like to
                        make a
                        complaint,
                        please contact us by e-mail at <a href="mailto:support@theworded.com">support@theworded.com</a> or by mail using the details provided
                        below:
                    </p>
                    <p>16192 Coastal Highway</p>
                    <p>Lewes, Delaware 19958</p>
                    <p>United States</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helpers from "../helpers";

    export default {
        name: "privacyPolicy",
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Privacy Policy'
        },
        data(){
            return {
                previousScroll: 0,
            }
        },
        created() {
            document.body.style = ''
        },
        mounted() {
            helpers.fixedSideBarCategory(this,'scrollNav','nav-container','',2)
        }
    }
</script>

<style>


</style>