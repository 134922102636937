<template>
    <div class="user-comment">
        <div class="modal fade pr-0 small-comment-section pt-5" tabindex="-1" role="dialog"
             aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class=" justify-content-center position-relative header-comment">
                        <button type="button" class="close d-inline-block button-comment" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times close-icon"></i>
                        </button>
                    </div>
                    <div class="Box">
                        <div class="row mt-1">
                            <div class="col-2 col-sm-2">
                                <div class="userImage">
                                    <img
                                            class="rounded-circle"
                                            :src="getAuthUser.profile_pic"
                                            width="40"
                                            height="40"/>
                                </div>
                            </div>
                            <div class="col-10 col-sm-10">
                                <div class="responseInput form-group">
                                    <input
                                            v-validate="'required'"
                                            v-model="comment"
                                            name="comment"
                                            type="text"
                                            placeholder="Add Response"
                                            autocomplete="off"
                                            class="form-control  comment-input"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <form @submit.prevent="validateBeforeSubmit" class="" >
                                    <div class="class1">
                                        <div class="readioBtn">
                                            <RadioButton :customTags="customTags" :customIds="customIds" :isModal="true" :commentTag="commentModalTag" @emitCommentTagModal="emitCommentTagModal"/>
                                        </div>
                                        <!-- <div class="selectOption">
                                            <select v-model="type" class="form-control form-control-sm">
                                                <option value="1">General</option>
                                            </select>
                                        </div> -->
                                    </div>
                                     <div class="commentBtn bg-light modal-comment-button">
                                        <button :disabled="isValidateButton" type="submit" class="write-button btn-write shadow-none d-inline-block  float-right pl-1 pr-1 comment-button mt-3" style="width
                                        95px;!important">
                                            Comment
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import RadioButton from "../RadioButton/RadioButton";
    import $ from "jquery";
    import Helper from "../../helper";

    export default {
        name: "userCommentModal",
        components: {
            RadioButton,
        },
        props:{
            getAuthUser:Object,
            articleDetail:Object,
            customTags: Array
        },
        data(){
            return {
                comment: "",
                commentModalTag: "",
                type: "1",
                customIds: [{id:1,name:'supportive'},{id:2,name: 'neutral'}, {id:3,name:'critique'}]
            }
        },
        computed:{
            isValidateButton() {
                return this.type === '' && this.commentModalTag === "" && this.comment === ""
            }
        },
        methods:{
            emptyForm() {
                this.type = '1'
                this.commentModalTag = ""
                this.comment = ""
            },
            validateBeforeSubmit() {
                if (this.commentModalTag === "") {
                    Helper.toast(this, 'Alert', 'Please select a comment tag from Supportive, Neutral, or Critique', 'danger')
                    return
                }
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let payload = {
                            comment_tag: this.commentModalTag,
                            comment: this.comment,
                            type: this.type,
                            is_reply: false,
                            article_id: this.articleDetail.id,
                        }
                        this.$store.dispatch('article/addComment', payload).then(()=>{
                            this.emptyForm()
                            this.closeModal('small-comment-section')
                        });
                    }
                });
            },
            closeModal(id) {
                $("." + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            emitCommentTagModal(val) {
                this.commentModalTag = val
            },
        }
    }
</script>

<style>
.user-comment .group {
     display: flex;
     align-items:flex-end;
     margin-bottom: -7px;
     justify-content: flex-end;
 }
</style>