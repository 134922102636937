<template>
    <div class="search">
        <div class="searchNav-mobile">
            <searchNav :componentPages="componentPages" :navBarData="navBarArray"/>
        </div>
    </div>
</template>

<script>
    import searchNav from '../components/Navbars/SearchNavbar.vue';
    import helpers from "../helpers";

    export default {
        name: 'searchUser',
        metaInfo: {
            meta: [
                {name: 'viewport', content: 'width=device-width , initial-scale=1.0'},
            ],
            title: 'The Worded',
            titleTemplate: '%s | Search'
        },
        components: {searchNav},
        data() {
            return {
                previousScroll: 0,
                componentPages:1,
                navBarArray: [{id: 1, title: "Articles"}, {id: 2, title: "user"}],
            };
        },
        created() {
            document.body.style = ''
        },
        methods: {
            getArticleAndUser() {
                let search = localStorage.getItem('search');
                let searchTab = localStorage.getItem('searchTab');
                if(searchTab){
                    this.componentPages = parseInt(searchTab);
                }
                if (search) {

                    this.$store.dispatch('article/getSearchedArticleAndUser', {search: search})
                }
            }
        },
        mounted() {
            this.getArticleAndUser();
            helpers.fixedSideBarCategory(this,'scrollNav','nav-container','',2)
        }
    }


</script>
<style scoped>
    .search {
        padding-top: 60px;
    }
</style>

