<template>
    <div class="edit-detail">
        <h3 class="text-center mb-4 mt-md-5 mt-sm-2">Edit Details</h3>
        <div class="row pr-lg-5 pl-lg-5 pr-sm-0 pl-sm-0">
            <div class="col-xxl-4 col-md-4 col-sm-12">
                <UploadImage :image="getAuthUser.profile_pic" @updateImage="updateImage"
                             @updateImageFile="updateImageFile"/>
            </div>

            <div class="col-xxl-8 col-md-8 col-sm-12">
                <form method="post" action="" class="upload-form">
                    <div class="form-group">
                        <input @keyup="updateName" :maxlength="maxNameLength" type="text"
                               :value="getAuthUser.name"
                               class="form-control"
                               placeholder="Name"
                        />
                        <div class="float-right">{{getMaxNameLength()}}</div>
                    </div>

                    <div class="form-group">
                        <input
                                @keyup="updateDescription"
                                type="text"
                                :maxlength="maxDescriptionLength"
                                class="form-control"
                                placeholder="Description"
                                :value="getAuthUser.description"
                        />
                        <div class="float-right">{{getMaxDescriptionLength()}}</div>
                    </div>

                    <div class="form-group mb-4">
                        <input
                                @keyup="updateFacebookUrl"
                                type="text"
                                class="form-control"
                                placeholder="Facebook Link"
                                :value="getAuthUser.social_user?getAuthUser.social_user.fb_url:''"
                        />
                    </div>

                    <div class="form-group mb-4">
                        <input
                                @keyup="updateTwitterUrl"
                                type="text"
                                class="form-control"
                                placeholder="Twitter Link"
                                :value="getAuthUser.social_user?getAuthUser.social_user.twitter_url:''"
                        />
                    </div>

                    <div class="form-group mb-4">
                        <input
                                @input="updateYoutubeUrl"
                                type="text"
                                class="form-control"
                                placeholder="Youtube Link"
                                :value="getAuthUser.social_user?getAuthUser.social_user.youtube_url:''"
                        />
                    </div>

                    <div class="form-group">
                        <input
                                @input="updateGlobalUrl"
                                type="text"
                                class="form-control"
                                placeholder="Link"
                                :value="getAuthUser.social_user?getAuthUser.social_user.global_url:''"
                        />
                    </div>
                    <div class="form-group">
                    <textarea v-model="profileBgColor" maxlength="1000" class="note form-control textarea" placeholder="Paste your gradient css code here." style="resize:none;">

                    </textarea>
                    </div>
                </form>
            </div>
        </div>

        <h3 class="text-center mt-2">Cover Photo</h3>
        <div class="row full-cover-box" v-if="!isToggle">
            <div class="col-sm-12 col-md-12">
                <div class="cover-image">
                    <UploadCoverImage :cover="getAuthUser.sec_1?getAuthUser.sec_1:''"
                                      @UpdateCoverImageFile="UpdateCoverImageFile"
                                      @UpdateCoverImageUrl="UpdateCoverImageUrl"/>
                </div>
            </div>
        </div>
        <div class="text-center mb-5">
            <button @click="updateProfile" class="btn text-center btn-outline-dark px-4 mt-4">Save</button>
        </div>
    </div>
</template>

<script>
    import UploadImage from "../components/UploadImage/UploadImage";
    import UploadCoverImage from "../components/UploadImage/UploadCoverImage";
    import {mapGetters} from "vuex"
    import helpers from '../helpers';

    export default {
        name: "settings",
        metaInfo: {
            meta: [
                {name: 'viewport', content: 'width=device-width , initial-scale=1.0'},
            ],
            title: 'The Worded',
            titleTemplate: '%s | Setting'
        },
        components: {UploadImage, UploadCoverImage},
        data() {
            return {
                profileBgColor:'',
                previousScroll: 0,
                maxNameLength: 20,
                maxDescriptionLength: 160,
                isToggle: false,
                imageSrc: "",
                imageSrcSec1: "",
                dropzoneSingle: {
                    url: 'https://httpbin.org/post',
                    thumbnailWidth: 150,
                    maxFiles: 1,
                    maxFilesize: 0.5,
                    addRemoveLinks: true,
                    dictDefaultMessage: "Drop your Avatar here",
                    headers: {"My-Awesome-Header": "header value"}
                },
                dropzoneOptions: {
                    url: 'https://httpbin.org/post',
                    thumbnailWidth: 150,
                    maxFilesize: 0.5,
                    headers: {"My-Awesome-Header": "header value"}
                },
                isProfileImageBase64: ""

            };
        },
        computed: {
            ...mapGetters({
                getAuthUser: 'auth/getAuthUser'
            })
        },
        created() {
            document.body.style = ''
        },
        methods: {
            updateName(e) {
                this.getAuthUser.name = e.target.value;
            },
            updateDescription(e) {
                this.getAuthUser.description = e.target.value;
            },
            updateFacebookUrl(e) {
                this.getAuthUser.social_user.fb_url = e.target.value
            },
            updateTwitterUrl(e) {
                this.getAuthUser.social_user.twitter_url = e.target.value
            },
            updateYoutubeUrl(e) {
                this.getAuthUser.social_user.youtube_url = e.target.value
            },
            updateGlobalUrl(e) {
                this.getAuthUser.social_user.global_url = e.target.value
            },
            updateProfileImage(e) {
                this.getAuthUser.profile_pic = e.target.files[0];
            },
            updateSec1(e) {
                this.getAuthUser.sec_1 = e.target.files[0];
            },
            updateSec2(e) {
                this.getAuthUser.sec_2 = e.target.files[0];
            },
            updateSec3(e) {
                this.getAuthUser.sec_3 = e.target.files[0];
            },
            updateFullCover(e) {
                this.getAuthUser.sec_1 = e.target.files[0]
            },
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            afterComplete(file) {
                console.log(file);
            },
            updateProfile() {
                let formData = new FormData();
                formData.append('name', this.getAuthUser.name ? this.getAuthUser.name : "")
                formData.append('description', this.getAuthUser.description ? this.getAuthUser.description : "")
                formData.append('profile_pic', this.imageSrc !== "" ? this.imageSrc : this.getAuthUser.profile_pic)
                formData.append('sec_1', this.imageSrcSec1 !== "" ? this.imageSrcSec1 : this.getAuthUser.sec_1)
                formData.append('sec_2', this.getAuthUser.sec_2 ? this.getAuthUser.sec_2 : "")
                formData.append('sec_3', this.getAuthUser.sec_3 ? this.getAuthUser.sec_3 : "")
                formData.append('bg_color', this.profileBgColor !== '' ? this.profileBgColor.replace(/[\r\n]/gm, '') : this.profileBgColor)
                formData.append('fb_url', this.getAuthUser.social_user.fb_url ? this.getAuthUser.social_user.fb_url : "")
                formData.append('twitter_url', this.getAuthUser.social_user.twitter_url ? this.getAuthUser.social_user.twitter_url : "")
                formData.append('global_url', this.getAuthUser.social_user.global_url ? this.getAuthUser.social_user.global_url : "")
                formData.append('youtube_url', this.getAuthUser.social_user.youtube_url ? this.getAuthUser.social_user.youtube_url : "")
                this.$store.dispatch('auth/updateUserProfile', formData).then(response => {
                    helpers.successMessages(this, response);
                }).catch(error => {
                    helpers.errorMessage(this, error);
                })
            },
            getMaxNameLength() {
                if (this.getAuthUser.name) {
                    return parseInt(this.maxNameLength - this.getAuthUser.name.length)
                }
                return this.maxNameLength
            },
            getMaxDescriptionLength() {
                if (this.getAuthUser.description) {
                    return parseInt(this.maxDescriptionLength - this.getAuthUser.description.length)
                }
                return this.maxDescriptionLength
            },
            updateImage(file) {
                this.getAuthUser.profile_pic = file;
            },
            updateImageFile(file) {
                this.imageSrc = file
            },
            UpdateCoverImageFile(file) {
                this.imageSrcSec1 = file
            },
            UpdateCoverImageUrl(file) {
                this.getAuthUser.sec_1 = file;
            },
            updateBgColor(e){
                return e.target.value;
            }
        },
        mounted() {
            helpers.fixedSideBarCategory(this,'scrollNav','nav-container','',2)
            this.profileBgColor = this.getAuthUser.bg_color ? this.getAuthUser.bg_color : ""
        }

    };
</script>

<style scoped>
    .edit-detail h3 {
        font-size: 25px;
    }

    .select-type {
        text-align: center;
        font-size: 14px;
    }

    #badge {
        border: 2px solid #17a2b8;
        background-color: transparent;
        color: black;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 12px;
    }

    .type {
        font-size: 0.9rem;
    }

    .upload-image {
        height: 242px;
        width: 87%;
        background-color: #eee;
        margin-left: 80px;
    }

    .upload {
        height: 242px;
        width: 87%;
        background-color: #eee;
        margin-left: 80px;
    }

    .dropzone {
        min-height: 320px
    }

    .button {
        background-color: transparent;
    }

</style>
