<template>
    <div class="caption">
        <!-- Modal -->
        <div class="modal fade pr-0" id="captionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title captionTitle" id="exampleModalLabel">Insert caption</h4>
                        <button @click="clickButtonToCloseModal('captionModal')" type="button" class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="validateBeforeSubmit">
                        <div class="modal-body">
                            <input type="hidden" id="captionImageId" value="">

                            <div class="form-group">
                                <input
                                        v-model='caption'
                                        v-validate="'required|max:120'"
                                        :class="{'input': true, 'text-danger': errors.has('caption') }"
                                        name="caption"
                                        maxlength="120"
                                        value=""
                                        autocomplete="off"
                                        id="captionEmbed"
                                        type="text" placeholder="caption" class="uploadInput col-12"/>
                                <i v-show="errors.has('caption')" class="fa fa-warning"></i>
                                <span v-show="errors.has('caption')"
                                      class="text-danger">{{ errors.first('caption') }}</span>
                                <i v-if="isValidate" class="fa fa-warning"></i>
                                <span v-if="isValidate"
                                      class="text-danger">{{ message }}</span>
                                <br>
                                <p>Captions will show under the image after the article gets published.</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="captionBtn btn-secondary text-muted">Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";

    export default {
        name: "captionModal",
        props: {
            articleDetail: Object,
            isCaption: Boolean
        },
        data() {
            return {
                caption: "",
                isValidate: false,
                message: ''
            }
        },
        methods: {
            clickButtonToCloseModal(id) {
                this.$emit('closeCaption', false)
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let captionEmbed = $('#captionEmbed').val();
                        let captionImageId = $('#captionImageId').val();
                        let payload = {
                            image_id: captionImageId,
                            caption: captionEmbed
                        }
                        this.$emit('emitImageCaption', payload);

                    }
                });
            },
            // captionShow(captions) {
            //     let index = captions.findIndex(x => x.image_id === $('#captionImageId').val())
            //     if (index !== -1) {
            //         this.caption = captions[index].caption;
            //         $('#captionEmbed').val(captions[index].caption);
            //     }
            //
            // }
        },
        // watch: {
        //     'articleDetail.captions': {
        //         handler: function (after) {
        //             if(after !== undefined){
        //                 if (after.length > 0) {
        //                     this.captionShow(after)
        //                 }
        //             }
        //         },
        //         deep: true
        //     }
        // }
    }
</script>

<style scoped>

    /*.modal-dialog {*/
    /*    width: 700px;*/
    /*}*/

    .captionTitle {
        font-size: 1.35rem;
        font-weight: 500;
    }

    .uploadInput {
        border-bottom: 1px solid gray;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0.25rem;
        height: calc(2.19rem + 2px);
        padding: 0.375rem 0.75rem;
        outline: none;
    }

    .captionBtn {
        background-color: #f5f5f5 !important;
        border: 1px solid #007bff !important;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        border-radius: 0.25rem
    }
</style>