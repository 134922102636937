export default {
    allArticles:[],
    articles: [],
    breaking: [],
    upcoming: [],
    subscribed: [],
    searchArticles:[],
    searchUsers:[],
    draftAndUpcoming: [],
    draftPage: 1,
    publishPage: 1,
    publishByUserPage: 1,
    breakingPage: 1,
    subscribedPage: 1,
    upcomingPage: 1,
    getHashtagPage:1,
    publishedArticles: [],
    publishedArticlesByUser: [],
    allBreakingArticles: [],
    allSubscribedArticles: [],
    allUpcomingArticles:[],
    allHashtagArticles:[],
    allHeadingRequests:[],
    allArticleSuggestion:[],
    articleDetail: {
        id:'',
        captions:[],
        notes:[],
        article_category:[],
        article_suggestions:[{id:"",content:"",selected_suggestion:{content:"",status:""}}],
        custom_tags: [],
        bg_color: '',
        status: {
            id:0
        },
        user:{
            id:0,
            slug:"",
            profile_pic:"",
            sec_1: '',
            name: '',
            total_user_subscribers: 0,
            is_subscribe:false,
            description: '',
            social_user: {
                fb_url: '',
                twitter_url: '',
                youtube_url: ''
            },
        }
    },
    allCommentsSendPage:1,
    allCommentsSend:[],
    allCommentsReceivedPage:1,
    allCommentsReceived:[],
    writerReplyCommentsSendPage:1,
    writerReplyCommentsSend:[],
    writerReplyCommentsReceivedPage:1,
    writerReplyCommentsReceived:[],
    articleSearch:[],
    customTags:[],
    articleDetailCommentsPageNumber:1,
    articleDetailComments:[],
    upcomingCount:0,
    checkSuggestionNotification:0,
    hashTagData : localStorage.getItem('hashTagData') ? JSON.parse(localStorage.getItem('hashTagData')) : {slug:"",hash_tag_name:""},
    cardData : localStorage.getItem('cardData') ? JSON.parse(localStorage.getItem('cardData')) : {id:"",slug:"",captions:[],notes:[],article_category:[],is_heading:'',},
    categoryArticle: {
        page:1,
        articles: [],
        breaking: [],
        upcoming: [],
        subscribed: []
    },
    userDetail:{
        sec_1: '',
        slug:'',
        profile_pic: '',
        name: '',
        total_user_subscribers: 0,
        description: '',
        social_user: {
            fb_url: '',
            twitter_url: '',
            youtube_url: ''
        },
        bg_color:""
    },
    paginate: {
        page: 1,
        links: {
            first: null,
            last: null,
            prev: null,
            next: null
        },
        meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: null,
            per_page: 16,
            to: 1,
            total: 1,
            custom: null
        },
    }
}