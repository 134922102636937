<template>
    <div class="archive">

        <!-- Modal -->
        <div class="modal fade pr-0" id="archive" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header pt-2 pb-0 subscribe-head">
                      <button data-dismiss="modal" class="close-btn-sub"> <i class="fa-solid fa-xmark"  aria-label="Close"></i></button>
       
                    </div>
                    <div class="modal-body pt-0">
                        <p class="archive-par mb-0">
                            Are you sure you want to archive this Article?
                        </p>
<!--                        <p class="archive-par mb-0">-->
<!--                            Are you sure you want to archive this Article? All submitted Heading Requests and-->
<!--                            Endorsements will be refunded.-->
<!--                        </p>-->
                    </div>
                    <div class="modal-footer subscribe-footer pt-0">
                    <div class="text-center">
                        <button @click="archiveArticle" type="button" class="archive-article archive-button ">Archive Article</button>
                        <button data-dismiss="modal" type="button" class="cancel  archive-article archive-button">Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    export default {
        name: "Archive",
        props: {
            cardData: Object,
        },
        data(){
            return{

            }
        },
        methods:{
            archiveArticle(){
                let payload = {
                    slug: this.cardData.slug
                }
                this.$store.dispatch('article/archiveArticle',payload).then(()=>{
                    this.closeModal('archive');
                });
            },
            closeModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
        }
    }
</script>

<style>
    .archive-par {
        font-weight: normal;
        font-family: sans-serif;
        text-align: center;
        font-size: 25px;
        color: #575757;
        margin: 25px 0;
        padding: 0;
        line-height: 40px;
        border: 1px;
    }

    .archive-button {
        border: 1px;
        border-radius: 10px;
        padding: 10px 32px;
        margin: 10px 5px;
        text-align: center;
        cursor: pointer;
        font-size: 17px;
        font-weight: 500;
        color: white;
    }

    .archive-article {
        background-color: rgb(221, 107, 85);
    }

    .cancel {
        border: 1px;
        background-color: #C1C1C1;
    }
</style>