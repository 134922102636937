<template>
    <table class="blog-info mb-2 w-100 pt-2 mt-3 mt-lg-0">
        <tbody>
        <tr :class="getAuthUser.id === articleDetail.user.id?'display-for-mobile login-date':'display-for-mobile logout-date'"
            :style="!isLogin?'align-item:center':''">
            <div class="profile-image d-flex" style="align-items: center;">
                <td class="blog-small-image">
                    <router-link :to="{name:'userProfile',params:{slug:articleDetail.user.slug}}"
                                 @click.native.capture="profile" target="_top" class="cursor">
                        <img :src="articleDetail.user.profile_pic"
                             class="rounded-circle blog-img" alt="profile"/>

                    </router-link>
                </td>
                <td class="pl-1  profileSocialData" :style="!isLogin?'align-item:center':''">
                    <div>
                        <router-link :to="{name:'userProfile',params:{slug:articleDetail.user.slug}}" target="_top" class="user cursor"
                                     @click.native.capture="profile">
                            <label class=" mb-0 mr-1 profile-user-name">{{articleDetail.user.name}}</label>
                        </router-link>
                        <div class="connect-popup  small-screen-popup d-inline d-sm-none">
                        <span id="elementTooltip"
                              v-if="(articleDetail.user.social_user.fb_url || articleDetail.user.social_user.youtube_url || articleDetail.user.social_user.twitter_url|| articleDetail.user.social_user.global_url)"
                              class="connect-icon">
                            <i @click="checkToggle" tar class="fa fa-link mr-1" aria-hidden="true"></i>
                            <span class="popup-body mr-1"
                                  v-if="isToggle && (articleDetail.user.social_user.fb_url || articleDetail.user.social_user.youtube_url || articleDetail.user.social_user.twitter_url|| articleDetail.user.social_user.global_url)">
                                <span class="text-center">
                                    <a v-if="articleDetail.user.social_user.fb_url"
                                       :href="articleDetail.user.social_user.fb_url" class=""
                                       target="_blank">
                                        <img src="../../assets/images/facebook.png" height="17px" alt="no-image">
                                    </a>
                                    <a v-if="articleDetail.user.social_user.twitter_url"
                                       :href="articleDetail.user.social_user.twitter_url"
                                       target="_blank" class="">
                                        <img src="../../assets/images/twitter.png" height="17px" alt="no-image">
                                    </a>
                                    <a v-if="articleDetail.user.social_user.youtube_url"
                                       :href="articleDetail.user.social_user.youtube_url"
                                       class="" target="_blank">
                                        <img src="../../assets/images/icons8-youtube-50.png" height="17px"
                                             alt="no-image">
                                    </a>
                                    <a v-if="articleDetail.user.social_user.global_url"
                                       :href="articleDetail.user.social_user.global_url"
                                       target="_blank" class="">
                                        <img src="../../assets/images/globe-icon.png" height="17px" alt="no-image">
                                    </a>
                                </span>
                            </span>
                        </span>
                            <!--popup-->

                        </div>

                        <div class="d-flex">
                            <div class="subscribe subscriber-extra-small-mobile d-sm-none">
                                <span class=" current-date mr-2">
                                    <label class="data-label mb-0 " v-on:click="checkDateToggle" style="cursor: pointer;"><sup><i
                                                v-if="articleDetail.article_last_updated" class="fa fa-asterisk"
                                                aria-hidden="true"></i></sup>{{showDate()}}
                                    </label>
                                </span>
                                <Subscriber v-if="isLogin"  :type="type" :loginUserId="getAuthUser.id"
                                            :userId="articleDetail.user.id"
                                            :totalUserSubscribers="articleDetail.user.total_user_subscribers"
                                            :IsSubscribe="articleDetail.user.is_subscribe"
                                            @emitUpdateUserSubscriber="emitUpdateUserSubscriber"
                                />
                               
                            </div>
                        </div>
                    </div>

                    <div class="connect-popup d-inline large-popup">
                      <span id="elementTooltip"
                            v-if="(articleDetail.user.social_user.fb_url || articleDetail.user.social_user.youtube_url || articleDetail.user.social_user.twitter_url|| articleDetail.user.social_user.global_url)"
                            class="connect-icon" v-on:click="checkToggle">
                          <i class="fa fa-link mr-1" aria-hidden="true"></i>
                        <span class="popup-body mr-1"
                              v-if="isToggle && (articleDetail.user.social_user.fb_url || articleDetail.user.social_user.youtube_url || articleDetail.user.social_user.twitter_url|| articleDetail.user.social_user.global_url)">
                            <span class="text-center">
                                <a v-if="articleDetail.user.social_user.fb_url"
                                   :href="articleDetail.user.social_user.fb_url" class=""
                                   target="_blank">
                                    <img src="../../assets/images/facebook.png" height="17px" alt="no-image">
                                </a>
                                <a v-if="articleDetail.user.social_user.twitter_url"
                                   :href="articleDetail.user.social_user.twitter_url"
                                   target="_blank" class="">
                                    <img src="../../assets/images/twitter.png" height="17px" alt="no-image">
                                </a>
                                <a v-if="articleDetail.user.social_user.youtube_url"
                                   :href="articleDetail.user.social_user.youtube_url" class=""
                                   target="_blank">
                                    <img src="../../assets/images/icons8-youtube-50.png" height="17px" alt="no-image">
                                </a>
                                <a v-if="articleDetail.user.social_user.global_url"
                                   :href="articleDetail.user.social_user.global_url"
                                   target="_blank" class="">
                                    <img src="../../assets/images/globe-icon.png" height="17px" alt="no-image">
                                </a>
                            </span>
                        </span>
                       </span>
                        <!--popup-->

                    </div>

                </td>
                <td></td>
                <td></td>
                <div v-if="isLogin" class="subscribe subscriber-mobile">
                    <Subscriber :type="type" :loginUserId="getAuthUser.id" :userId="articleDetail.user.id"
                                :totalUserSubscribers="articleDetail.user.total_user_subscribers"
                                :IsSubscribe="articleDetail.user.is_subscribe"
                                @emitUpdateUserSubscriber="emitUpdateUserSubscriber"
                    />
                </div>
            </div>
            <div class="skim-date publishDate">
                <td class="show-date "> 
                    <span class=" current-date">
                   
                    <label class="data-label mb-0 float-right" v-on:click="checkDateToggle"
                           style="cursor: pointer;"><sup><i v-if="articleDetail.article_last_updated"
                                                            class="fa fa-asterisk" aria-hidden="true"></i></sup>{{showDate()}}
                    </label> 
                    </span>
                </td>
                <td></td>
            </div>

        </tr>
        </tbody>
    </table>

</template>

<script>
    import Subscriber from "../User/Subscriber";

    export default {
        data() {
            return {
                lastUpdate: false,
                isToggle: false,
                isDateToggle: false,
            }
        },
        components: {
            Subscriber,
        },
        props: {
            isLogin: {
                type: Boolean,
                default: false
            },
            articleDetail: {
                type: Object
            },
            getAuthUser: {
                type: Object
            },
            type:{
                type:String
            },
            // isUpdated:Boolean
            isUpdated: {
                type: Boolean,
                default: true
            },
        },
        methods: {
            profile() {
                this.$store.dispatch('auth/updateUserProfileDetail', this.articleDetail.user).then(() => {
                    this.$router.push({name: 'userProfile', params: {slug: this.articleDetail.user.slug}})                })
            },
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            checkDateToggle() {
                this.isDateToggle = !this.isDateToggle;
            },
            showDate() {
                if (this.lastUpdate && this.articleDetail.article_last_updated && this.isDateToggle) {
                    return this.articleDetail.article_last_updated;
                }
                return this.articleDetail.created_at
            },
            emitUpdateUserSubscriber(user){
                this.articleDetail.user.is_subscribe = user.is_subscribe
                this.articleDetail.user.total_user_subscribers = user.total_user_subscribers
            }

        },
        mounted() {
            this.lastUpdate = this.isUpdated;
        },
        // watch:{
        //     isUpdated(){
        //         this.lastUpdate = this.isUpdated
        //     }
        // }


    }
</script>

<style>
    .heading-icons {
        display: inline-flex;
        justify-content: flex-end;
        column-gap: 10px;
    }

    .connect-popup span {
        color: gray;
    }

    .popup-body {
        background: #F5F5F5;
        padding: 5px;
        /*min-width: 85px;*/
        transition: .3s;
        z-index: 1;
        position: absolute;
        top: 33px;
    }

    .popup-body span a {
        margin-right: 10px;
    }

    .profileSocialData {
        display: flex;
        align-items: center;

    }

    .blog-small-image a .blog-img {
        height: 36px;
        width: 36px;
    }

    .user .profile-user-name {
        font-size: 13px;
        color: grey;
        font-family: "Roboto";
        font-weight: 400;
    }

    .current-date .data-label {
        font-size: 13px;
        font-family: "Roboto";
        font-weight: 400;
       /* float: right;*/
    }
</style>