<template>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="16.000000pt" height="16.000000pt" viewBox="0 0 860.000000 709.000000" preserveAspectRatio="xMidYMid meet"><metadata> Created by potrace 1.16, written by Peter Selinger 2001-2019 </metadata><g transform="translate(0.000000,709.000000) scale(0.100000,-0.100000)" fill="#808080" stroke="none"><path d="M4185 6673 c-64 -14 -146 -53 -199 -95 -86 -69 -701 -655 -1336
-1274 l-105 -102 -650 -5 c-561 -4 -659 -6 -713 -21 -213 -55 -362 -171 -514
-400 -478 -722 -478 -1683 1 -2416 73 -111 215 -254 308 -309 175 -103 235
-110 1020 -111 l542 0 428 -412 c631 -607 627 -603 775 -740 197 -182 286
-256 343 -286 48 -26 55 -27 220 -27 l170 0 70 34 c103 50 217 168 273 283
l42 86 0 2694 0 2694 -35 73 c-80 166 -216 281 -385 326 -51 14 -208 19 -255
8z m145 -468 c24 -13 30 -23 37 -72 10 -75 11 -5063 0 -5126 -10 -57 -44 -87
-80 -69 -12 6 -87 72 -167 148 -205 194 -592 565 -740 708 -69 67 -244 236
-389 376 l-263 255 -752 5 -752 5 -39 44 c-108 119 -194 263 -276 461 -96 231
-126 414 -116 699 9 272 51 439 172 686 78 159 130 238 213 327 l64 68 741 0
740 0 111 108 c564 547 1452 1392 1463 1392 2 0 17 -7 33 -15z"></path><path d="M6905 6056 c-60 -28 -87 -56 -114 -116 -25 -56 -28 -148 -5 -193 9
-18 105 -122 213 -232 199 -202 293 -317 400 -487 208 -330 335 -665 397
-1045 25 -154 30 -553 10 -733 -49 -426 -205 -850 -432 -1177 -108 -156 -181
-241 -369 -433 -99 -102 -191 -200 -204 -219 -37 -52 -41 -152 -8 -223 41 -90
115 -133 219 -126 84 5 143 44 312 208 333 322 569 668 739 1081 362 879 303
1914 -158 2750 -145 264 -286 449 -524 692 -263 267 -349 313 -476 253z"></path><path d="M6258 5266 c-95 -35 -139 -98 -146 -210 -4 -65 -2 -74 25 -113 15
-23 77 -94 137 -159 341 -371 474 -648 508 -1062 26 -316 -28 -625 -154 -877
-90 -180 -189 -313 -389 -522 -116 -121 -129 -142 -129 -221 0 -128 63 -206
189 -233 108 -23 224 45 389 230 304 339 478 700 558 1156 28 158 25 500 -5
670 -67 377 -218 715 -445 995 -95 118 -275 292 -334 324 -64 34 -149 43 -204
22z"></path><path d="M5590 4451 c-78 -28 -134 -89 -150 -164 -21 -104 2 -159 124 -297
129 -146 156 -218 156 -420 0 -138 -15 -218 -55 -285 -12 -22 -64 -87 -114
-144 -50 -57 -96 -119 -102 -138 -6 -19 -9 -68 -7 -108 4 -95 37 -146 120
-189 115 -58 192 -34 329 103 225 226 332 504 316 821 -14 273 -143 555 -333
729 -113 103 -186 127 -284 92z"></path></g></svg>

</template>
<script>
    export default {
        name: 'VolumeSvg'
    }
</script>