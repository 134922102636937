export default {
    countries: [],
    countryOptions:[],
    countryCustomOptions:[],
    selectedFlag : {
        id:1,
        name:"selectCountry",
        alpha_2:"sc",
        alpha_3:"slc",
        flag:"/flag-icon.png"
    },
    customSelectedFlag : {
        id:1,
        name:"selectCountry",
        alpha_2:"sc",
        alpha_3:"slc",
        flag:"/flag-icon.png"
    },
    defaultCustomCountry: localStorage.getItem('customCountry') ? JSON.parse(localStorage.getItem('customCountry')) : {id:1,text:"world"},
    defaultCountry: localStorage.getItem('country') ? JSON.parse(localStorage.getItem('country')) : {id:1,text:"world"},
    paginate: {
        links: {
            first: null,
            last: null,
            prev: null,
            next: null
        },
        meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: null,
            per_page: 16,
            to: 1,
            total: 1,
            custom: null
        },
    }
}
