<template>
    <div class="commentRadio d-flex ">
    <div class="group order-sm-1 order-1">
<!--        <div v-for="customId in customIds" :key="customId.name+'-'+Math.random()">-->
<!--            <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="customId.name"-->
<!--                :id="customId.name+isModal" :class="showTagClass(customId.name)" />-->
<!--            <label :for="customId.name+isModal" :class="'radioLabel '+showTagClass(customId.name)+'-label badge-pill'">-->
<!--                <small class="text-capitalize">{{customId.name}}</small>-->
<!--            </label>-->
<!--        </div>-->
    
<!--                    <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="'neutral'" :id="customIds[1]"-->
<!--                           class="neutral"/>-->
<!--                    <label :for="customIds[1]" class="radioLabel neutral-label  badge-pill">-->
<!--                        <small>Neutral</small>-->
<!--                    </label>-->
<!--                    <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="'critique'" :id="customIds[2]"-->
<!--                           class="critique"/>-->
<!--                    <label :for="customIds[2]" class="radioLabel status-label  badge-pill">-->
<!--                        <small>Critique</small>-->
<!--                    </label>-->
    </div>
    <div class="d-sm-flex show-small-screen">
    <div class="group order-sm-1 order-1">
            <div v-for="customId in customIds" :key="customId.name + '-' + Math.random()">

                <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="customId.name"
                    :id="customId.name + isModal" :class="showTagClass(customId.name)" />
                <label :for="customId.name + isModal" :class="'radioLabel ' + showTagClass(customId.name) + '-label badge-pill'">
                    <small class="text-capitalize">{{ customId.name }}</small>
                </label>
            </div>
    
                <!--            <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="'neutral'" :id="customIds[1]"-->
                <!--                   class="neutral"/>-->
                <!--            <label :for="customIds[1]" class="radioLabel neutral-label  badge-pill">-->
                <!--                <small>Neutral</small>-->
                <!--            </label>-->
                <!--            <input v-model="commentTagSelected" type="radio" name="status" v-bind:value="'critique'" :id="customIds[2]"-->
                <!--                   class="critique"/>-->
                <!--            <label :for="customIds[2]" class="radioLabel status-label  badge-pill">-->
                <!--                <small>Critique</small>-->
                <!--            </label>-->
    </div>
   <div class="customTag group order-sm-0 order-0">
            <div v-for="customTag in customTags" :key="customTag.name">
                <input v-if="!customTag.deleted_at" v-model="commentTagSelected" type="radio" v-bind:value="customTag.name" :id="customTag.name+isModal"
                       class="custom-tag"/>
                <label v-if="!customTag.deleted_at" :for="customTag.name+isModal" class="radioLabel custom-radio-label supportive-label badge-pill">
                    <small class="text-capitalize"><img v-if="emoji" :src="emoji" height="15" width="20"></small>
                    <small class="text-capitalize">{{customTag.name}}</small>
                </label>
            </div>
    </div>
    </div>
       
    </div>
</template>

<script>
    export default {
        name: "RadioButton",
        props: {
            commentTag: String,
            isModal: Boolean,
            customIds:Array,
            customTags:Array,
        },
        data() {
            return {
                commentTagSelected: "",
                colorCode : "",
                emoji:""
            }
        },

        mounted() {
            this.root = document.documentElement
            this.commentTagSelected = this.commentTag;

        },
        methods:{
            showTagClass(tag){
                if(tag === 'supportive'){
                    return 'supportive';
                }
                if(tag === 'neutral'){
                    return 'neutral';
                }
                if(tag === 'critique'){
                    return 'critique';
                }
            },

        },
        watch: {
            commentTag() {
                this.commentTagSelected = this.commentTag
            },
            commentTagSelected(val) {
                if (!val) return
                let index = this.customTags.findIndex(x=>x.name === val);
                if(index !== -1){
                    this.colorCode = this.customTags[index].color;
                    this.emoji = this.customTags[index].emoji;
                    this.root.style.setProperty('--tag-color',this.colorCode);
                    this.root.style.setProperty('--tag-border',this.colorCode);
                }
                if (this.isModal === true) {
                    this.$emit('emitCommentTagModal', val)
                }
                else {
                    this.$emit('emitCommentTag', val)
                }

            }
        }
    }
</script>

<style scoped>
    /*:root {*/
    /*    --tag-color: rgb(215, 189, 255);*/
    /*    --tag-border:1px solid rgb(215, 189, 255);*/
    /*}*/
.commentRadio .group {
        display: flex;
        
        align-items: center;
        margin-bottom:-7px;
    }


    .commentRadio input[type=radio] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .commentRadio label {
        position: relative;
        margin-right: 1em;
        padding-left: 14px;
        line-height: 2;
        cursor: pointer;
        color: #6c757d;
        font-size: 10px;
        padding-right: 5px;
    }

    .commentRadio label:before {
        box-sizing: border-box;
        content: " ";
        position: absolute;
        top: 8px;
        left: 1px;
        display: block;
        width: 12px;
        height: 12px;
        border: 2px solid #17a2b8;
        border-radius: 18px;
    }

    .commentRadio input[type=radio] + label::before {
        border-radius: 1em;
    }

    /* Checked */

    .commentRadio input[type=radio]:checked + label {
        padding-left: 1em;
        color:white;
    }
 .commentRadio .supportive:checked + label::before,
  .commentRadio .neutral:checked + label::before,
  .commentRadio .custom-tag:checked + label::before,
   .commentRadio .critique:checked + label::before {
         width: 100%;
        height: 100%;
        z-index: auto;
        left: 0;
        top: 0;
     border-radius: 30px;
   }
 /*-- .commentRadio label.supportive-label{
        background-color: #2ecc71;
        color:white;
    }
     .commentRadio label.neutral-label{
       background: lightgray;
    }
     .commentRadio label.status-label{
        background: #ff5e57;
        color:white;
    } --*/

    .commentRadio .custom-tag:checked + label::before {
        /*background: v-bind('colorCode');*/
        /*border: 1px solid v-bind('colorCode');*/
        background-color: var(--tag-color);
        border: 1px solid var(--tag-border);
    }

    
    .commentRadio .supportive:checked + label::before {
        background: #27ae60;
        border: 1px solid #27ae60;
    }

    .commentRadio .neutral:checked + label::before {
        background:darkgray;
        border: 1px solid darkgray;
    }

    .commentRadio .critique:checked + label::before {
        background: #dc3545;
        border: 1px solid #dc3545;
      
    }
  .commentRadio input[type=radio]:checked + label {
    padding-left: 2px!important;
    color: #fff;
}
    .commentRadio label,
    .commentRadio label::before {
        transition: 0.25s all ease;
    }

    .commentRadio small {
        position: relative;
        top: 0;
        z-index: 1;
        font-size: 15px;
        padding: 0px 5px;
    }
    .customTag{
        display:flex!important;
  
    }
</style>