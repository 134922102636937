<template>
    <div>
        <div class="position-relative form-group mb-0 search-box">
            <input type="text" required class="form-control inputField" id="input" @keypress.enter="submitSearch" @keyup="getSearch" v-model="search"/>
            <button @click="submitSearch" class="search-btn ml-md-0 " id="search_button">
                <i class="fas fa-search"></i>
            </button>
        </div>

        <ul class="search-list nav flex-column" v-if="isToggle">
            <div v-click-outside="hide">
                <div v-for="articleSearch in getArticleSearch" :key="articleSearch.id+'_'+Math.random()">
                    <div @click="clickSearch(articleSearch.type)" class="nav-link text-dark">
                        {{articleSearch.text}}
                        <label class="float-right readonly-text mb-0 pt-1">
                            ({{articleSearch.type}})</label>
                    </div>
                </div>
            </div>
        </ul>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ClickOutside from 'vue-click-outside'

    export default {
        name: "InputField",
        data() {
            return {
                isToggle: false,
                search: ""
            }
        },
        computed: {
            ...mapGetters({
                getArticleSearch: 'article/getArticleSearch'
            }),
        },
        methods: {
            getSearch() {
                if (this.search === "") {
                    this.$store.dispatch('article/emptyArticleSearch');
                } else {
                    this.isToggle = true;
                    this.$store.dispatch('article/getArticleSearch', {search: this.search})
                }
            },
            hide() {
                this.isToggle = false
            },
            clickSearch(type) {
                if(type === 'article'){
                    localStorage.setItem('searchTab','1');
                }else {
                    localStorage.setItem('searchTab','2');
                }
                localStorage.setItem('search',this.search);
                this.isToggle = false;
                window.location = '/search'
                // this.$router.push('/search')
            },
            submitSearch(){
                if(this.search){
                    localStorage.setItem('search',this.search);
                    this.$store.dispatch('article/getArticleSearch', {search: this.search}).then(
                        window.location = '/search'
                    )

                }
            }
        },
        directives: {
            ClickOutside
        }
    };
</script>

<style scoped>

    .inputField {
        border-radius: 0;
        width:220px;
        height: 39px;
        border: 2px solid black;
        border-bottom-width: 4px;
    }

    .inputField:hover,
    .inputField:active,
    .inputField:focus {
        box-shadow: none!important;
        outline: none!important;
        border-color: black !important;
    }
  .form-control:hover{
   border-color:black!important;
  }
    .search-btn {
        padding: 0 10px;
        background-color: #ffffff;
        border: 2px solid black;
        border-bottom-width: 4px;
        width: 38px;
        height: 39px;
        text-align: center;
        vertical-align: middle;
    }

    .second-input .input {
        height: 30px;
        width: 100%;
    }
</style>
