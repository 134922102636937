import Vue from 'vue'
import Vuex from 'vuex'
import moduleArticle from "./article/moduleArticle";
import moduleAuth from "./auth/moduleAuth";
import moduleCategory from "./category/moduleCategory";
import moduleCountry from "./country/moduleCountry";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    'auth' : moduleAuth,
    'article' : moduleArticle,
    'category' : moduleCategory,
    'country' : moduleCountry,
  }
})
