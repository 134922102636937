<template>
  <div class="imageCaptionModal">
   <!-- Modal -->
<div class="modal fade pr-0" id="ImageCaptionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content"  style="background-color: transparent !important; border: 0!important;">
            <div class="modal-header" style="border-bottom:0!important">
                <button type="button" class="close image-close-btn" data-dismiss="modal" aria-label="Close" style="border: 1px solid #f1ebeb; border-radius: 50%; padding: 0; width: 35px; height: 35px;  line-height: 31px; padding:0!important;">
                <span aria-hidden="true">&times;</span>
               </button>
            </div>
    <div class="modal-body p-0" style="background:transparent;">
        <img id="captionsImage" src="../../assets/images/no-image.png"  width="100%"  alt="caption-modal-image">
    </div>
        <div class="modal-footer bg-white justify-content-center pt-0">
            <br>
            <p style="color: black;text-align: center" id="captionsText"></p>
        </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
export default {
name:"ImageCaptionModal",
}
</script>

<style>
.image-close-btn span{
    font-size: 28px;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}


.modal-lg {
    max-width: 700px!important;
    width:100%;
}
</style>