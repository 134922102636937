<template>
    <div id="profile">
        <div class="row">
            <div  class="col-sm-12" id="profile-cover">
                <img :src="getUserSEC1Image()" alt="section-one" width="100%" height="250" style="object-fit: cover">
            </div>
        </div>

        <div id="profile-img" class="row  mt-3 mb-3">
            <div class="col-sm-12 profile-img d-flex justify-content-center align-items-center">
                <div id="user-img" class=" d-inline ">
                    <img :src="getUserProfileImage()" width="60" height="60" class="rounded-circle mr-3"
                         alt="profile-picture">
                </div>
                <div class="user-name d-inline">
                    <table class="d-inline">
                        <tr>
                            <td>
                                <h3 class="user-heading mb-0" style="text-align:justify;">{{getUserName()}}</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="subscriber d-flex">
                                    <div v-if="isLogin">
                                        <Subscriber class="mr-2" type="userProfile"
                                                    :loginUserId="getAuthUser.id"
                                                    :userId="getUserDetail.id"
                                                    :totalUserSubscribers="getUserDetail.total_user_subscribers"
                                                    :IsSubscribe="getUserDetail.is_subscribe"
                                        />
                                    </div>
                                <div class="d-inline-flex" style="column-gap: 10px;">
                                    <a v-if="getUserDetail.social_user.fb_url" :href="getUserDetail.social_user.fb_url"
                                       class=" d-inline-block" target="_blank">
                                    <img src="../assets/images/facebook.png" height="17px" alt="no-image">
                                    </a>
                                    <a v-if="getUserDetail.social_user.twitter_url"
                                       :href="getUserDetail.social_user.twitter_url" target="_blank"
                                       class=" d-inline-block">
                                        <img src="../assets/images/twitter.png" height="17px" alt="no-image">
                                    </a>
                                      <a v-if="getUserDetail.social_user.youtube_url"
                                         :href="getUserDetail.social_user.youtube_url" target="_blank"
                                         class=" d-inline-block">
                                        <img src="../assets/images/icons8-youtube-50.png" height="19px" alt="no-image">
                                    </a>
                                     <a v-if="getUserDetail.social_user.global_url"
                                        :href="getUserDetail.social_user.global_url" target="_blank"
                                        class=" d-inline-block">
                                            <img src="../assets/images/globe-icon.png" height="18px" alt="no-image">
                                    </a>
                                </div>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-lg-8 col-md-10 col-sm-12 col-12 profile-text mt-2 mx-auto">
                <h6>{{getDescription()}}</h6>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12  mx-auto order-xxl-first order-lg-first order-sm-last  order-last">
                <div v-for="(cardDataPublished,index) in getPublishedArticlesByUser"
                     :key="cardDataPublished.id+'_'+Math.random()">
                    <BlogCard :cardData="cardDataPublished" :isEdit="cardDataPublished.status.id===6 ? isEditCheck(cardDataPublished) : false" :isUpcoming="cardDataPublished.status.id===6" :isPublished="getUserDetail.id===getAuthUser.id"
                              @emitSkimOpenModel="emitSkimOpenModel"/>
                    <hr v-if="index !== getPublishedArticlesByUser.length-1" class="hr-progressbar">
                </div>
            </div>
        </div>
        <infinite-loading
                spinner="spiral"
                @infinite="getPublishedArticlesBySlug">
        </infinite-loading>
        <SkimModal type="userProfile" :isSkim="isSkim" :article="cardData" :isLogin="isLogin"
                   :getAuthUser="getAuthUser" @emitIsSkimData="emitIsSkimData"
                   @emitCloseModel="emitCloseModel"/>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import helpers from "../helpers";
    import Subscriber from "../components/User/Subscriber";
    import SkimModal from "../components/Modals/SkimModal";
    import BlogCard from "../components/BlogCard/BlogCard";
    import InfiniteLoading from "vue-infinite-loading";

    import $ from "jquery";


    export default {
        name: 'Profile',
        metaInfo: {
            meta: [
                {name: 'viewport', content: 'width=device-width , initial-scale=1.0'},
            ],
            title: 'The Worded',
            titleTemplate: '%s | Profile'
        },
        components: {
            Subscriber, SkimModal, BlogCard, InfiniteLoading
        },
        data() {
            return {
                isProfile: false,
                navBarArray: [{id: 1, title: "settings"}],
                previousScroll: 0,
                isSkim: false,
                skimData: [],
                cardData: {
                    id: 0,
                    slug: "",
                    total_percentage_skim: 0,
                    user:{
                        id:0,
                        slug:"",
                        profile_pic:"",
                        sec_1: '',
                        name: '',
                        total_user_subscribers: 0,
                        is_subscribe:false,
                        description: '',
                        social_user: {
                            fb_url: '',
                            twitter_url: '',
                            youtube_url: ''
                        },
                    }
                },
                // getUserProfile: {
                //     sec_1: '',
                //     profile_pic: '',
                //     name: '',
                //     total_user_subscribers: 0,
                //     description: '',
                //     social_user: {
                //         fb_url: '',
                //         twitter_url: '',
                //         youtube_url: ''
                //     }
                // }
            };
        },
        computed: {
            ...mapGetters({
                getUserProfile: "auth/getUserProfile",
                getUserDetail: "article/getUserDetail",
                articleDetail: 'article/getArticleDetails',
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                getPublishedArticlesByUser: 'article/getPublishedArticlesByUser',
                getPublishedByUserPage: 'article/getPublishedByUserPage'
            })
        },
        methods: {
            getUserProfileData() {
                let payload = {
                    slug: this.$route.params.slug
                }
                document.body.style = '';
                this.$store.dispatch('article/getUserAccountDetail', payload).then(response=>{
                    if(response.data.bg_color){
                        document.body.style = response.data.bg_color.replace(/[\r\n]/gm, '');
                    }
                }).catch(()=>{
                    this.$store.dispatch("auth/routeLogout").then(() => {
                        window.location = "/home";
                    });
                })
            },
            getUserSEC1Image() {
                return this.getUserDetail ? this.getUserDetail.sec_1 : '';
            },
            getUserProfileImage() {
                return this.getUserDetail ? this.getUserDetail.profile_pic : "../assets/images/no-image.png";
            },
            getUserName() {
                return this.getUserDetail ? this.getUserDetail.name : '';
            },
            getTotalUserSubscribers() {
                return this.getUserDetail ? this.getUserDetail.total_user_subscribers : 0
            },
            getFbUrl() {
                return this.getUserDetail ? this.getUserDetail.social_user.fb_url : '';
            },
            getTwitterUrl() {
                return this.getUserDetail ? this.getUserDetail.social_user.twitter_url : '';
            },
            getYoutubeUrl() {
                return this.getUserDetail ? this.getUserDetail.social_user.youtube_url : 0
            },
            getDescription() {
                return this.getUserDetail ? this.getUserDetail.description : ""
            },
            subscribe() {
                let object = {
                    subscriber_id: this.getUserDetail.id,
                }
                this.$store.dispatch('auth/updateUserSubscriber', object)
            },
            getPublishedArticlesBySlug($state) {
                let payload = {
                    page: this.getPublishedByUserPage,
                    slug: this.$route.params.slug,
                    $state: $state
                }
                this.$store.dispatch('article/getPublishedArticlesBySlug', payload)
            },
            emitSkimOpenModel(article) {
                this.isSkim = true;
                this.cardData = article;
                $('#skim-modal').modal('show');
            },
            emitCloseModel() {
                $('#skim-modal').modal('hide');
                this.isSkim = false
            },
            emitIsSkimData(data) {
                this.cardData.total_views_skim = data.total_views_skim
            },
            imgUrlAlt(event) {
                return event.target.src = "../assets/images/no-image.png"
            },
            isEditCheck(upcomingData){
                if(this.getAuthUser !== undefined){
                    if(this.getAuthUser.id !== undefined){
                        return upcomingData.user.id===this.getAuthUser.id
                    }
                    return false;
                }
                return false
            },
        },
        mounted() {
            this.getUserProfileData()
            this.$store.dispatch("article/setPublishArticleByUserPageNumber", 1);
            helpers.fixedSideBarCategory(this, 'scrollNav', 'nav-container', '', 2)
        },
        watch: {}
    }

</script>

<style scoped>
#profile{
    margin-top:60px;
}
#profile-cover {
        height: 250px;
        width: 100%;
    }
    

    #profile-img {
        text-align: center;
    }

    .user-profile-table {
        text-align: initial;
    }

    .user-name .user-heading {
        font-size: 25.2px;
       font-family: "Raleway",sans-serif!important;
    }

    .social-icon .icon {
        color: gray;
        width: 23px;
        font-size: 14px;
        text-align: center;
    }

    .profile-text h6 {
        font-size: 14.4px;
        font-family: "Raleway", sans-serif !important;
    }
   
</style>