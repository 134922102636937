export default {
    getCountries: state => {
        return state.countries;
    },
    countryOptions: state => {
        return state.countryOptions;
    },
    countryCustomOptions: state => {
        return state.countryCustomOptions;
    },
    selectedFlag : state =>{
        return state.selectedFlag
    },
    customSelectedFlag : state =>{
        return state.customSelectedFlag
    },
    defaultCustomCountry: state => {
      return state.defaultCustomCountry
    },
    defaultCountry: state => {
      return state.defaultCountry
    },





}
