<template>
    <div class="mt-3 upcoming-suggestion-count">
        <div>
        <h1 class="upcoming-article text-break text-wrap d-inline-block">{{articleSuggestion.suggestion}}</h1>
        <span class="d-flex justify-content-end">
        <p class="cursor d-inline" @click="copyClipboard(articleSuggestion)">{{articleSuggestion.uuid}}</p>
        <p class="d-inline">
            <span v-if="articleSuggestion.article.status.id !==7">
                <span v-if="articleSuggestion.is_lock" @click="lockSuggestion()"
                    class="badge badge-danger badges cursor ml-2">Unlock</span>
                <span v-else class="badge badge-success badges cursor ml-2" @click="lockSuggestion()">Lock</span>
            </span>
            <span>
                <span v-if="articleSuggestion.is_collapse" @click="collapseSuggestion()"
                    class="badge badge-dark badges cursor ml-2">Collapsed</span>
                <span v-else @click="collapseSuggestion()" class="badge badge-info badges cursor ml-2">Collapse</span>
            </span>
        </p>
        </span>
        </div>
<!--        <textarea v-model="content" :disabled="this.articleSuggestion.content!==''" @keyup="updateContent()"-->
<!--                  class="form-control text-area " rows="5" name="upcoming_content" maxlength="3000" spellcheck="false"></textarea>-->
        <div class="upcomming-summernote" style="height:300px; overflow: scroll;">
            <div  class="upcoming-summernote-content" :style="disabledText(articleSuggestion)">
                <summernote
                        :tweetEmbed="tweetEmbed"
                        :isTweet="isTweet"
                        :isTweetId="'tweetModal'+articleSuggestion.id"
                        @emitIsTweet="emitIsTweet"
                        :config="summernoteConfig"
                        :id="'summernoteEditor'+articleSuggestion.id"
                        @ready="readySummernote">
                </summernote>
                <div class="col-xs-12 text-right characters">
                    (Min: {{content.length}} Max: 3000)
                </div>
            </div>
        </div>
        <TweetModal :id="'tweetModal'+articleSuggestion.id" @emitTweetLink="emitTweetLink"/>
    </div>
</template>

<script>
    import Helper from "../../helper";
    import TweetModal from "../Modals/TweetModal";
    import Summernote from "../../views/Summernote";
    export default {
        name: 'ArticleSuggestionContent',
        components:{
            TweetModal,
            Summernote
        },
        data() {
            return {
                tweetEmbed: "",
                isTweet: false,
                content: "",
                summernoteConfig: {
                    tabsize: 2,
                    height: 500,
                    fontNames: [
                        'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New',
                        'Helvetica Neue', 'Helvetica', 'Impact', 'Lucida Grande',
                        'Tahoma', 'Times New Roman', 'Verdana', 'Microsoft YaHei'
                    ],
                    dialogsInBody: true,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline']],
                        ['para', ['ul', 'ol']],
                        ['insert', ['link', 'picture', 'video', 'hr']],
                        ['mybutton', ['quote']],
                        ['mybutton', ['tweet']],
                    ],
                    popover: {
                        image: [
                            ["image", ["resizeQuarter", "resizeHalf", "resizeHalf1", "resizeFull"]],
                            ["float", ["floatLeft", "floatRight"]],
                            ["remove", ["removeMedia", "imageCaption"]],
                        ],
                        link: [["link", ["linkDialogShow", "unlink"]]],
                        air: [
                            ["color", ["color"]],
                            ["font", ["bold", "underline", "clear"]],
                            ["insert", ["link", "picture"]],
                        ],
                    },
                },
            }
        },
        props: {
            articleSuggestion: Object
        },
        methods: {
            collapseSuggestion(){
                let payload = {
                    suggestion_id: this.articleSuggestion.id,
                }
                this.$store.dispatch("article/headingRequestCollapse", payload);
            },
            lockSuggestion() {
                let payload = {
                    suggestion_id: this.articleSuggestion.id,
                }
                this.$store.dispatch("article/headingRequestLockUnlocked", payload);
            },
            disabledText(suggestion){
               if(suggestion.content){
                   return 'background-color:#8080801d; pointer-events: none; opacity: 0.5;'
               }
            },
            emitTweetLink(tweet) {
                this.tweetEmbed = tweet
                this.isTweet = true
            },
            emitIsTweet(val) {
                this.isTweet = val
            },
            readySummernote(editor) {
                editor.summernote("code", this.content);
                editor.$on("change", (contents) => {
                    this.content = contents;
                    if(this.articleSuggestion.content){
                        this.content = contents
                    }
                    this.$emit('emitUpdatedArticleSuggestionContent', {
                        content: contents,
                        articleSuggestion: this.articleSuggestion
                    })
                });
            },
            updateContent() {
                if(this.articleSuggestion.content){
                    this.content = this.articleSuggestion.content
                }
                this.$emit('emitUpdatedArticleSuggestionContent', {
                    content: this.content,
                    articleSuggestion: this.articleSuggestion
                })
            },
            copyClipboard(note) {
                navigator.clipboard.writeText(note.uuid);
                return Helper.toast(this, 'Copy', 'Copy command to clipboard', 'success')
            }
        },
        mounted() {
            if(this.articleSuggestion.content){
                this.content = this.articleSuggestion.content
            }else{
                this.content = this.articleSuggestion.selected_suggestion.content
            }
        },
    }
</script>
<style scoped>
    .badges{
        padding: 5px 10px;
        font-size: 12px;
    }
.upcoming-suggestion-count .upcoming-article{
    font-family: 'Merriweather';
    font-size: 32px;
    font-weight: 400;
}
.upcoming-suggestion-count .characters{
    font-family: "Raleway", sans-serif;
    font-size:12px;
    color:gray;
}
.upcoming-suggestion-count .text-area{
border: 1px solid  black!important;
}
#summernoteeditor .note-editable[contenteditable="true"] {
    background-color: red !important;
}

/*.upcoming-summernote-content{*/
/*    background-color:#8080801d;*/
/*    pointer-events: none;*/
/*    opacity: 0.5;*/
/*    }*/
</style>
