<template>
    <div id="ArticleUserNavbar" class="profile-nav">
        <div class="homeTabsWrapper profileNav">
            <b-tabs content-class="mt-3">
                <b-tab title="Users" active>
                    <UserList/>
                </b-tab>
                <b-tab title="Articles">
                    <ArticleList/>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import ArticleList from "../ArticleList.vue";
import UserList from "../UserList";
// import {mapGetters} from "vuex";

export default {
    name: "ArticleNavbar",
    components: {
        UserList,
        ArticleList
    },
};
</script>
<style scoped>
#ArticleUserNavbar{
    margin-top:65px;
}
.active {
    background-color: transparent !important;
}
</style>

