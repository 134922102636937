<template>
  <div class="login  container" style="margin-top:62px;">
      <div class="row">
        <div class="col-12 col-md-6 col-sm-12 offset-sm-3">
            <div class="login-wrapper">
                <h2 class="text-center">Enter Password</h2>
                <!--login form-->
                <form>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                            <input autocomplete="off" value="" name="password" v-model="password" required class="form-control" id="adminPassword">
                        </div>
                    </div>
                    <div class="signInBtn mt-2">
                        <button @click="checkPassword()" type="button" class="button">Submit</button>
                    </div>
                </form>
                <!---button-->
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import helpers from "../helpers";

export default {
    name:"loginPage",
    components: {
    },
    data() {
        return {
            password:'',
             }
        },
    methods:{
        checkPassword(){
            let payload = {
                password: this.password,
            };
            this.$store.dispatch('auth/checkAdminPassword',payload).then(()=>{
                this.$router.push('/admin/dashboard');
            }).catch(error => {
                helpers.errorMessage(this, error);
            });
        }
     }
    }
</script>

<style  scoped>
.login-wrapper{
    transform: translateY(50%);
}
.para {
    color: #8A92A6;
    font-family: "Inter", sans-serif;
}
.signInBtn{
    text-align: center;
}
.button{
    color: white;
    background: #3A57E8;
    padding: 8px 24px;
    border: 0;
    text-align:center;
    cursor: pointer;
    border-radius: 4px;
}
</style>