export default {
    GET_ARTICLE(state, response) {
        let articles = response.data.data;
        if (articles.length > 0) {
            // response.data.meta.last_page
            if (response.data.meta.last_page >= state.paginate.page) {
                state.paginate.page += 1;
                state.articles.push(...articles)
                // response.data.data.map(x=>{
                // let index = state.articles.findIndex(y=>(y.id === x.id))
                // if(index === -1){
                //     state.articles.push(x)
                // }
                // })
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
        state.articles.map(article => {
            article.article_category = article.article_category.filter(x =>
                x.article !== null
            );
            return article
        })
    },

    SET_PAGE_NUMBER(state, page) {
        state.paginate.page = page;
        state.articles = []
        state.breaking = []
        state.upcoming = []

    },
    SET_CATEGORY_PAGE_NUMBER(state, page) {
        state.categoryArticle.page = page;
        state.categoryArticle.articles = []
        state.categoryArticle.breaking = []
        state.categoryArticle.upcoming = []
    },
    UPDATED_ARTICLE(state, response) {
        state.articles = [];
        state.paginate.page = 1;
        state.articles = response.data.data;
    },
    GET_CATEGORY_ARTICLE(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.categoryArticle.page) {
                state.categoryArticle.page += 1;
                state.categoryArticle.articles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
        // var unique = [];
        // var distinct = [];
        state.categoryArticle.articles.map(article => {
            article.article_category = article.article_category.filter(x =>
                x.article !== null
            );

            // if (article.article_category) {
            //     for (let i = 0; i < this.articleDetail.article_category.length; i++) {
            //         if (this.articleDetail.article_category[i].category !== undefined) {
            //             if (this.articleDetail.article_category[i].category.category_name_slug !== undefined) {
            //                 if (!unique[this.articleDetail.article_category[i].category.category_name_slug]) {
            //                     distinct.push(this.articleDetail.article_category[i]);
            //                     unique[this.articleDetail.article_category[i].category.category_name_slug] = 1;
            //                 }
            //             }
            //         }
            //     }
            // }
            return article
        })
    },
    GET_BREAKING_AND_UP_COMING_ARTICLE(state, response) {
        state.breaking = response.data.data.breaking;
        state.upcoming = response.data.data.upcoming;
        state.subscribed = response.data.data.subscribed;
    },
    GET_CATEGORY_BREAKING_AND_UP_COMING_ARTICLE(state, response) {
        state.categoryArticle.page = 1;
        state.categoryArticle.articles = []
        state.categoryArticle.breaking = []
        state.categoryArticle.upcoming = []
        state.categoryArticle.subscribed = []
        state.categoryArticle.breaking = response.data.data.breaking;
        state.categoryArticle.upcoming = response.data.data.upcoming;
        state.categoryArticle.subscribed = response.data.data.subscribed;
    },
    ARTICLE_DETAIL(state, response) {
        state.articleDetail = response.data.data;
        localStorage.setItem('cardData', JSON.stringify(state.articleDetail))
    },
    ARTICLE_DETAIL_AUDIO(state, response) {
        if (state.articleDetail.id === response.data.data.id) {
            state.articleDetail.audio = response.data.data.audio;
            localStorage.setItem('cardData', JSON.stringify(state.articleDetail))
        }
    },
    IMAGE_CAPTION(state, response) {
        state.articleDetail.captions = []
        state.articleDetail.captions.push(...response.data.data)
        localStorage.setItem('cardData', JSON.stringify(state.articleDetail))
    },
    EMPTY_ARTICLE_DETAIL(state) {
        state.articleDetail = {id: ''}
    },
    EDIT_ARTICLE_DETAIL(state, cardData) {
        state.articleDetail = cardData
    },
    EDIT_ARTICLE(state, cardData) {
        localStorage.setItem('cardData', JSON.stringify(cardData))
        state.cardData = cardData
    },
    ADD_NEW_ARTICLE(state) {
        let cardData = {id: "", title: "", slug: "", captions: []};
        localStorage.setItem('cardData', JSON.stringify(cardData))
        localStorage.setItem('isEdit', JSON.stringify(false))
        state.cardData = cardData
        state.allArticleSuggestion=[];
    },
    ADD_ARTICLE(state, response) {
        localStorage.setItem('cardData', JSON.stringify(response.data.data))
        state.cardData = response.data.data
    },
    UPLOAD_EDITOR_IMAGE() {
    },
    GET_DRAFT_AND_UPCOMING(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.draftPage) {
                state.draftPage += 1;
                state.draftAndUpcoming.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    SET_PUBLISHED_ARTICLE_PAGE_NUMBER(state, page) {
        state.publishedArticles = [];
        state.publishPage = page;
    },
    GET_PUBLISHED_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.publishPage) {
                state.publishPage += 1;
                state.publishedArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },

    SET_PUBLISHED_ARTICLE__BY_USER_PAGE_NUMBER(state, page) {
        state.publishedArticlesByUser = [];
        state.publishByUserPage = page;
    },

    GET_PUBLISHED_ARTICLES_BY_USER(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.publishByUserPage) {
                state.publishByUserPage += 1;
                state.publishedArticlesByUser.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },

    ADD_DRAFT_ARTICLE(state, response) {
        let draftAndUpcomingIndex = state.draftAndUpcoming.findIndex(x => x.id === response.data.data.id);
        if (draftAndUpcomingIndex !== -1) {
            state.draftAndUpcoming[draftAndUpcomingIndex] = response.data.data
        } else {
            state.draftAndUpcoming.unshift(response.data.data);
        }
        let draftUpcomingIndex = state.draftAndUpcoming.findIndex(el => el.status.id === 7);
        if (draftUpcomingIndex !== -1) {
            state.draftAndUpcoming.splice(draftUpcomingIndex, 1);
        }
    },
    ADD_PUBLISH_ARTICLE(state, response) {
        let publishedArticlesIndex = state.publishedArticles.findIndex(x => x.id === response.data.data.id);
        if (publishedArticlesIndex !== -1) {
            state.publishedArticles[publishedArticlesIndex] = response.data.data
        } else {
            state.publishedArticles.unshift(response.data.data);
        }
        let publishArticlesIndex = state.publishedArticles.findIndex(el => el.status.id === 5);
        if (publishArticlesIndex !== -1) {
            state.publishedArticles.splice(publishArticlesIndex, 1);
        }
    },
    GET_ALL_BREAKING_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.breakingPage) {
                state.breakingPage += 1;
                state.allBreakingArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_ALL_SUBSCRIBED_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.subscribedPage) {
                state.subscribedPage += 1;
                state.allSubscribedArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_ALL_UPCOMING_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.upcomingPage) {
                state.upcomingPage += 1;
                state.allUpcomingArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.complete();
            }
        }
    },

    ADD_COMMENT(state, response) {
        if (response.data.data.self_parent_id) {
            if (state.articleDetail.id) {
                state.articleDetail.total_percentage = response.data.data.article.total_percentage;
                let articleDetailCommentsIndex = state.articleDetailComments.findIndex(x => x.id === response.data.data.self_parent_id);
                if (articleDetailCommentsIndex !== -1) {
                    state.articleDetailComments[articleDetailCommentsIndex].replies.unshift(response.data.data);
                }
            }
            let allCommentsSendIndex = state.allCommentsSend.findIndex(x => x.id === response.data.data.self_parent_id);
            if (allCommentsSendIndex !== -1) {
                state.allCommentsSend[allCommentsSendIndex].replies.unshift(response.data.data);
            }
            let allCommentsReceivedIndex = state.allCommentsReceived.findIndex(x => x.id === response.data.data.self_parent_id);
            if (allCommentsReceivedIndex !== -1) {
                state.allCommentsReceived[allCommentsReceivedIndex].replies.unshift(response.data.data);
            }
            let writerReplyCommentsSendIndex = state.writerReplyCommentsSend.findIndex(x => x.id === response.data.data.self_parent_id);
            if (writerReplyCommentsSendIndex !== -1) {
                state.writerReplyCommentsSend[writerReplyCommentsSendIndex].replies.unshift(response.data.data);
            }
            let writerReplyCommentsReceivedIndex = state.writerReplyCommentsReceived.findIndex(x => x.id === response.data.data.self_parent_id);
            if (writerReplyCommentsReceivedIndex !== -1) {
                state.writerReplyCommentsReceived[writerReplyCommentsReceivedIndex].replies.unshift(response.data.data);
            }
        } else {
            if (state.articleDetail.id) {
                state.articleDetail.total_percentage = response.data.data.article.total_percentage;
                state.articleDetailComments.unshift(response.data.data)
                state.allCommentsSend.unshift(response.data.data)
            }
        }
    },
    READ_COMMENT(state, response) {
        if (state.articleDetail.id) {
            let articleCommentIndex = state.articleDetailComments.findIndex(x => x.id === response.data.data.id);
            if (articleCommentIndex !== -1) {
                state.articleDetailComments[articleCommentIndex].check_is_read = response.data.data.check_is_read
            }
        }
        let allCommentsSendIndex = state.allCommentsSend.findIndex(x => x.id === response.data.data.id);
        if (allCommentsSendIndex !== -1) {
            state.allCommentsSend[allCommentsSendIndex].check_is_read = response.data.data.check_is_read
        }
        let allCommentsReceivedIndex = state.allCommentsReceived.findIndex(x => x.id === response.data.data.self_parent_id);
        if (allCommentsReceivedIndex !== -1) {
            state.allCommentsReceived[allCommentsReceivedIndex].check_is_read = response.data.data.check_is_read
        }
        let writerReplyCommentsSendIndex = state.writerReplyCommentsSend.findIndex(x => x.id === response.data.data.self_parent_id);
        if (writerReplyCommentsSendIndex !== -1) {
            state.writerReplyCommentsSend[writerReplyCommentsSendIndex].check_is_read = response.data.data.check_is_read
        }
        let writerReplyCommentsReceivedIndex = state.writerReplyCommentsReceived.findIndex(x => x.id === response.data.data.self_parent_id);
        if (writerReplyCommentsReceivedIndex !== -1) {
            state.writerReplyCommentsReceived[writerReplyCommentsReceivedIndex].check_is_read = response.data.data.check_is_read
        }
    },
    ADD_LIKE_OR_DISLIKE_COMMENT(state, response) {
        if (state.articleDetail.id) {
            let articleDetailCommentsIndex = state.articleDetailComments.findIndex(x => x.id === response.data.data.article_comment.id);
            if (articleDetailCommentsIndex !== -1) {
                state.articleDetailComments[articleDetailCommentsIndex].is_like_dislike = response.data.data.article_comment.is_like_dislike;
                state.articleDetailComments[articleDetailCommentsIndex].total_like = response.data.data.article_comment.total_like;
                state.articleDetailComments[articleDetailCommentsIndex].total_dislike = response.data.data.article_comment.total_dislike;
                state.articleDetailComments[articleDetailCommentsIndex].write = response.data.data.article_comment.write;
                state.articleDetailComments[articleDetailCommentsIndex].writerLike = response.data.data.article_comment.writerLike;
            }
        }
        let allCommentsSendIndex = state.allCommentsSend.findIndex(x => x.id === response.data.data.article_comment.id);
        if (allCommentsSendIndex !== -1) {
            state.allCommentsSend[allCommentsSendIndex].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.allCommentsSend[allCommentsSendIndex].total_like = response.data.data.article_comment.total_like;
            state.allCommentsSend[allCommentsSendIndex].total_dislike = response.data.data.article_comment.total_dislike;
            state.allCommentsSend[allCommentsSendIndex].write = response.data.data.article_comment.write;
            state.allCommentsSend[allCommentsSendIndex].writerLike = response.data.data.article_comment.writerLike;

        }
        let allCommentsReceivedIndex = state.allCommentsReceived.findIndex(x => x.id === response.data.data.article_comment.id);
        if (allCommentsReceivedIndex !== -1) {
            state.allCommentsReceived[allCommentsReceivedIndex].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.allCommentsReceived[allCommentsReceivedIndex].total_like = response.data.data.article_comment.total_like;
            state.allCommentsReceived[allCommentsReceivedIndex].total_dislike = response.data.data.article_comment.total_dislike;
            state.allCommentsReceived[allCommentsReceivedIndex].write = response.data.data.article_comment.write;
            state.allCommentsReceived[allCommentsReceivedIndex].writerLike = response.data.data.article_comment.writerLike;
        }
        let writerReplyCommentsSendIndex = state.writerReplyCommentsSend.findIndex(x => x.id === response.data.data.article_comment.id);
        if (writerReplyCommentsSendIndex !== -1) {
            state.writerReplyCommentsSend[writerReplyCommentsSendIndex].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.writerReplyCommentsSend[writerReplyCommentsSendIndex].total_like = response.data.data.article_comment.total_like;
            state.writerReplyCommentsSend[writerReplyCommentsSendIndex].total_dislike = response.data.data.article_comment.total_dislike;
            state.writerReplyCommentsSend[writerReplyCommentsSendIndex].write = response.data.data.article_comment.write;
            state.writerReplyCommentsSend[writerReplyCommentsSendIndex].writerLike = response.data.data.article_comment.writerLike;

        }
        let writerReplyCommentsReceived = state.writerReplyCommentsReceived.findIndex(x => x.id === response.data.data.article_comment.id);
        if (writerReplyCommentsReceived !== -1) {
            state.writerReplyCommentsReceived[writerReplyCommentsReceived].is_like_dislike = response.data.data.article_comment.is_like_dislike;
            state.writerReplyCommentsReceived[writerReplyCommentsReceived].total_like = response.data.data.article_comment.total_like;
            state.writerReplyCommentsReceived[writerReplyCommentsReceived].total_dislike = response.data.data.article_comment.total_dislike;
            state.writerReplyCommentsReceived[writerReplyCommentsReceived].write = response.data.data.article_comment.write;
            state.writerReplyCommentsReceived[writerReplyCommentsReceived].writerLike = response.data.data.article_comment.writerLike;
        }
    },
    GET_ALL_COMMENTS_SEND(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.allCommentsSendPage) {
                state.allCommentsSendPage += 1;
                state.allCommentsSend.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_ALL_COMMENTS_RECEIVED(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.allCommentsReceivedPage) {
                state.allCommentsReceivedPage += 1;
                state.allCommentsReceived.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_WRITER_REPLY_COMMENTS_SEND(state, response) {
        state.writerReplyCommentsSend = response.data.data
    },
    GET_CUSTOM_TAGS(state,response){
      state.customTags=response.data.data
    },
    GET_WRITER_REPLY_COMMENTS_RECEIVED(state, response) {
        state.writerReplyCommentsReceived = response.data.data
    },
    GET_ARTICLE_SEARCH(state, response) {
        state.articleSearch = response.data.data
    },

    GET_ARTICLE_SEARCHED(state, response) {
        state.searchArticles = [];
        state.searchUsers = [];
        state.searchArticles = response.data.data.articles;
        state.searchUsers = response.data.data.users
    },
    EMPTY_ARTICLE_SEARCH(state) {
        state.articleSearch = []
    },
    GET_ALL_HASHTAG_ARTICLES(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.getHashtagPage) {
                state.getHashtagPage += 1;
                state.allHashtagArticles.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    GET_ARTICLE_DETAIL_COMMENTS(state, response) {
        if (response.data.data.length > 0) {
            if (response.data.meta.last_page >= state.articleDetailCommentsPageNumber) {
                state.articleDetailCommentsPageNumber += 1;
                state.articleDetailComments.push(...response.data.data)
            }
            if (response.$state) {
                response.$state.loaded();
            }
        } else {
            if (response.$state) {
                response.$state.complete();
            }
        }
    },
    UPDATE_ARTICLE_USER_SUBSCRIBER(satate, response) {
        satate.articleDetail.user.is_subscribe = response.data.data.is_subscribe
        satate.articleDetail.user.total_user_subscribers = response.data.data.total_user_subscribers
    },
    UPDATE_SEARCH_USER_SUBSCRIBER(state, response) {
        let index = state.searchUsers.findIndex(x => x.id === response.data.data.user_id);
        if (index !== -1) {
            state.searchUsers[index].is_subscribe = response.data.data.is_subscribe
            state.searchUsers[index].total_user_subscribers = response.data.data.total_user_subscribers
        }
    },
    UPDATE_PROFILE_USER_SUBSCRIBER(state, response) {
        state.userDetail.is_subscribe = response.data.data.is_subscribe
        state.userDetail.total_user_subscribers = response.data.data.total_user_subscribers
    },

    UPDATE_HOME_BLOG_USER_SUBSCRIBER(state, response) {
        state.categoryArticle.breaking.map((x, index) => {
            if (x.user.id === response.data.data.user_id) {
                state.categoryArticle.breaking[index].user.is_subscribe = response.data.data.is_subscribe
                state.categoryArticle.breaking[index].user.total_user_subscribers = response.data.data.total_user_subscribers
            }
        });
        state.categoryArticle.subscribed.map((x, index) => {
            if (x.user.id === response.data.data.user_id) {
                state.categoryArticle.subscribed[index].user.is_subscribe = response.data.data.is_subscribe
                state.categoryArticle.subscribed[index].user.total_user_subscribers = response.data.data.total_user_subscribers
            }
        });
        state.categoryArticle.articles.map((x, index) => {
            x.article_category.map((y, i) => {
                if (y.article.user.id === response.data.data.user_id) {
                    state.categoryArticle.articles[index].article_category[i].article.user.is_subscribe = response.data.data.is_subscribe
                    state.categoryArticle.articles[index].article_category[i].article.user.total_user_subscribers = response.data.data.total_user_subscribers
                }
            })
        });
    },
    UPDATE_ALL_BREAKING_USER_SUBSCRIBER(state, response) {
        state.allBreakingArticles.map((x, index) => {
            if (x.user.id === response.data.data.user_id) {
                state.allBreakingArticles[index].user.is_subscribe = response.data.data.is_subscribe
                state.allBreakingArticles[index].user.total_user_subscribers = response.data.data.total_user_subscribers
            }
        });
    },
    UPDATE_ALL_HASH_TAG_USER_SUBSCRIBER(state, response) {
        state.allHashtagArticles.map((x, index) => {
            if (x.user.id === response.data.data.user_id) {
                state.allHashtagArticles[index].user.is_subscribe = response.data.data.is_subscribe
                state.allHashtagArticles[index].user.total_user_subscribers = response.data.data.total_user_subscribers
            }
        });
    },


    ARCHIVE_PUBLISH_ARTICLE(state, response) {
        let index = state.publishedArticles.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.publishedArticles.splice(index, 1)
        }
    },
    ARCHIVE_DRAFT_ARTICLE(state, response) {
        let index = state.draftAndUpcoming.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.draftAndUpcoming.splice(index, 1)
        }
    },
    SKIM_LIKE_DISLIKE_ARTICLE(state, response) {
        let index = state.categoryArticle.articles.findIndex(x => x.id === response.data.data.article.id);
        if (index !== -1) {
            state.categoryArticle.articles[index].total_like_skim = response.data.data.article.total_like_skim
            state.categoryArticle.articles[index].total_dislike_skim = response.data.data.article.total_dislike_skim
            state.categoryArticle.articles[index].total_percentage_skim = response.data.data.article.total_percentage_skim
        }
    },
    SKIM_LIKE_DISLIKE_BREAKING_ARTICLE(state, response) {
        let index = state.categoryArticle.breaking.findIndex(x => x.id === response.data.data.article.id);
        if (index !== -1) {
            state.categoryArticle.breaking[index].total_like_skim = response.data.data.article.total_like_skim
            state.categoryArticle.breaking[index].total_dislike_skim = response.data.data.article.total_dislike_skim
            state.categoryArticle.breaking[index].total_percentage_skim = response.data.data.article.total_percentage_skim
        }
    },
    SKIM_LIKE_DISLIKE_UPCOMING_ARTICLE(state, response) {
        let index = state.categoryArticle.upcoming.findIndex(x => x.id === response.data.data.article.id);
        if (index !== -1) {
            state.categoryArticle.upcoming[index].total_like_skim = response.data.data.article.total_like_skim
            state.categoryArticle.upcoming[index].total_dislike_skim = response.data.data.article.total_dislike_skim
            state.categoryArticle.upcoming[index].total_percentage_skim = response.data.data.article.total_percentage_skim
        }
    },
    SET_ARTICLE_DETAIL_COMMENTS_PAGE_NUMBER(state, page) {
        state.articleDetailComments = []
        state.articleDetailCommentsPageNumber = page
    },
    SET_HASHTAG_PAGE_NUMBER(state, page) {
        state.allHashtagArticles = []
        state.getHashtagPage = page
    },
    SET_BREAKING_PAGE_NUMBER(state, page) {
        state.allBreakingArticles = []
        state.breakingPage = page
    },
    SET_UPCOMING_PAGE_NUMBER(state, page) {
        state.allUpcomingArticles = []
        state.upcomingPage = page
    },
    SET_SUBSCRIBED_PAGE_NUMBER(state, page) {
        state.allSubscribedArticles = []
        state.subscribedPage = page
    },
    ADD_ARTICLE_VIEWS(state, response) {
        state.articleDetail.total_views = response.data.data.total_views
        state.articleDetail.skim_count = response.data.data.skim_count
        state.articleDetail.total_views_skim = response.data.data.total_views_skim
    },
    SET_HASH_TAG_DATA(state, data) {
        localStorage.setItem('hashTagData', JSON.stringify(data));
        state.hashTagData = data;
    },
    UPDATE_WRITE_ARTICLE(state, cardData) {
        localStorage.setItem('cardData', JSON.stringify(cardData))
    },
    HEADING_REQUEST(state, response) {
        state.articleDetail.article_suggestions.unshift(response.data.data)
    },
    UPDATE_HEADING_REQUEST(state, response) {
        var suggestion = state.allHeadingRequests.findIndex(x => x.id === response.data.data.id);
        if (suggestion !== -1) {
            state.allHeadingRequests[suggestion].suggestion = response.data.data.suggestion
        }
    },
    CANCEL_ARTICLE_SUGGESTION(state, response) {
        let index = state.articleDetail.article_suggestions.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.articleDetail.article_suggestions[index].status = response.data.data.status
        }
    },
    ADD_NOTE_ARTICLE_DETAIL(state, response) {
        state.articleDetail.notes.unshift(response.data.data)
        localStorage.setItem('cardData', JSON.stringify(state.articleDetail))
    },

    UPDATE_NOTE_ARTICLE_DETAIL(state, response) {
        let index = state.articleDetail.notes.findIndex(x => x.uuid === response.data.data.uuid);
        if (index !== -1) {
            state.articleDetail.notes[index].title = response.data.data.title;
            localStorage.setItem('cardData', JSON.stringify(state.articleDetail))
        }
    },

    DELETE_NOTE_ARTICLE_DETAIL(state, response) {
        let index = state.articleDetail.notes.findIndex(x => x.uuid === response.data.data.uuid);
        if (index !== -1) {
            state.articleDetail.notes.splice(index, 1);
            localStorage.setItem('cardData', JSON.stringify(state.articleDetail))
        }
    },
    HEADING_REQUEST_ENDORSEMENT(state, response) {
        let index = state.articleDetail.article_suggestions.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.articleDetail.article_suggestions[index].is_endorsement = response.data.data.is_endorsement
            state.articleDetail.article_suggestions[index].endorsement_count = response.data.data.endorsement_count
            state.articleDetail.article_suggestions[index].endorsement = [];
            state.articleDetail.article_suggestions[index].endorsement.push(...response.data.data.endorsement);
        }

        let index1 = state.allHeadingRequests.findIndex(x => x.id === response.data.data.id);
        if (index1 !== -1) {
            state.allHeadingRequests[index1].is_endorsement = response.data.data.is_endorsement
            state.allHeadingRequests[index1].endorsement_count = response.data.data.endorsement_count
            state.allHeadingRequests[index1].status = response.data.data.status
            state.allHeadingRequests.splice(index1, 1);
        }
    },
    HEADING_REQUEST_LOCK_UNLOCKED(state,response){
        let index = state.allArticleSuggestion.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.allArticleSuggestion[index].is_lock = response.data.data.is_lock
        }
        },
    HEADING_REQUEST_COLLAPSE(state,response){
        let index = state.allArticleSuggestion.findIndex(x => x.id === response.data.data.id);
        if (index !== -1) {
            state.allArticleSuggestion[index].is_collapse = response.data.data.is_collapse
        }
        },
    HEADING_REQUEST_ACCORDING_TO_USER(state, response) {
        state.allHeadingRequests = response.data.data
    },

    ALL_ARTICLE_SUGGESTION_ACCORDING_TO_ARTICLE(state, response) {
        state.articleDetail.article_suggestions = [];
        state.articleDetail.article_suggestions = response.data.data;
        state.allArticleSuggestion = response.data.data
    },
    SAVE_ARTICLE_SUGGESTION_NOTE(state,response){
        let index = state.allArticleSuggestion.findIndex(x=>x.id===response.data.data.id)
        let index1 = state.allHeadingRequests.findIndex(x=>x.id===response.data.data.id)
        if(index !== -1){
            state.allArticleSuggestion[index].notes = [];
            state.allArticleSuggestion[index].notes.push(...response.data.data.notes);
        }
        if(index1 !== -1){
            state.allHeadingRequests[index1].notes=[];
            state.allHeadingRequests[index1].notes.push(...response.data.data.notes)
        }
    },

    UPDATE_ARTICLE_SUGGESTION_ACCORDING_TO_ARTICLE(state, response) {
        let index = state.allArticleSuggestion.findIndex(x => x.id === response.data.data.id)
        if (index !== -1) {
            state.allArticleSuggestion[index].selected_suggestion.content = response.data.data.selected_suggestion.content
        }
    },
    READ_SUGGESTION_NOTE_READER(state,response){
        let index = state.allHeadingRequests.findIndex(x=>x.id===response.data.data.id)
        if(index !== -1){
            state.allHeadingRequests[index].is_notes_read = response.data.data.is_notes_read
        }
    },
    READ_SUGGESTION_NOTE_WRITER(state,response){
        let index = state.allArticleSuggestion.findIndex(x=>x.id===response.data.data.id)
        if(index !== -1){
            state.allArticleSuggestion[index].is_notes_read = response.data.data.is_notes_read
        }
    },
    GET_USER_ACCOUNT_BY_SLUG(state, response) {
        state.userDetail = response.data.data
    },
    GET_UPCOMING_COUNT(state, response) {
        state.upcomingCount = response.data.data.upcoming_count;
    },

    CREATE_FONT_FAMILY(state, response) {
        state.cardData.font_family = response.data.data
        localStorage.setItem('cardData', JSON.stringify(state.cardData))
    },

    CREATE_CUSTOM_TAG(state, response) {
        let index = state.cardData.custom_tags.findIndex(x => x.id === response.data.data.id)
        if (index !== -1) {
            state.cardData.custom_tags[index] = response.data.data
        } else {
            state.cardData.custom_tags.push(response.data.data);
        }
        localStorage.setItem('cardData', JSON.stringify(state.cardData))
    },
    READ_SUGGESTION_NOTIFICATION(state,response){
        state.checkSuggestionNotification = response.data.data.unread_notes_count;
    },
    DELETE_CUSTOM_TAG(state, response) {
        let index = state.cardData.custom_tags.findIndex(x => x.id === response.data.data.id)
        if (index !== -1) {
            state.cardData.custom_tags.splice(index, 1);
        }
        localStorage.setItem('cardData', JSON.stringify(state.cardData))
    },
    GET_ALL_ARTICLES(state,response){
        state.allArticles = response.data.data;
    },
    ARCHIVE_ARTICLE_BY_ADMIN(state,response){
        let index = state.allArticles.findIndex(x=>x.slug===response.data.data.slug)
        if(index !== -1){
            state.allArticles[index].is_deleted = response.data.data.is_deleted
        }
    },
    ARTICLE_MONETIZE_BY_ADMIN(state,response){
        let index = state.allArticles.findIndex(x=>x.slug === response.data.data.slug)
        if(index !== -1){
            state.allArticles[index].is_monetize = response.data.data.is_monetize
        }
    }
}