<template>
    <div class="articles">
         <div class="row mt-4">
            <div class="col-lg-8 col-md-12 col-sm-12 order-xxl-first order-lg-first order-sm-last  order-last">
                <div v-for="(breakingData,index) in getSearchArticles" :key="breakingData.id+'_'+Math.random()">
                    <BlogCard :cardData="breakingData" :isEdit="false" @emitSkimOpenModel="emitSkimOpenModel"/>
                    <hr v-if="index!==getSearchArticles.length-1" class="hr-progressbar">
                </div>
            </div>
        </div>
        <SkimModal type="search" :isSkim="isSkim" :article="cardData" :isLogin="isLogin"
                   :getAuthUser="getAuthUser" @emitIsSkimData="emitIsSkimData"
                   @emitCloseModel="emitCloseModel"/>

        <!--    <infinite-loading-->
        <!--            spinner="spiral"-->
        <!--            @infinite="getArticles"-->
        <!--    ></infinite-loading>-->
    </div>
</template>

<script>
    import BlogCard from "../components/BlogCard/BlogCard";
    import SkimModal from "../components/Modals/SkimModal";

    // import InfiniteLoading from "vue-infinite-loading";
    import {mapGetters} from "vuex";
    import $ from "jquery";

    export default {
        name: "articles",
        components: {
            SkimModal,
            BlogCard
        },
        data() {
            return {
                page: 1,
                isSkim: false,
                cardData: {
                    id: 0,
                    slug: "",
                    total_percentage_skim: 0,
                    user:{
                        id:0,
                        slug:"",
                        profile_pic:"",
                        sec_1: '',
                        name: '',
                        total_user_subscribers: 0,
                        is_subscribe:false,
                        description: '',
                        social_user: {
                            fb_url: '',
                            twitter_url: '',
                            youtube_url: ''
                        },
                    }
                },
            };
        },
        computed: {
            ...mapGetters({
                defaultCountry: "country/defaultCountry",
                getSearchArticles: "article/getSearchArticles",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        },
        methods: {
            emitSkimOpenModel(article){
                this.isSkim = true;
                this.cardData = article;
                $('#skim-modal').modal('show');
            },
            emitCloseModel() {
                $('#skim-modal').modal('hide');
                this.isSkim = false
            },
            emitIsSkimData(data){
                this.cardData.total_views_skim = data.total_views_skim
            },
            checkCardLine(index,total){
                if(total>=4 && index===3){
                    return false;
                }
                if(total === 3 && index===2){
                    return false;
                }
                if(total===2 && index===1){
                    return false;
                }
                if(total===1 && index===0){
                    return false;
                }
                return true;
            },
        },
        mounted() {

        },
    };
</script>
<style scoped>

</style>
