<template>
  <div id="UserList">
       <div class="row">
        <div class="col-12 col-sm-12">
            <table ref="myTable" class="table dataTable table-striped table-bordered" style="width:100%">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
<!--                    <th>Office</th>-->
<!--                    <th>Age</th>-->
<!--                    <th>Start date</th>-->
                    <th>Action</th>
                </tr>
                </thead>
                <tbody v-if="allUsers.length>0">
                <tr v-for="user in allUsers" :key="`user-${user.id}`">
                    <td>{{user.name}}</td>
                    <td>{{user.email}}</td>
                    <td>
                        <span v-if="user.is_banned" @click="userBanStatus(user)"
                               class="btn btn-sm btn-danger cursor ml-2">Banned</span>
                        <span v-else class="btn btn-sm btn-success badges cursor ml-2" @click="userBanStatus(user)">Ban</span>
                        <span v-if="user.is_heading" @click="headingRequestPermission(user)"
                              class="btn btn-dark btn-sm cursor ml-2">InActive</span>
                        <span v-else @click="headingRequestPermission(user)" class="btn btn-info btn-sm cursor ml-2">Active</span>
                        <span v-if="user.is_monetize" @click="userMonetizeStatus(user)" class="btn btn-warning btn-sm cursor ml-2">Monetized</span>
                        <span v-else @click="userMonetizeStatus(user)" class="btn btn-primary btn-sm cursor ml-2">Monetize</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
       </div>
  </div>
</template>
<!--<style>-->
<!--    @import '~datatables.net-bs4/css/dataTables.bootstrap4.min.css';-->
<!--</style>-->
<script>
    import $ from 'jquery';
    import 'datatables.net';
    import  'datatables.net-bs4/css/dataTables.bootstrap4.css'
    import {mapGetters} from 'vuex';
export default {
   name:"UserListing",
    data(){
       return{
       }
    },
    computed:{
      ...mapGetters({allUsers:"auth/getAllUsers"})
    },
    methods:{
       usersDetails(){
           this.$store.dispatch("auth/getAllUsers").then(()=>{
               $(this.$refs.myTable).DataTable({
                   paging: true,
                   searching: true,
                   ordering: true
               })
           });
       },
        userBanStatus(user) {
            let payload = {
                slug: user.slug,
            }
            this.$store.dispatch("auth/updateUserIsBanned", payload);
        },
        headingRequestPermission(user) {
            let payload = {
                slug: user.slug,
            }
            this.$store.dispatch("auth/headingRequestPermission", payload);
        },
        userMonetizeStatus(user){
            let payload = {
                slug: user.slug,
            }
            this.$store.dispatch("auth/updateUserMonetizeStatus", payload);
        },
    },
   async mounted() {
       await this.usersDetails()
    }
}

</script>
<style  scoped>
.custom-table .button-edit{
  column-gap: 10px;
}
.custom-header{
    background: #F5F6FA;
    color:#8F96AA;
}
.custom-header .custom-head{
    font-size: 16px;
}
.custom-profile-name{
    column-gap: 10px;
}
</style>