import state from './moduleCountryState.js'
import mutations from './moduleCountryMutations.js'
import actions from './moduleCountryActions.js'
import getters from './moduleCountryGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
