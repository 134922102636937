<template>
    <div id="blog" class="hide">
        <div class="row">
            <div class="col-12 col-lg-8 col-md-12 col-sm-8 mx-auto">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"
                />
                <div class="blogHeading">
                    <h1 class="first-heading" :style="articleDetail.font_family ? 'font-family:'+articleDetail.font_family.title+'!important; color: '+articleDetail.font_family.title_color+'!important;':''">{{ articleDetail.title }}</h1>
                    <!--                    <h2 class="second-heading articleHeading mt-0">{{articleDetail.sub_title}}</h2>-->
                </div>
                <VideoOrImageFrame
                        :videoUrl="articleDetail.video_url"
                        :imageUrl="articleDetail.featured_image"
                        imageClass="default-view-image"
                        mainClass="article-width"


                />
            </div>

            <div class="col-12 col-lg-8 col-md-12 col-sm-8 mx-auto">
                <!---Footer--->
                <div v-if="upcomingArticle !== 'UpcomingDetail'"  class="social-icon social-icon-blog d-flex mt-lg-3 mt-md-3 mt-sm-3">
                    <div class="view articleView mb-0 w-25">{{articleDetail.total_views_skim}} Views</div>
                    <div class="w-50 position-relative progress-bar-wrapper">
                        <ProgressBar :totalPercentage="articleDetail.total_percentage"/>
                    </div>
                </div>
                <ProgressBarPercentage :totalPercentage="articleDetail.total_percentage"/>
            </div>

        </div>
    </div>
</template>

<script>
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import {mapGetters} from "vuex";
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";
    import ProgressBar from "../ArticleDetail/ProgressBar";
    import ProgressBarPercentage from "../ArticleDetail/ProgressBarPercentage";

    export default {

        name: "ArticleWidth",
        components: {
            userProfileDetail, VideoOrImageFrame, ProgressBar,ProgressBarPercentage
        },
        props: {
            articleDetail: Object,
            upcomingArticle:String,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            shareArticle(article) {
                const route = this.$router.resolve({
                    path: 'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Ftheworded.com%2F&',
                    params: {title: article.title},
                });
                window.open(route.href, '_blank');
            },
            profile() {
                this.$store.dispatch('auth/updateUserProfileDetail', this.cardData.user).then(() => {
                    this.$router.push({name: 'userProfile'})
                })
            }
        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        }
    };
</script>
