<template>
    <div class="font-modal">
        <!-- Modal -->
        <div class="modal fade" id="fontModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-title" id="exampleModalLabel">
                            Font Modal
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="row mb-3">
                            <div class="col-sm-6 ">
                                <span class="fw-bold">Title</span>
                                <select v-model="title" :style="'font-size:18px;font-family:' + title.toLowerCase()"
                                    class="form-control">
                                    <option v-for="font in titleArray" :key="font.id + Math.random()"
                                        :value="font.title"
                                        :style="'font-size:18px;font-family:' + font.title.toLowerCase()">
                                        {{ font.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <span class="fw-bold">Title Color</span>
                                <div class="form-group">
                                    <input v-model="titleColor" type="color" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6 ">
                                <span class="fw-bold">H1</span>
                                <select v-model="h1" :style="'font-size:18px;font-family:' + h1.toLowerCase()"
                                    class="form-control">
                                    <option v-for="font in h1Array" :key="font.id + Math.random()" :value="font.title"
                                       :style="'font-size:18px;font-family:' + font.title.toLowerCase()">
                                        {{ font.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <span class="fw-bold">H1 Color</span>
                                <div class="form-group">
                                    <input v-model="h1Color" type="color" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                <span class="fw-bold">Quote</span>
                                <select v-model="blockquote"
                                    :style="'font-size:18px;font-family:' + blockquote.toLowerCase()"
                                    class="form-control">
                                    <option v-for="font in blockquoteArray" :key="font.id + Math.random()"
                                        :value="font.title"
                                        :style="'font-size:18px;font-family:' + font.title.toLowerCase()">
                                        {{ font.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <span class="fw-bold">Quote Color</span>
                                <div class="form-group">
                                    <input v-model="blockquoteColor" type="color" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                <span class="fw-bold">PT</span>
                                <select v-model="pt" :style="'font-size:18px;font-family:' + pt.toLowerCase()"
                                    class="form-control">
                                    <option v-for="font in ptArray" :key="font.id + Math.random()" :value="font.title"
                                        :style="'font-size:18px;font-family:' + font.title.toLowerCase()">
                                        {{ font.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <span class="fw-bold">PT Color</span>
                                <div class="form-group">
                                    <input v-model="ptColor" type="color" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                <span class="fw-bold">H2</span>
                                <select v-model="h2" :style="'font-size:18px;font-family:' + h2.toLowerCase()"
                                    class="form-control">
                                    <option value="" style="font-size:18px;">
                                        Please select font family for h2
                                    </option>
                                    <option v-for="font in h2Array" :key="font.id + Math.random()" :value="font.title"
                                        :style="'font-size:18px;font-family:' + font.title.toLowerCase()">
                                        {{ font.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <span class="fw-bold">H2 Color</span>
                                <div class="form-group">
                                    <input v-model="h2Color" type="color" class="form-control" />
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="validateBeforeSubmit" class="btn btn-secondary">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import helpers from "../../helpers";
import $ from "jquery";

export default {
    name: "FontsModal",
    props: {
        articleDetail: Object,
    },
    data() {
        return {
            title: "",
            h1: "",
            h2: "",
            pt: "",
            blockquote: "",
            titleColor: "#fffffF",
            h1Color: "#ffffff",
            h2Color: "#ffffff",
            ptColor: "#ffffff",
            blockquoteColor: "#ffffff",
            titleArray: [
                { id: 1, title: "Merriweather" },
                { id: 2, title: "Roboto Slab" },
                { id: 3, title: "Playfair Display" },
                { id: 4, title: "Cinzel" },
                { id: 5, title: "Cormorant Upright" },
                { id: 6, title: "Oswald" },
                { id: 7, title: "Teko" },
                { id: 8, title: "Julius Sans One" },
                { id: 9, title: "Righteous" },
                { id: 10, title: "Barrio" },
                { id: 11, title: "Lobster" },
                { id: 12, title: "Londrina Solid" },
                { id: 13, title: "Dancing Script" },
                { id: 14, title: "Patrick Hand" },
                { id: 15, title: "Roboto" },
                { id: 16, title: "Montserrat" },
                { id: 17, title: "Jura" },
                { id: 18, title: "IBM Plex Mono" },
                { id: 19, title: "Inknut Antiqua" },
                { id: 20, title: "Finger Paint" },

            ],
            h1Array: [
                { id: 1, title: "Merriweather" },
                { id: 2, title: "Roboto Slab" },
                { id: 3, title: "Playfair Display" },
                { id: 4, title: "Cinzel" },
                { id: 5, title: "Cormorant Upright" },
                { id: 6, title: "Oswald" },
                { id: 7, title: "Teko" },
                { id: 8, title: "Julius Sans One" },
                { id: 9, title: "Righteous" },
                { id: 10, title: "Barrio" },
                { id: 11, title: "Lobster" },
                { id: 12, title: "Londrina Solid" },
                { id: 13, title: "Dancing Script" },
                { id: 14, title: "Patrick Hand" },
                { id: 15, title: "Roboto" },
                { id: 16, title: "Montserrat" },
                { id: 17, title: "Jura" },
                { id: 18, title: "IBM Plex Mono" },
                { id: 19, title: "Inknut Antiqua" },
                { id: 20, title: "Finger Paint" },
            ],
            h2Array: [
                { id: 1, title: "Merriweather" },
                { id: 2, title: "Roboto Slab" },
                { id: 3, title: "Playfair Display" },
                { id: 4, title: "Cinzel" },
                { id: 5, title: "Cormorant Upright" },
                { id: 6, title: "Oswald" },
                { id: 7, title: "Teko" },
                { id: 8, title: "Julius Sans One" },
                { id: 9, title: "Righteous" },
                { id: 10, title: "Barrio" },
                { id: 11, title: "Lobster" },
                { id: 12, title: "Londrina Solid" },
                { id: 13, title: "Dancing Script" },
                { id: 14, title: "Patrick Hand" },
                { id: 15, title: "Roboto" },
                { id: 16, title: "Montserrat" },
                { id: 17, title: "Jura" },
                { id: 18, title: "IBM Plex Mono" },
                { id: 19, title: "Inknut Antiqua" },
                { id: 20, title: "Finger Paint" },
            ],
            ptArray: [
                { id: 1, title: "Merriweather" },
                { id: 2, title: "Roboto Slab" },
                { id: 3, title: "Roboto" },
                { id: 4, title: "Montserrat" },
                { id: 5, title: "Jura" },
                { id: 6, title: "IBM Plex Mono" },
                { id: 7, title: "Inknut Antiqua" },
                { id: 8, title: "Finger Paint" },
            ],
            blockquoteArray: [
                { id: 1, title: "Merriweather" },
                { id: 2, title: "Roboto Slab" },
                { id: 3, title: "Playfair Display" },
                { id: 4, title: "Cinzel" },
                { id: 5, title: "Cormorant Upright" },
                { id: 6, title: "Oswald" },
                { id: 7, title: "Teko" },
                { id: 8, title: "Julius Sans One" },
                { id: 9, title: "Righteous" },
                { id: 10, title: "Barrio" },
                { id: 11, title: "Lobster" },
                { id: 12, title: "Londrina Solid" },
                { id: 13, title: "Dancing Script" },
                { id: 14, title: "Patrick Hand" },
                { id: 15, title: "Roboto" },
                { id: 16, title: "Montserrat" },
                { id: 17, title: "Jura" },
                { id: 18, title: "IBM Plex Mono" },
                { id: 19, title: "Inknut Antiqua" },
                { id: 20, title: "Finger Paint" },
            ],
        };
    },
    methods: {
        validateBeforeSubmit() {
            let payload = {
                article_id: this.articleDetail.id,
                title: this.title,
                h1: this.h1,
                h2: this.h2,
                pt: this.pt,
                blockquote: this.blockquote,
                title_color: this.titleColor,
                h1_color: this.h1Color,
                h2_color: this.h2Color,
                pt_color: this.ptColor,
                blockquote_color: this.blockquoteColor,
            };
            this.$store
                .dispatch("article/createFontFamily", payload)
                .then((response) => {
                    helpers.successMessages(this, response);
                    this.clickButtonToCloseModal("fontModal");
                })
                .catch((error) => {
                    helpers.errorMessage(this, error);
                });
        },
        clickButtonToCloseModal(id) {
            $("#" + id).modal("hide");
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open");
        },
        updateModal() {
            if (this.articleDetail.font_family) {
                this.title = this.articleDetail.font_family.title;
                this.h1 = this.articleDetail.font_family.h1;
                this.h2 = this.articleDetail.font_family.h2;
                this.pt = this.articleDetail.font_family.pt;
                this.blockquote = this.articleDetail.font_family.blockquote;
                this.titleColor = this.articleDetail.font_family.title_color
                this.h1Color = this.articleDetail.font_family.h1_color
                this.h2Color = this.articleDetail.font_family.h2_color
                this.ptColor = this.articleDetail.font_family.pt_color
                this.blockquoteColor = this.articleDetail.font_family.blockquote_color
            }
        },
    },
    mounted() {
        this.updateModal()
    },
    watch: {
        articleDetail: {
            handler() {
                this.updateModal()
            },
            deep: true
        }
    }
};
</script>
<style scoped>
.fw-bold {
    font-weight: bold;
}

.font-title {
    font-weight: bold;
    font-size: 20px;
}
</style>
