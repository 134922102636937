<template>
  <div class="uniqueNoteModal">
    <!-- Button trigger modal -->
   
    <!-- Modal -->
    <div class="modal fade pr-0"  id="uniqueNote" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-sm-centered mobile-model-width" role="document">
            <div class="modal-content unique-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Article Note</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  <p id="show-note"></p>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
name:"UniqueNoteModal",
}
</script>

<style scoped>
  
#show-note{
    word-break: break-word;
}

</style>
