<template>
    <div class="submit-heading-request">
        <div class="row mb-3">
            <div class="col-12 col-md-10 col-sm-12">
                <form action="">
                    <div class=" heading-form request-form">
                        <input @click="checkHeadingAndBody" id="submitHeading" autocomplete="off" v-model="headingRequest.heading_request" type="text" maxlength="100" placeholder="Enter Heading Request"
                               class="form-control submit-input" autofocus>
                        <div class="float-right total-length">120</div>
                    </div>
                </form>
            </div>
            <div class="col-3 col-sm-2 d-md-block d-sm-none d-none">
                <button @click="submitHeadingRequest" :disabled="isDisabled" type="submit"
                        class=" mb-3 btn write-button btn-write shadow-none submit-btn d-sm-block d-block">Submit
                </button>
            </div>
        </div>
        <div class="row mb-3" v-if="content">
            <div class="col-12 col-sm-12 upcoming-summernote-content" >
                <summernote
                        :tweetEmbed="tweetEmbed"
                        :isTweet="isTweet"
                        :isTweetId="'tweetModal'+articleDetail.id"
                        @emitIsTweet="emitIsTweet"
                        :id="'summernoteEditorSubmit'"
                        @fileUpload="fileUpload"
                        :config="summernoteConfig"
                        @ready="readySummernote">
                </summernote>
            </div>
        </div>
        <div class="d-flex justify-content-end d-md-none d-sm-block">
            <button @click="submitHeadingRequest" :disabled="isDisabled" type="submit"
                class=" mb-3 btn write-button btn-write shadow-none submit-btn d-sm-block d-block">Submit
            </button>
        </div>
        
        <TweetModal :id="'tweetModal'+articleDetail.id" @emitTweetLink="emitTweetLink"/>
        
    </div>
</template>

<script>
    import $ from 'jquery';
    import Helper from "../../helper";
    import Summernote from "../../views/Summernote";
    import TweetModal from "../Modals/TweetModal";


    export default {
        name: "SubmitHeadingRequest",
        data() {
            return {
                tweetEmbed: "",
                isTweet: false,
            headingRequest:{
                heading_request: "",
                heading_content:""
            },
                summernoteConfig: {
                    tabsize: 2,
                    placeholder: 'Enter description here',
                    height: 500,
                    fontNames: [
                        'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New',
                        'Helvetica Neue', 'Helvetica', 'Impact', 'Lucida Grande',
                        'Tahoma', 'Times New Roman', 'Verdana', 'Microsoft YaHei'
                    ],
                    dialogsInBody: true,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline']],
                        ['para', ['ul', 'ol']],
                        ['insert', ['link', 'picture', 'video', 'hr']],
                        ['mybutton', ['quote']],
                        ['mybutton', ['tweet']],
                    ],
                    popover: {
                        image: [
                            ["image", ["resizeQuarter", "resizeHalf", "resizeHalf1", "resizeFull"]],
                            ["float", ["floatLeft", "floatRight"]],
                            ["remove", ["removeMedia", "imageCaption"]],
                        ],
                        link: [["link", ["linkDialogShow", "unlink"]]],
                        air: [
                            ["color", ["color"]],
                            ["font", ["bold", "underline", "clear"]],
                            ["insert", ["link", "picture"]],
                        ],
                    },
                },
                content:false,
            }
        },
        components:{
            Summernote,
            TweetModal
        },
        props: {
            isLogin: {
                type: Boolean,
                default: false,
            },
            articleDetail:Object
        },
        computed: {
            isDisabled() {
                return this.headingRequest.heading_request === ""
            },
        },
        methods: {
            emitTweetLink(tweet) {
                this.tweetEmbed = tweet
                this.isTweet = true
            },
            emitIsTweet(val) {
                this.isTweet = val
            },
            readySummernote(editor) {
                editor.summernote("code", this.headingRequest.heading_content);
                editor.$on("change", (contents) => {
                    this.headingRequest.heading_content = contents;
                });
            },
            submitHeadingRequest() {
                if(!this.isLogin){
                    return $('#LoginModal').modal('show');
                }
                if (this.headingRequest.heading_request === "") {
                    return Helper.toast(this, 'Alert', 'Please enter the heading request first', 'danger')
                }
                if(this.articleDetail.is_heading){
                    if (this.headingRequest.heading_content === "") {
                        return Helper.toast(this, 'Alert', 'Please enter the heading request description', 'danger')
                    }
                }
                this.$emit('emitSubmitHeadingRequest', this.headingRequest);
                this.headingRequest = {
                    heading_request: "",
                    heading_content:""
                }
                $('#summernoteEditorSubmit').summernote('reset')
            },
            fileUpload(val) {
                this.submitArticleApi = val;
            },
            checkHeadingAndBody(){
                if(this.articleDetail.is_heading){
                    this.content =true;
                }
                $('#submitHeading').focus();
                return this.content;
            }
        },
    }
</script>

<style scoped>
    .request-form .submit-input {
        height: 33px;
        font-size: 13px;
    }

    .submit-btn {
        padding: 0 17px;
        height: 35px !important;
        font-size: 14.4px;
        border: 2px solid black;
        border-radius: 0;

    }

    .submit-btn:hover {
        color: black;
    }

    .heading-form .total-length {
        font-size: 12px;
        color: gray;
        font-size: 12px
    }
</style>