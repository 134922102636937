export default {
    getUpcomingArticle: state => {
        return state.upcoming
    },
    getBreakingArticle: state => {
        return state.breaking
    },
    getSubscribedArticle: state => {
        return state.subscribed
    },
    getCategoryArticle: state => {
        return state.categoryArticle
    },

    getLastPageNumber: state => {
        return state.paginate.meta.last_page
    },
    getPageNumber: state => {
        return state.paginate.page
    },
    getHomePageArticle: state => {
        return state.articles;
    },
    getArticleDetails: state => {
        return state.articleDetail;
    },
    getDraftAndUpcoming: state => {
        return state.draftAndUpcoming;
    },
    getPublishedArticles: state => {
        return state.publishedArticles;
    },
    getPublishedArticlesByUser: state => {
        return state.publishedArticlesByUser;
    },
    getAllBreakingArticles: state => {
        return state.allBreakingArticles;
    },
    getAllUpcomingArticles: state => {
        return state.allUpcomingArticles;
    },
    getAllSubscribedArticles: state => {
        return state.allSubscribedArticles;
    },
    getDraftPage: state => {
        return state.draftPage;
    },
    getPublishedPage: state => {
        return state.publishPage;
    },
    getPublishedByUserPage: state => {
        return state.publishByUserPage;
    },
    getAllBreakingPage: state => {
        return state.breakingPage;
    },
    getAllSubscribedPage: state => {
        return state.subscribedPage;
    },
    getAllUpcomingPage: state => {
        return state.upcomingPage;
    },
    getAllCommentsSendPage: state => {
        return state.allCommentsSendPage
    },
    getAllCommentsReceivedPage: state => {
        return state.allCommentsReceivedPage
    },
    getAllCommentsSend: state => {
        return state.allCommentsSend
    },
    getAllCommentsReceived: state => {
        return state.allCommentsReceived;
    },
    getWriterReplyCommentsSend: state => {
        return state.writerReplyCommentsSend;
    },
    getWriterReplyCommentsReceived: state => {
        return state.writerReplyCommentsReceived;
    },
    getArticleSearch: state => {
        return state.articleSearch;
    },
    getAllHashtagArticles: state => {
        return state.allHashtagArticles;
    },
    getHashtagPage: state => {
        return state.getHashtagPage;
    },
    getSearchArticles: state => {
        return state.searchArticles;
    },
    getSearchUsers: state => {
        return state.searchUsers;
    },
    getArticleDetailCommentsPageNumber: state => {
        return state.articleDetailCommentsPageNumber;
    },
    getArticleDetailComments: state => {
        return state.articleDetailComments;
    },
    getHashTagData: state => {
        return state.hashTagData;
    },
    getCardData: state => {
        return state.cardData;
    },
    getAllHeadingRequest: state => {
        return state.allHeadingRequests
    },
    getAllArticleSuggestion: state => {
        return state.allArticleSuggestion
    },
    getUserDetail:state=>{
        return state.userDetail;
    },
    getUpcomingCount:state=>{
        return state.upcomingCount;
    },
    getSuggestionNotification:state=>{
        return state.checkSuggestionNotification
    },
    getCustomTags:state=>{
        return state.customTags;
    },
     getAllArticles:state=>{
        return state.allArticles;
     }
}


