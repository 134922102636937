<template>

    <div class="progress">
         <div v-for="(percentage,index) in totalPercentage" :key="percentage+index+'_'+Math.random()" :title="getTitle(index)" :class="getClass(index)"
             role="progressbar" :style="{'width':percentage+'%'}">
<!--            <span v-if="percentage!==0">{{percentage}}%</span>-->
        </div>
    </div>


</template>
<script>
    export default {
        name: "ProgressBar",
        props: {
            totalPercentage: Array
        },
        methods: {
            getTitle(index) {
                if (index === 0) {
                    return 'Supportive'
                }
                if (index === 1) {
                    return 'neutral'
                }
                if (index === 2) {
                    return 'Critique'
                }

            },
            getClass(index) {
                if (index === 0) {
                    return 'progress-bar bg-success'
                }
                if (index === 1) {
                    return 'progress-bar neutral'
                }
                if (index === 2) {
                    return 'progress-bar bg-danger'
                }

            }

        }
    }

</script>
<style scoped>
.neutral{
    background-color: darkgray!important;
color: darkgray !important;;
}

</style>