<template>
    <div v-if="userId !== loginUserId"
         :class="'badge badge-pill '+ isButtonSubscriber() +' subscribe-btn text-white p-1'" @click="subscribe">
        <i>{{isSubscriber()+" "+totalUserSubscribers}}</i>
    </div>
</template>
<script>
    export default {
        props: {
            loginUserId: Number,
            userId: Number,
            totalUserSubscribers: Number,
            IsSubscribe: Boolean,
            type: String
        },
        data() {
            return {}
        },
        methods: {
            isSubscriber() {
                if (this.IsSubscribe) {
                    return 'Unsubscribe'
                }
                return 'Subscribe'
            },
            isButtonSubscriber() {
                if (this.IsSubscribe) {
                    return 'badge-danger'
                }
                return 'badge-info'
            },
            subscribe() {
                let object = {
                    subscriber_id: this.userId,
                    type: this.type
                }
                this.$store.dispatch('article/updateUserSubscriber', object).then(response=>{
                    this.$emit("emitUpdateUserSubscriber",response.data)
                })

            }

        }

    }

</script>
<style scoped>
.subscribe-btn {
        padding: 4px;
        cursor: pointer;
    }
  
</style>
