<template>
    <div id="blog" class="banner">
        <div class="row">
            <div class="col-lg-9 col-md-12 col-sm-8 mx-auto">
                <userProfileDetail
                        :articleDetail="articleDetail"
                        :getAuthUser="getAuthUser"
                        :isLogin="isLogin"
                />
            </div>
            <!---image---->
            <div class="col-sm-12 col-md-12 col-lg-12">
                <VideoOrImageFrame
                        :videoUrl="articleDetail.video_url"
                        :imageUrl="articleDetail.featured_image"
                        imageClass="default-view-image"
                        mainClass="banner-box-image"
                />
            </div>


            <div class="col-lg-8 col-md-12 col-sm-8 mx-auto mt-3">
                <div class="">
                    <h1 class="first-heading" :style="articleDetail.font_family ? 'font-family:'+articleDetail.font_family.title+'!important; color: '+articleDetail.font_family.title_color+'!important;':''">{{ articleDetail.title }}</h1>
                    <!--                    <h2 class="smallTitle mb-2">{{articleDetail.sub_title}}</h2>-->
                </div>
            </div>
        </div>

        <div class="row">
            <!---Footer--->
            <div v-if="upcomingArticle !== 'UpcomingDetail'" class=" col-lg-8 col-md-12 col-sm-8 mx-auto">
                <div class="social-icon social-icon-blog d-flex mt-lg-4 mt-sm-0">
                    <div class="viewBanner mb-0 w-26 ml-lg-0  ml-md-4">{{articleDetail.total_views_skim}} Views</div>
                    <div class="w-50 position-relative progress-bar-wrapper">
                        <ProgressBar :totalPercentage="articleDetail.total_percentage"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-8  mx-auto text-end">
                <div class="progress-date d-flex  w-25 float-right">
                    <div v-for="(percentage,index) in articleDetail.total_percentage" :key="Math.random()+index" :title="getTitle(index)">
                        <span v-if="percentage>0" :class="getClass(index)">{{percentage}}%</span>
                        <span v-else :class="getClass(index)"><i style="font-size: 20px;" :class="getClassPercentage(index)"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {mapGetters} from "vuex";
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import VideoOrImageFrame from "../VideoOrImage/VideoOrImageFrame";
    import ProgressBar from "../ArticleDetail/ProgressBar";


    export default {
        name: "Banner",
        components: {userProfileDetail, VideoOrImageFrame, ProgressBar},
        props: {
            articleDetail: Object,
            upcomingArticle:String,
        },
        data() {
            return {
                isToggle: false,
            };
        },
        methods: {
            checkToggle() {
                this.isToggle = !this.isToggle;
            },
            profile() {
                localStorage.setItem('profile', JSON.stringify(this.articleDetail.user))
                this.$router.push('/user/profile');
            },
            getClassPercentage(index) {
                if (index === 0) {
                    return 'fa-solid fa-minus text-success'
                }
                if (index === 1) {
                    return 'fa-solid fa-minus text-dark'
                }
                if (index === 2) {
                    return 'fa-solid fa-minus text-danger'
                }

            },
            getClass(index) {
                if (index === 0) {
                    return 'text-success'
                }
                if (index === 1) {
                    return 'text-dark'
                }
                if (index === 2) {
                    return 'text-danger'
                }

            },
            getTitle(index) {
                if (index === 0) {
                    return 'Supportive'
                }
                if (index === 1) {
                    return 'Neutral'
                }
                if (index === 2) {
                    return 'Critique'
                }

            },

        },
        computed: {
            ...mapGetters({
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
            }),
        }
    };
</script>

<style scoped>
    .viewBanner{
        font-size: 14px !important;
        font-family: 'Roboto' !important;
    }

</style>
