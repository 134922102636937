import axios from "../../axios.js";

export default {
    async getCountries({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/country').then((response) => {
                commit('GET_COUNTRIES', response)
                commit('UPDATE_DEFAULT_COUNTRY')
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getCountriesForCustom({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/country/custom').then((response) => {
                commit('GET_COUNTRIES_FOR_CUSTOM', response)
                commit('UPDATE_DEFAULT_COUNTRY')
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },


    async getSelectedCountryForCustom({commit}, countryId) {
        return new Promise((resolve, reject) => {
            axios.get('/country/' + countryId).then((response) => {
                commit('SELECTED_COUNTRY_FOR_CUSTOM', response)
                commit('UPDATE_DEFAULT_COUNTRY')
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getSelectedCountry({commit}) {
        commit('UPDATE_DEFAULT_COUNTRY')
    },

    async selectedFlag({commit}, countryId) {
        return new Promise((resolve, reject) => {
            axios.get('/country/' + countryId).then((response) => {
                commit('SELECTED_FLAG', response)
                commit('UPDATE_DEFAULT_COUNTRY')
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async customSelectedFlag({commit}, countryId) {
        commit('CUSTOM_SELECTED_FLAG', countryId)
        commit('UPDATE_DEFAULT_COUNTRY')
    }

}
