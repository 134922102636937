<template>
    <div id="blog-card">
        <div class="blog-card">
            <div class=" d-flex justify-content-end align-items-center" style="column-gap: 10px;">
            
            <div class="custom-tooltip order-1" v-if="tooltip()">
                <i @click="checkToggle" class="fa fa-ellipsis-v tooltip-icon" aria-hidden="true"></i>
                <ToolTip v-if="isToggle" :cardData="cardData" @editArticle="editArticle" />
            </div>
        <div class="card-badge order-0" style="text-align: end;">
            <span v-if="cardData.status.status === 'Upcoming'" class="badge badge-secondary">Upcoming</span>
        </div>
            </div>
            <router-link v-if="!isEdit" :to="isEditRoute()" target="_top"
                class="row  cursor pt-xl-4 pt-md-4 pb-4 pt-sm-0  pt-0" @click.native.capture="detail">
                <div class="col-12 col-md-8 col-lg-7 col-xl-8 col-sm-12 order-md-first order-sm-first order-first">
                    <div class="body-card">
                        <h5 class="card-title text-break text-wrap fw-bold text-dark">{{ cardData.title }}</h5>
                        <p v-if="skimData.length > 0" class="card-text mb-0 text-break text-wrap">{{ skimData[0].text }}</p>
                        <p v-else class="card-text mb-0 text-break text-wrap">{{ cardData.description }}</p>
                        <p v-if="isUpcoming" class="card-text mb-0 ">{{ cardData.upcoming_content }}</p>
                        <div class="footer d-flex pt-2">
                            <div class="profile-image">
                                <div>
                                    <router-link :to="{ name: 'userProfile', params: { slug: cardData.user.slug } }"
                                        @click.native.capture="profile" target="_top"
                                        class="cursor d-flex footer-profile-area align-items-center">

                                        <div class="card-profile-image d-inline-block">
                                            <img :src="cardData.user.profile_pic" alt="profile-image"
                                                class="image img-fluid mr-2">
                                        </div>
                                    </router-link>
                                    <div>
                                        <router-link :to="{ name: 'userProfile', params: { slug: cardData.user.slug } }"
                                            @click.native.capture="profile" target="_top"
                                            class="cursor d-flex footer-profile-area align-items-center">
                                            <div class="card-profile-name  d-inline-block">
                                                <span class="profile-name fw-bold">{{ cardData.user.name }}</span>&nbsp;
                                            </div>
                                        </router-link>
                                        <span class="footer-deatil">{{ cardData.total_views_skim }} Views
                                            <span class="dot"></span> {{ cardData.created_at_home }}
                                            <a href="#" class="badge badge-pill bg p-2"></a>
                                            <img style="filter:grayscale(100%);" v-if="cardData.video_url"
                                                src="/img/youtube-logo.898d3054.png" alt="Youtube link" width="18px"
                                                height="10px" class="mt-0 pl-1 cursor youtube-link-icon">
                                        </span>
                                    </div>

                                    <!-- <span class="footer-deatil"><span class="dot"></span> {{cardData.total_views_skim}} Views
                                        <span class="dot"></span> {{cardData.created_at_home}}
                                            <a href="#" class="badge badge-pill bg p-2"></a>
                                            <img style="filter:grayscale(100%);" v-if="cardData.video_url" src="/img/youtube-logo.898d3054.png" alt="Youtube link" width="18px" height="10px" class="mt-0 pl-1 cursor youtube-link-icon">
                                    </span> -->
                                </div>
                            </div>
                            <a href="javascript:void(0)" target="_self" @click.prevent.stop="highlightedText"
                                v-if="skimData.length > 0" class="skim-modal-btn">
                                <u>SKIM</u>
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="col-12 col-md-4 col-sm-12 col-lg-5 col-xl-4 card order-md-last order-sm-last order-last pt-md-0">
                    <BlogCardVideoOrImageFrame :videoUrl="cardData.video_url" :imageUrl="cardData.thumbnail"
                        imageClass="" />
                </div>
            </router-link>
            <a v-if="isEdit" href="javascript:void(0)" target="_top" class="row pb-4 cursor pt-xl-4 pt-md-4 pt-sm-0  pt-0"
                @click="editArticle(cardData)">
                <div class="col-12 col-md-8 col-lg-7 col-xl-8 col-sm-12 order-md-first order-sm-first order-first">
                    <div class="body-card">
                        <h5 class="card-title text-break text-wrap fw-bold text-dark">{{ cardData.title }}</h5>
                        <p v-if="skimData.length > 0" class="card-text mb-0 text-break text-wrap">{{ skimData[0].text }}</p>
                        <p v-if="isUpcoming" class="card-text mb-0 ">{{ cardData.upcoming_content }}</p>
                        <div class="footer d-flex pt-2">
                            <div class="profile-image">
                                <div>
                                    <router-link :to="{ name: 'userProfile', params: { slug: cardData.user.slug } }"
                                        @click.native.capture="profile" target="_top"
                                        class="cursor d-flex footer-profile-area align-items-center">

                                        <div class="card-profile-image d-inline-block">
                                            <img :src="cardData.user.profile_pic" alt="profile-image"
                                                class="image img-fluid mr-2">
                                        </div>
                                    </router-link>
                                    <div>
                                        <router-link :to="{ name: 'userProfile', params: { slug: cardData.user.slug } }"
                                            @click.native.capture="profile" target="_top"
                                            class="cursor d-flex footer-profile-area align-items-center">
                                            <div class="card-profile-name  d-inline-block">
                                                <span class="profile-name fw-bold">{{ cardData.user.name }}</span>&nbsp;
                                            </div>
                                        </router-link>
                                        <span class="footer-deatil">{{ cardData.total_views_skim }} Views
                                            <span class="dot"></span> {{ cardData.created_at_home }}
                                            <a href="#" class="badge badge-pill bg p-2"></a>
                                            <img style="filter:grayscale(100%);" v-if="cardData.video_url"
                                                src="/img/youtube-logo.898d3054.png" alt="Youtube link" width="18px"
                                                height="10px" class="mt-0 pl-1 cursor youtube-link-icon">
                                        </span>
                                    </div>

                                    <!-- <span class="footer-deatil"><span class="dot"></span> {{cardData.total_views_skim}} Views
                                        <span class="dot"></span> {{cardData.created_at_home}}
                                            <a href="#" class="badge badge-pill bg p-2"></a>
                                            <img style="filter:grayscale(100%);" v-if="cardData.video_url" src="/img/youtube-logo.898d3054.png" alt="Youtube link" width="18px" height="10px" class="mt-0 pl-1 cursor youtube-link-icon">
                                    </span> -->
                                </div>
                            </div>
                            <a href="javascript:void(0)" target="_self" @click.prevent.stop="highlightedText"
                                v-if="skimData.length > 0" class="skim-modal-btn">
                                <u>SKIM</u>
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="col-12 col-md-4 col-sm-12 col-lg-5 col-xl-4 card order-md-last order-sm-last order-last pt-md-0">
                    <BlogCardVideoOrImageFrame :videoUrl="cardData.video_url" :imageUrl="cardData.thumbnail"
                        imageClass="" />
                </div>
            </a>

        </div>

    </div>
</template>

<script>
// import SkimModal from "../Modals/SkimModal";
import ToolTip from "../ArticalDots/ToolTip";
import BlogCardVideoOrImageFrame from "../VideoOrImage/BlogCardVideoOrImageFrame";

import $ from "jquery";
import { mapGetters } from "vuex";

export default {
    name: "BlogCard",
    data() {
        return {
            skimData: [],
            isDetail: false,
            isProfile: false,
            isToggle: false,
        }
    },
    components: {
        ToolTip, BlogCardVideoOrImageFrame
    },
    computed: {
        ...mapGetters({
            isLogin: "auth/getIsUserLogin",
        })
    },
    props: {
        cardData: Object,
        isEdit: Boolean,
        isPublished: Boolean,
        upcomingTag: {
            default: false,
            type: Boolean
        },
        isUpcoming: {
            default: false,
            type: Boolean
        },
    },
    methods: {
        highlightedText() {
            this.$emit('emitSkimOpenModel', this.cardData);
        },
        skimShowOnHomePage() {
            var skimDataArray = [];
            if (this.cardData.content !== "") {
                var content = $('<html>').html(this.cardData.content).find('SKIM');
                content.map((e) => {
                    skimDataArray.push({ id: e, text: $(content[e]).text() });
                });
                this.skimData = [];
                skimDataArray.map((x) => {
                    this.skimData.push({ id: x.id, text: x.text })
                })
            }
        },
        checkToggle() {
            this.isToggle = !this.isToggle;
        },
        profile() {
            this.$store.dispatch('auth/updateUserProfileDetail', this.cardData.user).then(() => {
                this.$router.push({ name: 'userProfile', params: { slug: this.cardData.user.slug } })

            })
        },
        detail() {
            if (this.isUpcoming) {
                return {
                    name: 'UpcomingDetail',
                    params: { slug: this.cardData.slug, articleId: this.cardData.id }
                };
            }

            if (this.isEdit) {
                this.$store.dispatch('article/editArticle', this.cardData).then(() => {
                    localStorage.setItem('isEdit', JSON.stringify(this.isEdit))
                    localStorage.setItem('isPublish', JSON.stringify(this.isPublished))
                    return
                })
            }
            // if(this.isEdit){
            //     localStorage.setItem('cardData',JSON.stringify(this.cardData))
            //     this.$router.push({name:'writeArticle'})
            //     return
            // }
            // this.isProfile = true;
            // if(this.isDetail === false && this.isProfile === true && this.isSkim ===false){
            localStorage.setItem('articleId', this.cardData.id)
            localStorage.setItem('articleSlug', this.cardData.slug)
            // this.$router.push({ name: 'Details', params: { slug: this.cardData.slug, articleId:this.cardData.id } })
            // }
        },
        editArticle(cardData) {
            this.$store.dispatch('article/editArticle', cardData).then(() => {
                localStorage.setItem('isEdit', JSON.stringify(this.isEdit))
                localStorage.setItem('isPublish', JSON.stringify(this.isPublished))
                this.$router.push({ name: 'writeArticle' })
            })
        },
        isEditRoute() {
            if (this.isEdit) {
                // this.$store.dispatch('article/editArticle',this.cardData).then(()=>{
                //     localStorage.setItem('isEdit',JSON.stringify(true))
                // })
                return { name: 'writeArticle', params: { slug: this.cardData.slug, articleId: this.cardData.id } }
            }
            if (this.isUpcoming) {
                // if(this.isLogin){
                return { name: 'UpcomingDetail', params: { slug: this.cardData.slug, articleId: this.cardData.id } };
                // }
                // return false;
            }
            return { name: 'Details', params: { slug: this.cardData.slug, articleId: this.cardData.id } };
        },
        tooltip() {
            if (this.isUpcoming) {
                return false;
            }
            return this.isEdit || this.isPublished
        }
    },
    mounted() {
        this.skimShowOnHomePage();
    }
}
</script>

<style scoped>
.custom-tooltip {
    text-align: end;
    /*--padding-top: 10px;--*/
}

.blog-card {
    color: grey !important;
    font-family: 'Roboto' !important;
}

.card {
    border: none;
    background-color: transparent;
}

.profile-image .image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    /* border: 3px solid black;*/
}

.card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 700;
    font-family: "Roboto";
    font-size: 16px;
    word-break: break-all;
}

.card-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: gray;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto';
}

.footer {
    justify-content: space-between;
    align-items: center;
}

.footer .bg {
    background-color: #F2F2F2;
    color: rgba(117, 117, 117, 1);
}

.home-blog-image {
    max-width: 230px;
    width: 100%;
    height: 150px;
}

.home-blog-image .card-img-top {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.dot {
    height: 3px;
    width: 3px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.footer .footer-deatil {
    font-size: 13px;
    color: gray;
    font-weight: 400;
    font-family: 'Roboto';
}

.skim-modal-btn {

    cursor: pointer;
    z-index: 1;
}

.skim-modal-btn u {
    color: gray;
    z-index: 1;
    font-size: 14px;
}

.routerLink {
    color: gray;
}

.routerLink small {
    color: black;
}

.profile-name {
    font-size: 13px;
    color: gray;
    font-weight: 400;
    font-family: 'Roboto';
}

.card-profile-name {
    word-break: break-word !important;
}
</style>