import axios from "../../axios.js";

export default {
    async getArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/country/' + payload.countryId + '?type=' + payload.type, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getCategoryArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                    type: payload.type
                },
            }
            axios.get('/article/country/' + payload.countryId + '/category/' + payload.categoryId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_CATEGORY_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async setPageNumber({commit}, page) {
        commit('SET_PAGE_NUMBER', page)
    },
    async setCategoryPageNumber({commit}, page) {
        commit('SET_CATEGORY_PAGE_NUMBER', page)
    },

    async setHashTagData({commit}, data) {
        commit('SET_HASH_TAG_DATA', data)
    },
    async updatedPaginateArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/country/' + payload.countryId + '/category/' + payload.categoryId, object).then((response) => {
                commit('UPDATED_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getBreakingAndUpComingArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/breaking/upcoming/' + payload.id + '?type=' + payload.type).then((response) => {
                commit('GET_BREAKING_AND_UP_COMING_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getCategoryBreakingAndUpComingArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/breaking/upcoming/' + payload.countryId + '/category/' + payload.categoryId + '?type=' + payload.type).then((response) => {
                commit('GET_CATEGORY_BREAKING_AND_UP_COMING_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async articleDetail({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/detail/' + payload.slug).then((response) => {
                commit('ARTICLE_DETAIL', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async editArticleDetail({commit}, cardData) {
        commit('EDIT_ARTICLE_DETAIL', cardData)
    },

    async editArticle({commit}, cardData) {
        commit('EDIT_ARTICLE', cardData)
    },

    async addNewArticle({commit}) {
        commit('ADD_NEW_ARTICLE')
    },

    async addArticle({commit}, cardData) {
        commit('ADD_ARTICLE', cardData)
    },

    async createArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article', payload).then((response) => {
                commit('ARTICLE_DETAIL', response)
                commit('ADD_DRAFT_ARTICLE', response)
                commit('ADD_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async uploadFeatureImageOrGif({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/header/image', payload).then((response) => {
                commit('ARTICLE_DETAIL', response)
                commit('ADD_DRAFT_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async uploadHeaderDesktopOrMobileImageOrGif({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/thumbnail/images', payload).then((response) => {
                commit('ARTICLE_DETAIL', response)
                commit('ADD_DRAFT_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async uploadAudioFile({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/upload/audio', payload).then((response) => {
                commit('ARTICLE_DETAIL_AUDIO', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async emptyArticleDetail({commit}) {
        commit('EMPTY_ARTICLE_DETAIL')
    },
    async uploadEditorImage({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/upload_editor_image', payload).then((response) => {
                commit('UPLOAD_EDITOR_IMAGE')
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async imageCaption({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/image_caption', payload).then((response) => {
                commit('IMAGE_CAPTION', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getDraftAndUpcoming({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/user/draft/and/upcoming', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_DRAFT_AND_UPCOMING', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getPublishedArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/user/published/articles', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_PUBLISHED_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getPublishedArticlesBySlug({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/user/published/articles/'+payload.slug, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_PUBLISHED_ARTICLES_BY_USER', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async setPublishArticlePageNumber({commit}, payload) {
        commit("SET_PUBLISHED_ARTICLE_PAGE_NUMBER", payload)
    },
    async setPublishArticleByUserPageNumber({commit}, payload) {
        commit("SET_PUBLISHED_ARTICLE__BY_USER_PAGE_NUMBER", payload)
    },
    async getAllBreakingArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                    type: payload.type
                },
            }
            axios.get('/article/breaking/country/' + payload.countryId + '/category/' + payload.categoryId, object).then((response) => {
                // axios.get('/article/breaking/' + payload.countryId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_BREAKING_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllSubscribedArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                    type: payload.type
                },
            }
            axios.get('/article/all/subscribed', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_SUBSCRIBED_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllUpcomingArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                    type: payload.type
                },
            }
            axios.get('/article/upcoming/country/' + payload.countryId + '/category/' + payload.categoryId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_UPCOMING_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async addComment({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/comment', payload).then((response) => {
                commit('ADD_COMMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async addLikeOrDislike({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/comment/like', payload).then((response) => {
                commit('ADD_LIKE_OR_DISLIKE_COMMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async deleteLikeOrDislike({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete('/article/comment/like', {params: payload}).then((response) => {
                commit('ADD_LIKE_OR_DISLIKE_COMMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllCommentsSend({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/comments/all/send', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_COMMENTS_SEND', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllCommentsReceived({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/comments/all/received', object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_COMMENTS_RECEIVED', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getWriterReplyCommentsSend({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/article/comments/writer/send').then((response) => {
                commit('GET_WRITER_REPLY_COMMENTS_SEND', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getCommentCustomTags({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/article/customize/tags').then((response) => {
                commit('GET_CUSTOM_TAGS', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getWriterReplyCommentsReceived({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/article/comments/writer/received').then((response) => {
                commit('GET_WRITER_REPLY_COMMENTS_RECEIVED', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getArticleSearch({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/search/' + payload.search).then((response) => {
                commit('GET_ARTICLE_SEARCH', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getSearchedArticleAndUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/searched/' + payload.search).then((response) => {
                commit('GET_ARTICLE_SEARCHED', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async emptyArticleSearch({commit}) {
        commit('EMPTY_ARTICLE_SEARCH')
    },
    async getAllHashtagArticles({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/hashtag/' + payload.slug + '/country/' + payload.countryId + '?type=' + payload.type, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ALL_HASHTAG_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getArticleDetailComments({commit}, payload) {
        return new Promise((resolve, reject) => {
            let object = {
                params: {
                    page: payload.page,
                },
            }
            axios.get('/article/detail/comments/' + payload.articleId, object).then((response) => {
                Object.assign(response, {$state: payload.$state})
                commit('GET_ARTICLE_DETAIL_COMMENTS', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async updateUserSubscriber({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/user/subscriber', payload).then((response) => {
                if (payload.type === 'articleDetail') {
                    commit('UPDATE_ARTICLE_USER_SUBSCRIBER', response)
                }
                if (payload.type === 'searchUser') {
                    commit('UPDATE_SEARCH_USER_SUBSCRIBER', response)
                }
                if (payload.type === 'userProfile') {
                    commit('UPDATE_PROFILE_USER_SUBSCRIBER', response)
                }
                if (payload.type === 'homeBlog') {
                    commit('UPDATE_HOME_BLOG_USER_SUBSCRIBER', response)
                }
                if (payload.type === 'breaking') {
                    commit('UPDATE_ALL_BREAKING_USER_SUBSCRIBER', response)
                }
                if (payload.type === 'hashtag') {
                    commit('UPDATE_ALL_HASH_TAG_USER_SUBSCRIBER', response)
                }
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getUserAccountDetail({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('user/account/details/by/slug', payload).then((response) => {
                commit('GET_USER_ACCOUNT_BY_SLUG', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async archiveArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`/article/archive/${payload.slug}`).then((response) => {
                commit('ARCHIVE_PUBLISH_ARTICLE', response)
                commit('ARCHIVE_DRAFT_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async skimLikeDislike({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/rating`, payload).then((response) => {
                commit('SKIM_LIKE_DISLIKE_ARTICLE', response)
                commit('SKIM_LIKE_DISLIKE_BREAKING_ARTICLE', response)
                commit('SKIM_LIKE_DISLIKE_UPCOMING_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async addArticleViews({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/views`, payload).then((response) => {
                commit('ADD_ARTICLE_VIEWS', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async addArticleSkimCount({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/skim/count`, payload).then((response) => {
                commit('ADD_ARTICLE_VIEWS', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async setArticleDetailCommentsPageNumber({commit}, page) {
        commit('SET_ARTICLE_DETAIL_COMMENTS_PAGE_NUMBER', page)
    },
    async setHashtagPageNumber({commit}, page) {
        commit('SET_HASHTAG_PAGE_NUMBER', page)
    },
    async setBreakingPageNumber({commit}, page) {
        commit('SET_BREAKING_PAGE_NUMBER', page)
    },
    async setUpcomingPageNumber({commit}, page) {
        commit('SET_UPCOMING_PAGE_NUMBER', page)
    },
    async setSubscribedPageNumber({commit}, page) {
        commit('SET_SUBSCRIBED_PAGE_NUMBER', page)
    },
    async updateWriteArticle({commit}, cardData) {
        commit('UPDATE_WRITE_ARTICLE', cardData)
    },
    async readComments({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/read/comments`, payload).then((response) => {
                commit('READ_COMMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async createHeadingRequest({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/heading/request`, payload).then((response) => {
                commit('ARTICLE_DETAIL', response)
                commit('ADD_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async submitHeadingRequest({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/submit/heading/request`, payload).then((response) => {
                commit('HEADING_REQUEST', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async updateHeadingRequest({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/update/heading/request`, payload).then((response) => {
                commit('UPDATE_HEADING_REQUEST', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async cancelArticleSuggestion({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/article/cancel/pledge`, payload).then((response) => {
                commit('CANCEL_ARTICLE_SUGGESTION', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async addNotes({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/note', payload).then((response) => {
                commit('ADD_NOTE_ARTICLE_DETAIL', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async updateNotes({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.patch('/note/' + payload.uuid, payload).then((response) => {
                commit('UPDATE_NOTE_ARTICLE_DETAIL', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async deleteNotes({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete('/note/' + payload.uuid).then((response) => {
                commit('DELETE_NOTE_ARTICLE_DETAIL', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async headingRequestEndorsement({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('article/heading/request/endorsement', payload).then((response) => {
                commit('HEADING_REQUEST_ENDORSEMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async headingRequestLockUnlocked({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('article/heading/request/lock/unlock', payload).then((response) => {
                commit('HEADING_REQUEST_LOCK_UNLOCKED', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async headingRequestCollapse({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('article/heading/request/collapsable', payload).then((response) => {
                commit('HEADING_REQUEST_COLLAPSE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async cancelHeadingRequestEndorsement({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('article/cancel/endorsement', payload).then((response) => {
                commit('HEADING_REQUEST_ENDORSEMENT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getHeadingRequestAccordingToUser({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/article/get/heading/requests').then((response) => {
                commit('HEADING_REQUEST_ACCORDING_TO_USER', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getAllArticleSuggestionAccordingToArticle({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/heading/request/according/article', payload).then((response) => {
                commit('ALL_ARTICLE_SUGGESTION_ACCORDING_TO_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async AddOrUpdateSelectedSuggestions({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/selected/suggestions', payload).then((response) => {
                commit('ALL_ARTICLE_SUGGESTION_ACCORDING_TO_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async updatedArticleSuggestionContent({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/update/selected/suggestion', payload).then((response) => {
                commit('UPDATE_ARTICLE_SUGGESTION_ACCORDING_TO_ARTICLE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async saveArticleSuggestionNote({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/suggestion/notes', payload).then((response) => {
                commit('SAVE_ARTICLE_SUGGESTION_NOTE', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async readSuggestionNotes({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/read/suggestion/notes', payload).then((response) => {
                commit('READ_SUGGESTION_NOTE_READER',response)
                commit('READ_SUGGESTION_NOTE_WRITER',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getUpcomingCount({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/check/upcoming/user/count', payload).then((response) => {
                commit('GET_UPCOMING_COUNT', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async readSuggestionNotesForUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/article/read/suggestion/according/to/user', payload).then((response) => {
                commit('READ_SUGGESTION_NOTIFICATION',response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async createFontFamily({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/font/family', payload).then((response) => {
                commit('CREATE_FONT_FAMILY', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async createCustomTag({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/article/custom/tag', payload).then((response) => {
                commit('CREATE_CUSTOM_TAG', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async deleteCustomTag({commit}, payload) {
        return new Promise((resolve, reject) => {
             axios.delete(`article/custom/tag/${payload.article_id}/${payload.custom_tag_id}`, payload).then((response) => {
                commit('DELETE_CUSTOM_TAG', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async getAllArticle({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('/admin/articles').then((response) => {
                commit('GET_ALL_ARTICLES', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async archiveArticleByAdmin({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/admin/archive/article`,payload).then((response) => {
                commit('ARCHIVE_ARTICLE_BY_ADMIN', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    async articleMonetizeByAdmin({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/admin/article/monetize`,payload).then((response) => {
                commit('ARTICLE_MONETIZE_BY_ADMIN', response)
                resolve(response.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

}
