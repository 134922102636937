import axios from 'axios'

export default axios.create({
    // baseURL:"http://vue87623459dev.theworded.com/backend/public/api/v1",
    baseURL:"https://theworded.com/the-worded-vue.js-testing/backend/public/api/v1/",
    // baseURL:"http://127.0.0.1:8000/api/v1/",
    headers: {
        Authorization: {
            toString () {
                return `Bearer ${localStorage.getItem('auth_token')}`
            }
        }
    }
});

//     .interceptors.response.use(
//     (response) => response,
//     (error) => {
//         const { response } = error;
//         if (response && response.status === 401) {
//             localStorage.clear();
//         }
//         return Promise.reject(error);
//     },
// );
