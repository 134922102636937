<template>
    <div class="table">
        <table class="table text-center">
            <caption class="caption-position text-right mr-5 topText">
                <span>$27.5</span>/$0
            </caption>
            <thead>

            <tr>
                <th>Date</th>
                <th>Article Name</th>
                <th>Payment Type</th>
                <th>Amount</th>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td>04-06-2022</td>
                <td>Macdonald</td>
                <td>Heading Request</td>
                <td><span class="text-success">$5</span>(success)</td>
            </tr>

            <tr>
                <td>04-06-2022</td>
                <td>Macdonald</td>
                <td>Heading Request</td>
                <td><span class="text-danger">$5</span>(success)</td>
            </tr>
            <tr>
                <td>04-06-2022</td>
                <td>Macdonald</td>
                <td>Heading Request</td>
                <td><span class="text-danger">$5</span>(success)</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>


    export default {
        name: "walletTable",
    }
</script>

<style scoped>
    .caption-position {
        caption-side: top;
        font-size: 17px;
    }

    .topText span {
        font-weight: bold;
    }

</style>