<template>
  <div class="header-request-detail">
<!-- Modal -->
<div class="modal fade pl-0" :id="'requestHeadingDetail'+suggestion.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document" style="width: 96%;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title headign-request-title" id="exampleModalLabel">{{suggestion.suggestion}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <p class="heading-request-content" v-if="suggestion" v-html="bodyContent(suggestion)"></p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
  </div>
</template>

<script>
export default {
name:"HeadingRequestDetail",
    props: {
        suggestion: Object,
    },
    data(){
     return{}
    },
    methods:{
        bodyContent(suggestion){
           if(suggestion.content !== '' && suggestion.content !== null){
               return  suggestion.content
           }else{
               if(suggestion.selected_suggestion){
                   return suggestion.selected_suggestion.content;
               }
           }
        }
    },
}
</script>

<style  scoped>
.headign-request-title{
font-size: 1.35rem;
font-weight: bold;
font-family: "Merriweather";
}
.heading-request-content{
font-family: "Merriweather";
}
</style>