import Vue from 'vue'
import VueRouter from 'vue-router'
// import MainPage from '../views/mainPage'
// import About from '../views/about'
// import Nasa from '../views/nasa'
import Details from '../views/details'
import AppLayoutLinks from "../layouts/AppLayoutLinks"
import writeArticle from '../views/writeArticle'
// import Profile from '../views/profile'
import settings from '../views/settings'
import draft from '../views/drafts'
// import published from '../views/published'
// import comments from '../views/comments'
import searchUser from '../views/search'
import AllPublished from '../views/allPublished'
import AllUpcoming from '../views/allUpcoming'
import Hashtag from '../views/HashtagDetail'
import HomeBlog from '../views/homeBlog'
import SubscribedArticle from '../views/subscribedArticles'
import userProfile from "../views/userProfile";
import termsOfServices from "../views/termsOfServices";
import privacyPolicy from "../views/privacyPolicy";
import HeadingRequestDetail from "../views/headingRequestDetails";
//Admin
import AdminLogin from "../views/login.vue";
import ArticlePage from "../views/ArticlePage.vue"

import store from '../store/store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'home',
        component: AppLayoutLinks,
        children: [
            {
                path: '/djf3s6szsd9hlkrasewfnbev',
                name: 'AdminLogin',
                component: AdminLogin,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path:'/admin/dashboard',
                name:'ArticlePage',
                component: ArticlePage,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/home/:country?/:category?',
                name: 'MainPage',
                component: HomeBlog
            },
            // {
            //     path:'/custom/homepage',
            //     name: 'customHomePage',
            //     component: MainPage
            // },
            {
                path: '/custom/homepage',
                name: 'customHomePage',
                component: HomeBlog
            },
            // {
            //     path:'blog/home',
            //     name: 'blogHome',
            //     component: HomeBlog
            // },
            // {
            //     path: '/about',
            //     name: 'About',
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: About
            // },
            // {
            //     path: '/nasa',
            //     name: 'Nasa',
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: Nasa
            // },
            {
                path: '/details/:slug?',
                name: 'Details',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: Details
            },
            {
                path: '/write-article',
                name: 'writeArticle',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: writeArticle,
                meta: {
                    requiresAuth: true,
                },
            },
            // {
            //     path: '/profile',
            //     name: 'Profile',
            //     // route level code-splitting
            //     // this generates a spublishedeparate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: Profile,
            //     meta: {
            //         requiresAuth: true,
            //     },
            // },
            // {
            //     path: '/user/profile',
            //     name: 'userProfile',
            //     component: userProfile,
            //
            // },
            {
                path: '/user/profile/:slug',
                name: 'userProfile',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: userProfile,
                // meta: {
                //     requiresAuth: true,
                // },
            },
            {
                path: '/setting',
                name: 'Setting',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: settings,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/draft',
                name: 'Draft',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: draft,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/subscribed',
                name: 'Subscribed',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: SubscribedArticle,
                meta: {
                    requiresAuth: true,
                },
            },
            // {
            //     path: '/published',
            //     name: 'Published',
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: published
            // },
            // {
            //     path: '/comments',
            //     name: 'Comments',
            //     // route level code-splitting
            //     // this generates a separate chunk (about.[hash].js) for this route
            //     // which is lazy-loaded when the route is visited.
            //     component: comments
            // },
            {
                path: '/search',
                name: 'SearchUser',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: searchUser
            },
            {
                path: '/breaking',
                name: 'AllPublished',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: AllPublished
            },
            {
                path: '/upcoming',
                name: 'AllUpcoming',
                component: AllUpcoming
            },
            {
                path: '/hashtag/:slug',
                name: 'HashTag',
                component: Hashtag
            },
            {
                path: '/termsofservices',
                name: 'termsOfServices',
                component:termsOfServices
            },
            {
                path: '/privacypolicy',
                name: 'privacyPolicy',
                component:privacyPolicy
            },
            {
                path: '/upcoming/:slug?',
                name: 'UpcomingDetail',
                component: Details,
            },
            {
                path: '/heading/requests',
                name: 'HeadingRequest',
                component: HeadingRequestDetail
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },

});
// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    let user = localStorage.getItem('user');
    let is_verify = localStorage.getItem('auth_token')
    const authRequired = routeTo.matched.some((route) => route.meta.requiresAuth)
    if (authRequired) {
        if (!user || !is_verify) {
            return logout()
        }
    }

    const userObject =JSON.parse(user);

    if(user){
        if(userObject.roles.find(x=>x.id === 2)){
            if(routeTo.name.includes('ArticlePage')  || routeTo.name.includes('AdminLogin') ) {
               return  next('/')
            }
        }
        if(userObject.roles.find(x=>x.id === 1)){
            if(!userObject.is_admin_verified){
                if(routeTo.name.includes('ArticlePage')){
                    return  next('/djf3s6szsd9hlkrasewfnbev')
                }
            }else{
                if(routeTo.name.includes('AdminLogin')){
                    return  next('/admin/dashboard')
                }
            }
        }


    }
    return next();


    function logout() {
        store.dispatch("auth/routeLogout").then(() => {
            return next({name: 'MainPage'})
        });
        return next()
    }
})


export default router
