<template>
    <div>

        <div class="modal fade pr-0" id="audioModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" data-backdrop="static" data-keyboard="false">

            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="exampleModalLabel">Insert Audio</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="validateBeforeSubmit">
                        <div class="modal-body">
                            <div class="form-group">
                                <label id="files-name" class="col-form-label"></label>
                                <input  ref="fileInput" @change="previewFiles" type="file" accept="audio/mp3,audio/*;capture=microphone" />
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="px-3 py-2 text-dark headerImage">
                               Insert Audio
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery";
import Helper from "../../helper";

export default {
    name: "AudioModal",
    data(){
      return {
          audio: ""
      }
    },
    methods:{
        closeModal(){

        },
        validateBeforeSubmit(){
            if(this.audio === ''){
                return  Helper.toast(this, 'Alert', 'Please select audio file first', 'danger')
            }
            this.$emit('emitAudioPreview', this.audio)
            this.clickButtonToCloseModal('audioModal')

        },
        previewFiles(event){
            let input = event.target;
            if (input.files) {
                this.audio = input.files[0];
            }
        },
        clickButtonToCloseModal(id) {
            $("#" + id).modal('hide');
            $(".modal-backdrop").remove();
            $('body').removeClass('modal-open');
        },
    }
    
};
</script>

<style scoped>
.modal-content {
    width: 700px;
}

.headerImage {
    background-color: #f5f5f5 !important;
    border-color: #007bff;
    border-radius: 5px;
}

.headerImage:hover {
    background-color: #f5f5f5 !important;
    outline: #007bff;
    border-radius: 5px;
}

.uploadGif {
    font-family: "Raleway", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: black;
}

.uploadInput {
    border-bottom: 1px solid gray;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0.25rem;
    height: calc(2.19rem + 2px);
    padding: 0.375rem 0.75rem;
    outline: none;
}
</style>
