<template>
    <div class="upload-img">
        <div class="profile-pic-wrapper">
            <div class="pic-holder">
                <!-- uploaded pic shown here -->
                <img id="" class="pic" :src="image">
                <Input @change="uploadProfileImage" class="uploadProfileInput" type="file" name="profile_pic"
                       id="newProfilePhoto" accept="image/*" style="opacity:0;"/>
                <label for="newProfilePhoto" class="upload-file-block">
                    <div class="text-center">
                        <div class="text-uploadImage">
                            Drop your avatar here
                        </div>
                    </div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UploadImage",
        props: ['image', 'ImageType', 'imageClassType'],
        data() {
            return {}
        },
        methods: {
            uploadProfileImage(e) {
                const file = e.target.files[0];
                const fileName = URL.createObjectURL(file);
                this.$emit('updateImageFile', file);
                this.$emit('updateImage', fileName);
            }
        },
        mounted() {
        }
    }

</script>

<style scoped>

    .cover-type {
    }

    .profile-type {
    }

    .pic-holder {
        text-align: center;
        position: relative;
        width: 100%;
        height: 333px;

        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .pic-holder .pic {
        height: 100%;
        width: 100%;
        min-width: 333px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
    }

    .pic-holder .upload-file-block,
    .pic-holder .upload-loader {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(90, 92, 105, 0.7);
        color: #f8f9fc;
        font-size: 12px;
        font-weight: 600;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
    }

    .pic-holder .upload-file-block {
        cursor: pointer;
    }

    .uploadProfileInput {
        position: absolute;
    }

    .pic-holder:hover .upload-file-block,
    .uploadProfileInput:focus ~ .upload-file-block {
        opacity: 1;
    }

    .pic-holder.uploadInProgress .upload-file-block {
        display: none;
    }

    .pic-holder.uploadInProgress .upload-loader {
        opacity: 1;
    }

    /* Snackbar css */
    .snackbar {
        visibility: hidden;
        min-width: 250px;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 16px;
        position: fixed;
        z-index: 1;
        left: 50%;
        bottom: 30px;
        font-size: 14px;
        transform: translateX(-50%);
    }

    .snackbar.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    .text-uploadImage {
        color: gray;
        font-size: 12px;
    }

    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }
        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }
        to {
            bottom: 0;
            opacity: 0;
        }
    }
</style>