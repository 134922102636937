<template>
    <div class="skim-modal">
        <!-- Modal -->
        <div class="modal fade pr-0" id="skim-modal" data-backdrop="static" data-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true" role="dialog">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content skimModalData">
                    <div class="modal-header skim-heading">
                        <button @click="closeModel" type="button" class="close text-dark">
                            <span style="font-size:20px !important;" aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body pt-0 skimBody">
                        <!--profile-->
                        <userProfileDetail
                                :type="type"
                                :articleDetail="article"
                                :getAuthUser="getAuthUser"
                                :isLogin="isLogin"
                        />
                        <div class=" default-blog-heading">
                            <h1 class="first-heading">{{article.title}}</h1>
                        </div>
                        <div class="socail-icon d-flex mb-3" style="justify-content: space-between;">
                            <div class="skim-view">
                                {{article.skim_count}} Views
                            </div>
                            <div class="share-icons">
                                <a href="javascript:void(0)">
                                    <label class="mb-0 shareText font-weight-bold"
                                           style="color:grey; font-size:14px;position: relative;top:3px">Share:</label>
                                </a>
                                <span class="heading-icons">
                        <a @click="shareFacebookArticle(article)" class=" d-inline-block" target="_blank">
                            <img src="../../assets/images/facebook.png" height="17px" alt="no-image">
                        </a>
                        <a :href="shareTwitterArticle(article)" target="_blank" class=" d-inline-block">
                            <img src="../../assets/images/twitter.png" height="17px" alt="no-image">
                        </a>
                        </span>
                            </div>
                        </div>
                        <ul id="homeSkim">
                            <li style="color:black" v-for="skim in skimData" :key="skim.id">{{skim.text}}</li>
                        </ul>
                        <div class="text-center">
                            <div v-if="isLogin" class="skim-helpful">
                                <h3 class="mb-4">Is this skim helpful for you ?</h3>
                                <div class="skim-button d-flex justify-content-center" style="column-gap: 15px;">
                                    <button @click="skimRating(true)" class="btn btn-outline-dark px-4">Yes</button>
                                    <button @click="skimRating(false)" class="btn btn-outline-dark px-4">No</button>
                                </div>
                            </div>
                            <div class="col-8 col-xl-6 col-6 col-sm-6  m-auto mt-1" v-if="!isLogin">
                                <button class="btn  commentUserButton shadow-none"
                                        data-toggle="modal" data-target="#LoginModal">
                                    <span class="sign-in-text-color  text-muted">Sign in to give review </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="px-3 py-2 skim-footer justify-content-between">
                        <h5 v-if="article.total_percentage_skim !== null " class="mb-0">
                            {{article.total_percentage_skim}}% of readers found this Skim Helpful</h5>
                        <h5 v-else class="mb-0">This Skim has not been rated yet</h5>
                        <router-link :to="{ name: 'Details', params: { slug: article.slug, articleId:article.id } }"
                                     data-dismiss="modal" aria-label="Close" class="skim-detail ">Read full article
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import userProfileDetail from "../VideoOrImage/userProfileDetail"
    import $ from "jquery";

    export default {
        name: "skim",
        components: {
            userProfileDetail,
        },
        data() {
            return {
                isCount: false,
                count: 0,
                skimData: [],
            }
        },
        props: {
            article: Object,
            isSkim: Boolean,
            isLogin: {
                type: Boolean,
                default: false
            },
            type:{
              type:String,
              default:"homeBlog"
            },
            getAuthUser: {
                type: Object,
            }
        },
        computed: {},
        methods: {
            closeModel() {
                this.$emit('emitCloseModel', false)
            },
            skimRating(rating) {
                let payload = {
                    is_like: rating,
                    article_id: this.article.id
                }
                this.$store.dispatch('article/skimLikeDislike', payload).then(response => {
                        this.article.total_percentage_skim = response.data.article.total_percentage_skim;
                    }
                )
            },
            countViews() {
                if (this.count >= 0 && this.count <= 10 && this.isSkim) {
                    this.count++;
                }
                if (this.count === 10 && this.isSkim) {
                    this.isCount = true;
                }
                if (this.count === 11 && this.isCount && this.isSkim) {
                    let payload = {
                        slug: this.article.slug
                    }
                    this.$store.dispatch('article/addArticleSkimCount', payload).then(response => {
                        this.article.skim_count = response.data.skim_count;
                        this.isCount = false
                        this.$emit("emitIsSkimData", response.data);
                    })
                    this.count++;
                }
            },
            skimShow() {
                var skimDataArray = [];
                if (this.article.content !== "") {
                    var content = $('<html>').html(this.article.content).find('SKIM');
                    content.map((e) => {
                        skimDataArray.push({id: e, text: $(content[e]).text()});
                    });
                    this.skimData = [];
                    skimDataArray.map((x) => {
                        this.skimData.push({id: x.id, text: x.text})
                    })
                }
            },
            shareFacebookArticle(article) {
                let url = this.$sharePath + article.slug
                /*global FB*/
                FB.ui({
                        method: 'share_open_graph',
                        action_type: 'og.likes',
                        action_properties: JSON.stringify({
                            object: {
                                'og:url': url,
                                'og:title': article.title,
                                'og:description': article.description,
                                'og:image': article.featured_image
                            }
                        })
                    },
                    function () {
                    });

            },
            shareTwitterArticle(article) {
                let url = this.$sharePath + article.slug
                return 'https://twitter.com/intent/tweet?text=' + url
            },

            addCountTenSecond() {
                setInterval(() => {
                    this.countViews();
                }, 1000)
            }
        },
        watch: {
            isSkim() {
                if (this.isSkim === true) {
                    this.skimShow()
                    this.addCountTenSecond();
                }
                this.count = 0

            }
        }
    }
</script>

<style>
    #homeSkim {
        padding-inline: 20px;
    }

    #homeSkim li {
        font-size: 18px;
        font-family: "Merriweather";
    }

    /*.modal-dialog {*/
    /*    max-width: 50%;*/
    /*}*/

    .skimModalData {
        height: 600px;
        overflow: scroll;
    }

    .close {
        opacity: 1;
    }

    .thumbs-icon {
        color: #aaa;
    }

    .skim-footer {
        background-color: #e7e5e5;
        font-size: 19px;
    }

    .skim-view {
        font-size: 13px;
        font-family: 'Roboto';
        font-weight: 400;
        color: grey;
    }

    .skim-footer .skim-detail {
        font-size: 18px;
        font-weight: bold;
    }

    .skim-modal .modal-header {
        border-bottom: 0px !important;
        padding-top: 10px;
        padding-bottom: 0;
    }
</style>