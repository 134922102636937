<template>
  <div id="heading-request-list">
    <span class="d-md-none d-sm-block d-block d-flex justify-content-end align-items-center">
      <div>
        <a class="edit-heading-request" :style="disableIcon(suggestion)" v-if="checkEditable && suggestion.is_lock===0"
          data-toggle="collapse" :data-target="'#collapseOne'+suggestion.id" aria-expanded="true"
          :aria-controls="'collapseOne'+suggestion.id"><i class="fas fa-pencil" aria-hidden="true"></i>
        </a>
        <div v-if="suggestion.notes.length>0">
          <a class="notes-heading-request" @click="isRead(suggestion)" :style="iconColor(suggestion)" v-if="checkEditable"
            data-toggle="modal" :data-target="'#articleSuggestionNote'+suggestion.id"><i data-v-9d209fd0=""
              class="far fa-file-alt pl-1" aria-hidden="true" style="font-size: 15px;"></i>
          </a>
        </div>
      </div>
       <div v-if="this.$route.name === 'HeadingRequest'">
          <span :class="'badge-' + showStatus().class + ' badge-pill cancel-badge'">{{ showStatus().status }}</span>
        </div>
    </span>
    <div class="row d-md-block d-sm-none d-none">
      <div v-if="this.$route.name === 'HeadingRequest'" class="col-sm-11 d-flex justify-content-end mb-1 mt-2">
        <span :class="'badge-' + showStatus().class + ' badge-pill cancel-badge'">{{ showStatus().status }}</span>
      </div>
    </div>
    <div class="row m-0">
       <div :class="checkRouteForStructure">
         <div class="row  mb-1 pt-2 pb-2 m-0 heading-request">
           <div class="col-md-1 col-sm-1 pr-0 col-2" :style="imageStyleAccordingToComponent">
            <span class="d-inline-block">
              <img v-if="suggestion.user.profile_pic" :src="suggestion.user.profile_pic" width="40" height="40"
                class="rounded-circle" />
              <img v-else src="https://theworded.mashghol.com/img/no-image.9ce6cc12.png" width="40" height="40"
                class="rounded-circle"/>
            </span>
          </div>
          <div class="col-md-8 col-sm-11 pl-xl-3 pl-md-3 pl-sm-4  pl-3 line-height col-10" style="line-height: 19px">
            <router-link class="heading-request-link" v-if="this.$route.name === 'HeadingRequest'"
              :to="articleDetail(suggestion.article)">{{suggestion.article.title }}</router-link>
            <span class="mb-0 mt-0 request-name d-block">{{suggestion.user.name}}</span>

            <span v-if="suggestion.article.is_heading" style="cursor: pointer" class="mb-0 topic-suggestion-text" data-toggle="modal"
              :data-target="'#requestHeadingDetail'+suggestion.id">{{suggestion.suggestion}}</span>
            <span v-else class="mb-0 topic-suggestion-text" data-toggle="modal"
              :data-target="'#requestHeadingDetail'+suggestion.id">{{suggestion.suggestion}}</span>


              <div class="header-request-detail">
                  <!-- Modal -->
                  <div class="modal fade pl-0" :id="'requestHeadingDetail'+suggestion.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                       aria-hidden="true">
                      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document" style="width: 96%;">
                          <div class="modal-content">
                              <div class="modal-header">
                                  <h4 class="modal-title headign-request-title" id="exampleModalLabel">{{suggestion.suggestion}}</h4>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div>
                              <div class="modal-body">
                                  <p class="heading-request-content" v-if="suggestion" v-html="bodyContent(suggestion)"></p>
                              </div>
                              <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>


          </div>
          <div class="col-md-3 col-sm-12 endorsment-counter" style="text-align:end;">
            <div v-if="isLogin && getAuthUser.id!==suggestion.user.id">
              <button v-if="suggestion.is_endorsement" :disabled="isDisabled" @click="endorsement()" class="
                                          btn btn-outline-danger btn-sm
                                          cancel-pledge-cancel
                                          endorseBtn-5
                                          disable_btn">
                Un-endorse
              </button>
              <button v-else @click="endorsement()" class="btn btn-outline-success btn-sm
                                                    cancel-pledge-cancel
                                                    endorseBtn-5
                                                    disable_btn" style="font-size:12px">Endorse
              </button>
            </div>
            <div class="d-flex endorsements-main" style="column-gap:11px;justify-content:flex-end;">
              <label class="total-endorse-count pt-0 mb-0">
                <a class="font-sd" data-toggle="modal"
                  :data-target="suggestion.endorsement_count!==0 ? '#endorsementModal'+suggestion.id:''"><span class="endorsements-number-count">{{suggestion.endorsement_count}}</span> Endorsements</a>
              </label>
              <!-- <div v-if="this.$route.name === 'HeadingRequest'">
                <span :class="'badge-'+showStatus().class+' badge-pill cancel-badge'">{{showStatus().status}}</span>
              </div> -->
            </div>
            <EndorsementUserModal v-if="suggestion.endorsement_count >0" :suggestion="suggestion" />
          </div>
        </div>
      </div>
        <div class="col-md-1 pl-0" v-if="this.$route.name === 'HeadingRequest'">
          <div class="pt-2 d-md-block d-sm-none d-none">
            <a class="edit-heading-request" :style="disableIcon(suggestion)" v-if="checkEditable && suggestion.is_lock===0"
              data-toggle="collapse" :data-target="'#collapseOne'+suggestion.id" aria-expanded="true"
              :aria-controls="'collapseOne'+suggestion.id"><i class="fas fa-pencil" aria-hidden="true"></i>
            </a>
            <div v-if="suggestion.notes.length>0">
              <a class="notes-heading-request" @click="isRead(suggestion)" :style="iconColor(suggestion)" v-if="checkEditable"
                data-toggle="modal" :data-target="'#articleSuggestionNote'+suggestion.id"><i data-v-9d209fd0=""
                  class="far fa-file-alt pl-1" aria-hidden="true" style="font-size: 15px;"></i>
              </a>
            </div>
          </div>
        </div>
    </div>
   
    <div  v-if="checkEditable" class="row mt-1 mb-1 pt-2 pb-2  m-0 collapse" :id="'collapseOne'+suggestion.id">
      <div class="col-md-11 col-sm-12 col-12 heading-request">
      <div class="p-3">
        <label class="font-weight-bold">Heading Request</label><div>
       <input  maxlength="120" v-model="headingRequest.suggestion_content" class="note form-control"/>
        <label v-if="suggestion.article.is_heading" class="mb-0 mt-2 font-weight-bold">Heading Description</label>
        <div class="upcoming-summernote-content" style="background-color: white;" v-if="suggestion.article.is_heading">
            <summernote
                    :tweetEmbed="tweetEmbed"
                    :isTweet="isTweet"
                    :isTweetId="'tweetModal'+suggestion.id"
                    @emitIsTweet="emitIsTweet"
                    :config="summernoteConfig"
                    :id="'summernote'+suggestion.id"
                    @ready="readySummernote">
            </summernote>
            <div class="characters" style="flex: 0 0 20%; text-align: end;"></div>
        </div>
          <button @click="updateHeadingRequest" type="button" class="btn btn-secondary px-4 mt-2">Save</button>
      </div>
      </div>
      <div class="col-md-1"></div >
    </div>
    <TweetModal :id="'tweetModal'+suggestion.id" @emitTweetLink="emitTweetLink"/>
    <HeadingRequestDetailModal :suggestion="suggestion"/>
    <HeadingRequestNoteModal :articleSuggestion="suggestion" @emitSubmitSuggestionNote="emitSubmitSuggestionNote"/>
  </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import EndorsementUserModal from "../Modals/EndorsemenUserModal.vue";
  import HeadingRequestDetailModal from "../../components/Modals/HeadingRequestDetailModal";
  import HeadingRequestNoteModal from "../Modals/ArticleSuggestionNoteModal";
  import Summernote from "../../views/Summernote";
  import TweetModal from "../Modals/TweetModal";
  import Helper from "../../helper";
export default {
  name: "HeadingRequestList",
  data(){
    return{

      contentExist:false,
      tweetEmbed: "",
      isTweet: false,
      headingRequest:{
        suggestion_content:"",
        suggestion_description:""
      },
      summernoteConfig: {
        tabsize: 2,
        height: 500,
        fontNames: [
          'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New',
          'Helvetica Neue', 'Helvetica', 'Impact', 'Lucida Grande',
          'Tahoma', 'Times New Roman', 'Verdana', 'Microsoft YaHei'
        ],
        dialogsInBody: true,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['para', ['ul', 'ol']],
          ['insert', ['link', 'picture', 'video', 'hr']],
          ['mybutton', ['quote']],
          ['mybutton', ['tweet']],
        ],
        popover: {
          image: [
            ["image", ["resizeQuarter", "resizeHalf", "resizeHalf1", "resizeFull"]],
            ["float", ["floatLeft", "floatRight"]],
            ["remove", ["removeMedia", "imageCaption"]],
          ],
          link: [["link", ["linkDialogShow", "unlink"]]],
          air: [
            ["color", ["color"]],
            ["font", ["bold", "underline", "clear"]],
            ["insert", ["link", "picture"]],
          ],
        },
      },
    }
  },
  components: {
    EndorsementUserModal,Summernote,
    TweetModal,
    // UpdateHeadingRequestListModal,
    HeadingRequestDetailModal,
    HeadingRequestNoteModal
  },
  computed:{
    ...mapGetters({isLogin: "auth/getIsUserLogin"}),
    isDisabled(){
      return this.suggestion.article.status.id === 7
    },
    currentRoute(){
      return this.$route.name
    },
    checkEditable(){
      return this.currentRoute === 'HeadingRequest' && this.getAuthUser.id===this.suggestion.user.id && this.suggestion.article.status.id !== 7
    },
    checkRouteForStructure(){
      if(this.currentRoute === 'HeadingRequest'){
        return 'col-12 col-md-11 col-sm-12 pr-0 pl-0'
      }else{
        return 'col-12 col-md-12 col-sm-12 pr-0 pl-0'
      }
    },
    imageStyleAccordingToComponent(){
      if(this.currentRoute === 'HeadingRequest'){
        return 'text-align:center;margin-top:15px';
      }else{
        return 'text-align:center;margin-top:0px';
      }
    }
  },
  props: {
    suggestion:Object,
    getAuthUser: Object,
    headingDetail: Boolean
  },
  methods: {
    emitTweetLink(tweet) {
      this.tweetEmbed = tweet
      this.isTweet = true
    },
    emitIsTweet(val) {
      this.isTweet = val
    },
    readySummernote(editor) {
      editor.summernote("code", this.headingRequest.suggestion_description);
      editor.$on("change", (contents) => {
        this.headingRequest.suggestion_description = contents;
      });
      editor.$on("paste", (contents) => {
        this.headingRequest.suggestion_description = contents;
      });
    },
    isRead(suggestion){
        if(suggestion.is_notes_read > 0) {
          let payload = {
            suggestion_id : suggestion.id,
          }
          this.$store.dispatch('article/readSuggestionNotes',payload).then(()=>{
            this.checkNotifications()
          });

        }
    },
    checkNotifications(){
      this.$store.dispatch("article/readSuggestionNotesForUser");
    },
    emitSubmitSuggestionNote(note){
      let payload = {
        note:note,
        suggestion_id:this.suggestion.id,
        user_id:this.getAuthUser.id
      }
      // console.log('testing',note,'payload',payload);
      this.$store.dispatch('article/saveArticleSuggestionNote',payload);
    },
    checkContent(){
      if(this.suggestion.article.status.id === 7) {
        if (this.suggestion.content) {
          this.contentExist = true
        } else if (this.suggestion.selected_suggestion.content !== '' && this.suggestion.selected_suggestion.content !== null) {
          this.contentExist = true
        } else {
          this.contentExist = false
        }
      }else{
        this.contentExist = false
      }
      return this.contentExist;
    },
    disableIcon(suggestion){
      if(suggestion.article.status.id===7){
        return 'pointer-events: none';
      }
    },
    iconColor(suggestion){
      if(suggestion.is_notes_read > 0){
          return 'color:blue'
        }else{
          return 'color:black'
      }
    },
    cancelPledge() {
      let payload = {
        suggestion_id: this.suggestion.id,
        article_id: this.suggestion.article.id
      }
      this.$store.dispatch("article/cancelArticleSuggestion", payload)
    },
    endorsement() {
      let payload = {
        suggestion_id: this.suggestion.id,
      }
      this.$store.dispatch("article/headingRequestEndorsement", payload);
    },
    cancelEndorsement() {
      let payload = {
        suggestion_id: this.suggestion.id,
      }
      this.$store.dispatch("article/cancelHeadingRequestEndorsement", payload);
    },
    articleDetail(article) {
      if(article.status.id ===7){
        return {name: 'Details', params: {slug: article.slug, articleId: article.id}};
      }else{
        return {name: "UpcomingDetail", params: { slug: article.slug, articleId: article.id},};
      }
    },
    showStatus(){
      if(this.suggestion) {
        if (this.suggestion.article.is_deleted) {
          return {class:'danger',status:'Cancelled'}
        }
        if (this.suggestion.article.status.id === 6) {
          return {class:'warning',status:'Pending'}
        }
        if (this.suggestion.article.status.id === 7) {
          if (!this.suggestion.selected_suggestion) {
            return {class:'primary',status:'Unselected'}
          }
          if (this.suggestion.selected_suggestion) {
            if (!this.suggestion.selected_suggestion.status)
              return {class:'primary',status:'Unselected'}
          }
        }
      }
      return '';
    },

    updateHeadingRequest(){
      let payload = {
        suggestion: this.headingRequest.suggestion_content,
        content: this.headingRequest.suggestion_description,
        article_id: this.suggestion.article.id,
        suggestion_id: this.suggestion.id,
      }
      this.$store.dispatch("article/updateHeadingRequest", payload).then(()=>{
        return Helper.toast(this, 'Heading Request', 'Heading request updated successfully', 'success')
      });
    },
      bodyContent(suggestion){
          if(suggestion.content !== '' && suggestion.content !== null){
              return  suggestion.content
          }else{
              if(suggestion.selected_suggestion){
                  return suggestion.selected_suggestion.content;
              }
          }
      }
  },
  mounted() {
    this.checkContent();
    this.headingRequest.suggestion_content = this.suggestion.suggestion;
    this.headingRequest.suggestion_description = this.suggestion.content;
  },

}
</script>
<style>
.notes-heading-request{
 /*-- background: #d1d1d1;--*/
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
   /*-- position: absolute;
    right: -45px;--*/
    top: 35px;
    color: black;
}
  .edit-heading-request{
   /*-- background: #d1d1d1;--*/
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
   /*-- position: absolute;
    right: -45px;--*/
    color: black;
  }
.heading-request {
  background-color: #eeeeeeb3;
  border: 1px solid #ced4da73;
}
.request-name {
  font-size: 14.4px;
  font-weight: normal;
font-family: "Roboto";
}
.topic-suggestion-text {
font-family: "Merriweather";
  font-size: 0.9rem;
  font-weight: 400;
  word-break: break-word;
  cursor: pointer;
}
.endorsment-counter {
  line-height: 17px;
}
.endorsment-counter .total-endorse-count {
  display: block;
}
.endorsment-counter .total-endorse-count .font-sd {
  font-size: 12px;
  color: #212529;
  font-family: "Roboto";
  font-weight: normal;
}
.heading-request-link{
  font-size: 11px;
  text-decoration: underline !important;
  color: gray;
}
.cancel-badge{
  font-size: 12px;
}
.headign-request-title{
    font-size: 1.35rem;
    font-weight: bold;
    font-family: "Merriweather";
}
.heading-request-content{
    font-family: "Merriweather";
}
</style>