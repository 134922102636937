<template>
    <div id="post pt-5">
        <div class="row main">
            <div class="col-8  m-auto p-0">
                <div class="mb-md-0  w-100 d-flex justify-content-end">
                    <span v-if="isSaving"><i class="far fa-save mt-2 mt-md-2 mt-sm-2"> </i> saving... </span>
                    <button @click="checkIsSkim" :disabled="isDisabled" class="btn mb-3 publish-button">
                        {{isUpdate ? 'Update': 'Preview' }}
                    </button>
                </div>
                <div class="row second-row m-0 " style="clear: both">
                    <div class="col-8 col-lg-8 col-md-8 col-sm-8">
                       <form class=" mt-md-0 mt-sm-0 pt-2">
                            <div class="title-input form-group  mb-4">
                                <input v-model="title" @keyup="isUpdate === false ? submitArticleApi = true : '' "
                                       type="text" maxlength="80" placeholder="Title"
                                       class="input"/>
                            </div>
                            <div class="form-group mb-0">
                                <input v-model="description" @keyup="isUpdate === false ? submitArticleApi = true : '' " maxlength="100" 
                                    placeholder="Description" class="input" />
                                <!-- <textarea v-model="description" @keyup="isUpdate === false ? submitArticleApi = true : '' " maxlength="1000" placeholder="Description"
                                    class="note form-control textarea" style="resize: none;"></textarea> -->
                            </div>
                            <div class="col-xs-12 text-right "> (Min: {{description.length}} Max: 100) </div>
                            <br class="d-lg-block d-sm-none"/>
                            <!--                            <div class="title-input form-group mb-2">-->

                            <!--                                 <input v-model="subTitle" @keyup="submitArticleApi = true" type="text"-->
                            <!--                                       placeholder="Subtitle" class="input"/>-->
                            <!--                            </div>-->
                            <!--                            <br/>-->
                            <div class="form-group mb-3 mb-md-0 mb-sm-1" style="transition: 0.5s">
                                <!-- <input v-model="youtube" @keyup="isUpdate === false ? submitArticleApi = true : '' "
                                       type="text"
                                       placeholder="Add Youtube Video Link here"
                                       class="input"
                                       style="border-bottom: 1px solid gray"/>
                                <span class="text-danger">{{message}}</span> -->
                            </div>
                        </form>
                        <!-- audio start -->
                        <div class="main-audio cursor d-flex" style="justify-content: space-between;">
                           
                            <div class="audio pl-2 mb-3" @click="checkIfTittle('audioModal')">
                                <span class="audio-icon mr-1">
                                    <!-- <i aria-hidden="true" class="fa fa-volume-up pr-2 volume-icon"></i> -->
                                    <volumeSvg />
                                 </span>
                                <span>Upload Audio File</span>
                            </div>
                            <div class="show-audio">
                                <Audio :audio="audio" audioHeight="audio-height" />
                            </div>
                        </div>
                        <!-- audio end -->
                        <div class="pl-1" style="margin-top:55px;">
                            <button type="button" data-toggle="modal" data-target="#exampleModalLong"
                                    id="customize_btn">
                                <i class="fa-solid fa-pen-to-square"></i>
                                <a class="font-weight-bold mt-2">Customize</a>
                            </button>
                        </div>


                    </div>
                    <div class="col-4 col-lg-4 col-md-4 col-sm-4 pl-4 article-featured-image">
                        <iframe v-if="youtube" width="230px" class="video-box" height="160px"
                                :src="updateYoutube(youtube)">
                        </iframe>
                        <img v-else :src="featured_image ? featured_image : '/no-image-log.png' " width="230px"
                             height="160px" class="featuredImage"
                             id="top-img"/>
                        <div class="image-upload">
                            <a @click="checkIfTittle('headerImageModal')" href="javascript:void(0)">
                                <img src="../assets/images/no-img.png" class="small-img mt-lg-2 mt-md-2" width="30px"
                                     height="25px"/>
                                <span>Article Header Image/Video</span>
                            </a>
                            <img v-on:click="show = !show" class="small-img mt-lg-2 mt-md-2 pl-1 "
                                 src="../assets/images/youtube-logo.png" alt="Youtube link" width="0px" height="0px"
                                 style="cursor: pointer"/>
                        </div>
<!--                    <button type="button" style="font-size: 13px!important;" class="btn publish-button" data-toggle="modal" data-target="#upload-image-modal">-->
<!--                        Parallax Animation Images-->
<!--                    </button>-->
                    </div>

                    <div class=" col-sm-12 p-0 mt-2">
                        <div>
                            <summernote
                                    :tweetEmbed="tweetEmbed"
                                    :isTweet="isTweet"
                                    :isTweetId="'tweetModal'"
                                    @emitIsTweet="emitIsTweet"
                                    @emitSubmitApi="emitSubmitApi"
                                    @fileUpload="fileUpload"
                                    :config="summernoteConfig"
                                    @ready="readySummernote">
                            </summernote>
                            <div class="col-xs-12 text-right characters">
                                (Min: {{contents.length}} Max: 40000)
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <CaptionModal @emitImageCaption="emitImageCaption" :articleDetail="articleDetail"/>
        <TweetModal :id="'tweetModal'" @emitTweetLink="emitTweetLink"/>
        <CustomizationModal v-if="getArticleId()!==''" :customTags="articleDetail.custom_tags"
                            @emitCustomization="emitCustomization"
                            @emitBgColor="emitBgColor"
                            :articleId="getArticleId()"
                            :customize="customize"
                            :thumbnail="thumbnail"
                            :bgColor="bgColor"
                            :mobile_thumbnail="mobile_thumbnail"/>
        <HeaderImageModal v-if="this.getArticleId() !== ''"
                          @emitHeaderGif="emitHeaderGif"
                          @emitYoutube="emitYoutube"
                          @emitHeaderImagePreview="emitHeaderImagePreview"/>
        <PublishModal :isSubmit="isSubmit" :isUpdate="isUpdate"
                      @emitSelectWorldCategories="emitSelectWorldCategories"
                      @emitSelectWorldHashTag="emitSelectWorldHashTag"
                      @emitSelectCategories="emitSelectCategories"
                      @emitSelectHashTag="emitSelectHashTag"
                      @emitSubmitPublishModal="emitSubmitPublishModal"/>
        <CustomizationHeaderImageModal
                @emitCustomizationHeaderImage="emitCustomizationHeaderImage"/>
        <CustomizationMobileThumbnailImageModal
                @emitCustomizationMobileThumbnailImage="emitCustomizationMobileThumbnailImage"/>
        <NoteModal/>
        <AudioModal @emitAudioPreview="emitAudioPreview"/>
        <PreviewModal v-if="getArticleId()!==''" :articleDetail="cardData" />
        <FontsModal v-if="getArticleId()!==''" :articleDetail="cardData"/>
      <ImageModal />
    </div>
</template>

<script>

    import CustomizationModal from "../components/Modals/CustomizationModal";
    import CustomizationHeaderImageModal from "../components/Modals/CustomizationHeaderImageModal";
    import CustomizationMobileThumbnailImageModal from "../components/Modals/CustomizationMobileThumbnailImageModal";
    import PublishModal from "../components/Modals/PublishModal";
    import HeaderImageModal from "../components/Modals/HeaderImageModal";
    import TweetModal from "../components/Modals/TweetModal";
    import Summernote from "./Summernote";
    import CaptionModal from "../components/Modals/captionModal";
    import NoteModal from "../components/Modals/NoteModal";
    import Helper from '../helper';
    import AudioModal from "../components/Modals/AudioModal";
    import Audio from "../components/Audio/Audio";
    import PreviewModal from "../components/Modals/PreviewModal";
    import FontsModal from "../components/Modals/FontsModal";
    import ImageModal from "../components/Modals/ImageModal";
    import volumeSvg from '../components/SVG/VolumeSvg';

    import {mapGetters} from "vuex";

    import $ from "jquery";
    import helpers from "../helpers";
    export default {
        name: "articlePost",
        components: {
            CustomizationHeaderImageModal,
            CustomizationMobileThumbnailImageModal,
            CustomizationModal,
            PublishModal,
            Summernote,
            HeaderImageModal,
            TweetModal,
            CaptionModal,
            NoteModal,
            AudioModal,
            Audio,
            PreviewModal,
            FontsModal,
            volumeSvg,
            ImageModal,
        },
        props: {
            slug: String
        },
        data() {

            return {
                bgColor: '',
                scrollPosition: null,
                show: false,
                isSaving: false,
                polling: null,
                isSubmit: false,
                message: '',
                tweetEmbed: "",
                isTweet: false,
                description:"",
                summernoteConfig: {
                    tabsize: 2,
                    height: 500,
                    fontNames: [
                        'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New',
                        'Helvetica Neue', 'Helvetica', 'Impact', 'Lucida Grande',
                        'Tahoma', 'Times New Roman', 'Verdana', 'Microsoft YaHei'
                    ],
                    dialogsInBody: true,
                    toolbar: [
                        ['mybutton', ['h1', 'h2', 'plain']],
                        ['mybutton', ['f']],
                        ['h1', 'h2'],
                        ['style', ['bold', 'italic', 'underline']],
                        ['para', ['ul', 'ol']],
                        ['insert', ['link', 'picture', 'video', 'hr']],
                        ['mybutton', ['quote']],
                        ['mybutton', ['collapse']],
                        ['mybutton', ['gif']],
                        ['mybutton', ['tweet']],
                        ['mybutton', ['notes']],
                        ['mybutton', ['skim']],
                    ],
                    popover: {
                        image: [
                            ["image", ["resizeSmall","resizeQuarter","resizeMedium","resizeHalf", "resizeHalf1", "resizeFull"]],
                            ["float", ["floatLeft", "floatRight", "floatNone"]],
                            ["remove", ["removeMedia", "imageCaption"]],
                        ],
                        link: [["link", ["linkDialogShow", "unlink"]]],
                        air: [
                            ["color", ["color"]],
                            ["font", ["bold", "underline", "clear"]],
                            ["insert", ["link", "picture"]],
                        ],
                    },
                },
                contents: "",
                title: "",
                // subTitle: "",
                youtube: "",
                customize: {
                    layout: "default",
                    customizeTitle: "default",
                },
                upcoming_content: "",
                audio: "",
                globe_categories: [],
                globe_hashtags: [],
                status_id: 5,
                type: "",
                article_id: "",
                featured_img: "",
                monetize: "",
                category: "",
                subcategory: "",
                country_categories: [],
                country_hashtags: [],
                country_id: "",
                customize_article: "",
                gif_img: "",
                video_url: "",
                featured_gif_img: "",
                featured_image: "",
                mobile_thumbnail: "",
                thumbnail: "",
                isUpdate: false,
                submitArticleApi: false,
                isSkim: false,
                errorMessage: "",
                iframeList: [
                    'https://platform.twitter.com/embed',
                    'https://www.youtube.com/embed',
                    'https://giphy.com/embed',
                    'http://imgur.com',
                    'https://open.spotify.com/embed',
                    'https://soundcloud.com',
                    'https://www.instagram.com',
                    'https://www.google.com/maps/embed',
                    'https://math.embed.fun/embed'
                ],
            };
        },
        computed: {
            ...mapGetters({
                articleDetail: "article/getArticleDetails",
                cardData: "article/getCardData",
                selectedFlag: "country/selectedFlag",
                defaultCountry: "country/defaultCountry",
            }),
            isDisabled() {
                return this.checkKeys()
            },


        },
        // created() {

        // },
        methods: {
            updateYoutube(youtube) {
                let youtubeVideoId = this.getYoutubeVideoId(youtube);
                if (!youtubeVideoId) {
                    this.message = 'Please enter the valid youtube video url';
                    return;
                }
                return 'https://www.youtube.com/embed/' + youtubeVideoId;
            },
            getYoutubeVideoId(youtube) {
                var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                if (youtube.match(p)) {
                    return youtube.match(p)[1];
                }
                return false
            },
            emitYoutube(youtube){
                this.youtube = youtube;
            },
            updateScroll() {
                this.scrollPosition = window.scrollY
                if (this.scrollPosition > 400) {
                    // $(".note-toolbar").addClass('classA')
                } else if (this.scrollPosition < 400) {
                    $(".note-toolbar").removeClass('classA')
                }
            },
            readySummernote(editor) {
                editor.summernote("code", this.contents);
                editor.$on("change", (contents) => {
                    this.isUpdate === false ? this.submitArticleApi = true : ''
                    this.contents = contents;
                });
            },
            emitCustomization(customize) {
                this.customize.layout = customize.layout
                this.customize.customizeTitle = customize.customizeTitle
                this.isUpdate === false ? this.submitArticleApi = true : ''
            },
            emitSelectWorldCategories(selectWorldCategories) {
                this.globe_categories = [];
                let selectedCategories = [];
                selectWorldCategories.map(x => {
                    selectedCategories.push({id: x.code, name: x.name})
                })
                this.globe_categories = selectedCategories
                this.submitArticleApi = true;
            },
            emitSelectWorldHashTag(selectWorldHashTag) {
                this.globe_hashtags = [];
                selectWorldHashTag.map(x => {
                    this.globe_hashtags.push({id: x.code, name: x.name})
                })
                this.submitArticleApi = true;
            },
            emitSelectCategories(selectCategories) {
                this.country_categories = [];
                selectCategories.map(x => {
                    this.country_categories.push({id: x.code, name: x.name})
                })
                this.submitArticleApi = true;
            },
            emitSelectHashTag(selectHashTag) {
                this.country_hashtags = [];
                selectHashTag.map(x => {
                    this.country_hashtags.push({id: x.code, name: x.name})
                })
                this.submitArticleApi = true;
            },
            emitSubmitPublishModal(isSubmit) {
                if (isSubmit) {
                    this.isSubmit = true;
                    this.status_id = 7
                    this.submitArticleApi = true;
                }
            },
            emitHeaderImagePreview(file) {
                this.featured_image = file
                this.gif_img = '';
                this.youtube = '';
                this.headerFileUpload();
            },
            // emitHeaderImage(file) {
            //     this.featured_image = file
            //     this.youtube = ""
            //     this.gif_img = gif
            //
            // },
            emitCustomizationHeaderImage(file) {
                this.headerDesktopOrMobileFileUpload(file, null)
            },
            emitCustomizationMobileThumbnailImage(file) {
                this.headerDesktopOrMobileFileUpload(null, file)
            },
            emitHeaderGif(gif) {
                if (gif !== '') {
                    this.featured_image = ''
                    this.youtube = ""
                    this.gif_img = gif
                    this.headerFileUpload()
                }
            },
            // headerImage(image) {
            //     return image.length !== 0 ? image : '/no-image-log.png';
            // },
            emitTweetLink(tweet) {
                this.tweetEmbed = tweet
                this.isTweet = true
            },
            emitIsTweet(val) {
                this.isTweet = val
                this.submitArticleApi = true;
            },
            emitSubmitApi(){
                this.submitArticleApi = true;
            },
            fileUpload(val) {
                this.submitArticleApi = val;
            },
            checkKeys() {
                // return this.title === "";
            },
            checkSkim(contents) {
                if (contents.length === 0) return
                let position = contents.search('&lt;SKIM&gt;');
                let position2 = contents.search('&lt;/SKIM&gt;');
                let total = position2 - position;
                this.isSkim = total > 12
            },
            getArticleId() {
                if (this.articleDetail) {
                    if (this.articleDetail.id !== 'undefined') {
                        return this.articleDetail.id
                    }
                    return ''
                }
                return ''
            },
            headerFileUpload() {
                let formData = new FormData();
                this.isSaving = true;
                formData.append('article_id', this.getArticleId())
                formData.append('featured_image', this.featured_image)
                formData.append('gif_img', this.gif_img)
                this.$store.dispatch('article/uploadFeatureImageOrGif', formData)
            },
            headerDesktopOrMobileFileUpload(thumbnail, mobile_thumbnail) {
                let formData = new FormData();
                this.isSaving = true;
                formData.append('article_id', this.getArticleId())
                if (thumbnail) {
                    formData.append('thumbnail', thumbnail)
                }
                if (mobile_thumbnail) {
                    formData.append('mobile_thumbnail', mobile_thumbnail)
                }
                this.$store.dispatch('article/uploadHeaderDesktopOrMobileImageOrGif', formData).then(response => {
                    this.isSaving = false;
                    this.thumbnail = response.data.thumbnail
                    this.mobile_thumbnail = response.data.mobile_thumbnail
                })
            },
            emitAudioPreview(audioFile) {
                let formData = new FormData();
                this.isSaving = true;
                formData.append('article_id', this.getArticleId())
                if (audioFile) {
                    formData.append('audio', audioFile)
                }
                this.$store.dispatch('article/uploadAudioFile', formData).then(response => {
                    this.isSaving = false;
                    this.audio = response.data.audio;
                    helpers.successMessages(this, response);
                }).catch(error => {
                    this.isSaving = false;
                    helpers.errorMessage(this, error);
                })

            },
            addArticle() {
                this.isSaving = true;
                let formData = new FormData();
                formData.append('title', this.title)
                // formData.append('sub_title', this.subTitle)

                formData.append('layout', this.customize.layout)
                formData.append('customize_title', this.customize.customizeTitle)
                formData.append('content', this.contents)
                formData.append('description', this.description)
                formData.append('upcoming_content', this.upcoming_content)
                this.globe_categories.forEach((x, i) => {
                    formData.append(`globe_categories[${i}][id]`, x.id)
                    formData.append(`globe_categories[${i}][name]`, x.name)
                })
                this.globe_hashtags.forEach((x, i) => {
                    formData.append(`globe_hashtags[${i}][id]`, x.id)
                    formData.append(`globe_hashtags[${i}][name]`, x.name)
                })
                this.country_categories.forEach((x, i) => {
                    formData.append(`country_categories[${i}][id]`, x.id)
                    formData.append(`country_categories[${i}][name]`, x.name)
                })
                this.country_hashtags.forEach((x, i) => {
                    formData.append(`country_hashtags[${i}][id]`, x.id)
                    formData.append(`country_hashtags[${i}][name]`, x.name)
                })
                formData.append('status_id', this.status_id)
                formData.append('type', this.type)
                let cardData = localStorage.getItem('cardData');
                if (cardData) {
                    let data = JSON.parse(cardData);
                    formData.append('article_id', data.id)
                } else {
                    formData.append('article_id', this.getArticleId())
                }
                formData.append('is_monetize', this.monetize)
                formData.append('category', this.category)
                formData.append('subcategory', this.subcategory)
                formData.append('country_id', this.defaultCountry.id)
                formData.append('customize_article', this.customize_article)
                formData.append('video_url', this.youtube)
                formData.append('bg_color', this.bgColor !=='' ? this.bgColor.replace(/[\r\n]/gm, '') : this.bgColor )
                if (!this.checkKeys() && this.submitArticleApi) {

                    this.$store.dispatch('article/createArticle', formData).then(response => {
                        this.submitArticleApi = false
                        this.updateIsSaving();
                        if (this.isSubmit && response.data.status.id === 7) {
                            this.closeModal('publishModal')
                            localStorage.removeItem('cardData');
                            localStorage.removeItem('isEdit');
                            localStorage.removeItem('isPublish');
                            this.$router.push({name: 'Details', params: {slug: response.data.slug}})
                        }
                    }).catch(error => {
                        console.log('error=>', error);
                    })
                } else {
                    this.updateIsSaving();
                    this.submitArticleApi = false
                }

            },
            emitImageCaption(payload) {
                if (this.getArticleId()) {
                    Object.assign(payload, {article_id: this.getArticleId()})
                    this.closeModal('captionModal');
                    this.$store.dispatch('article/imageCaption', payload)
                }
            },
            emitBgColor(colorCode){
              this.bgColor = colorCode;
            },
            closeModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            updateIsSaving() {
                let vm = this;
                setInterval(function () {
                    vm.isSaving = false;
                }, 1000)
            },
            editArticle() {
                if (this.cardData.id !== "") {
                    // if (localStorage.getItem('isEdit')) {
                    //     this.isUpdate = true
                    // }
                    if (localStorage.getItem('isPublish')) {
                        this.isUpdate = JSON.parse(localStorage.getItem('isPublish'))
                    }
                    let data = this.cardData;
                    this.$store.dispatch('article/editArticleDetail', data)
                    this.contents = data.content;
                    this.title = data.title;
                    // this.subTitle = data.sub_title;
                    this.youtube = data.video_url;
                    this.customize.layout = data.layout;
                    this.customize.customizeTitle = data.customize_title;
                    this.description = data.description;
                    this.upcoming_content = data.upcoming_content == null ? data.upcoming_content : "";
                    this.status_id = data.status.id;
                    this.monetize = data.is_monetize;
                    this.featured_image = data.featured_image
                    this.thumbnail = data.thumbnail
                    this.mobile_thumbnail = data.mobile_thumbnail
                    this.audio = data.audio
                    this.bgColor = data.bg_color !=='' ? data.bg_color.replace(/[\r\n]/gm, '') : ''
                }
                this.skimReplaceTag();
            },
            checkEmbed(key,embed){
               return key.includes(embed)
            },
            checkIsSkim() {
                if (this.title === "") {
                    return Helper.toast(this, 'Alert', 'Please enter the title first', 'danger')
                }
                if (this.contents.length < 1000) {
                    return Helper.toast(this, 'Alert', 'Minimum characters are 1000 for article. Please check it', 'danger')
                }
                var collapse = $('.collapse');
                if ((this.contents.length - $('.collapse p').text().length  - $('.collapse h1').text().length - $('.collapse h2').text().length - $('.collapse h5').text().length )  > 2500) {
                    this.checkSkim(this.contents);
                    if (!this.isSkim) {
                        return Helper.toast(this, 'Alert', 'Please enter the Skim', 'danger')
                    }
                }else{
                    if (this.description.length===0) {
                        return Helper.toast(this, 'Alert', 'Please enter the description first', 'danger')
                    }
                }

                collapse.removeClass('show');
                $('#preview').modal('show');

                // this.contents.replaceAll("<", '&lt;')
                // this.contents.replaceAll(">", '&gt;')
                //
                // if(this.checkEmbed('iframe') || this.checkEmbed('blockquote')){
                //     var isBool = false;
                //     $('iframe').each(function() {
                //         console.log($(this).attr('src'));
                //         isBool = this.iframeList.includes($(this).attr('src'));
                //     });
                //     if (isBool) {
                //         console.log(true)
                //     } else {
                //         return Helper.toast(this, 'Alert', 'Please Enter valid Embed Code', 'danger')
                //     }
                // }
                // $('#publishModal').modal('show');
            },
            skimReplaceTag() {
                let msg = this.contents;
                msg = msg.replaceAll("<SKIM>", '&lt;SKIM&gt;')
                msg = msg.replaceAll("</SKIM>", '&lt;/SKIM&gt;')
                msg = msg.replaceAll("<skim>", '&lt;SKIM&gt;')
                msg = msg.replaceAll("</skim>", '&lt;/SKIM&gt;')
                msg = msg.replaceAll("<COLLAPSE>", '&lt;COLLAPSE&gt;')
                msg = msg.replaceAll("</COLLAPSE>", '&lt;/COLLAPSE&gt;')
                msg = msg.replaceAll("<collapse>", '&lt;COLLAPSE&gt;')
                msg = msg.replaceAll("</collapse>", '&lt;/COLLAPSE&gt;')
                this.contents = msg;
            },
            checkIfTittle(modalId) {
                if (this.title === "") {
                    return Helper.toast(this, 'Alert', 'Please enter the title first', 'danger')
                }
                $("#" + modalId).modal('show');
                // $("#headerImageModal").modal('show');
            },
        },
        watch: {
            submitArticleApi() {
                this.addArticle();
            },
            audio(val) {
                if (!val) return
                this.audio = val;
            },
            youtube(val) {
                if (!val) {
                    return this.message = ""
                }
                if (this.message !== "") {
                    return this.youtube = ""
                }

            },
        },

        mounted() {
            this.$store.dispatch('article/emptyArticleDetail')
            window.addEventListener('scroll', this.updateScroll);
            this.editArticle();
        },
    };
</script>

<style scoped>
.main {
        margin-top: 53px;

    }

    .publish-button {
        background: none;
        border: 2px solid black;
        border-bottom-width: 4px;
        font: inherit;
        height: 40px;
        letter-spacing: inherit;
        padding: 0 27px;
        border-radius: 0;
        text-transform: inherit;
        -webkit-transition: color 1s;
        transition: color 1s;
        text-align: center;
        font-size: 14px;
    }

    .publish-button:hover {
        color: #006692;
        -webkit-animation: zigzag 1s linear infinite;
        animation: zigzag 1s linear infinite;
        background: linear-gradient(135deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(225deg, #dee2e67d 0.25em, transparent 0.25em) -0.5em 0,
        linear-gradient(315deg, #dee2e67d 0.25em, transparent 0.25em) 0 0,
        linear-gradient(45deg, #dee2e67d 0.25em, transparent 0.25em) 0 0;
        background-size: 0.75em 0.75em;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        cursor: pointer;
    }

    #customize_btn {
        background-color: transparent;
        border: none;
    }

    .input {
        background: transparent;
        outline: none;
        border: none;
        border-radius: 5px;
        border-bottom: 1px solid #dee2e6;
        width: 100%;
        height: 37px;
        padding: 0.375px 12px;
    }

    .input::placeholder {
        color: #6d757d !important;
        font-size: 14px;
    }

    .customize i,
    a {
        font-size: 13px;
        color: #6d757d !important;
    }

    .button {
        color: green;
        font-size: 14px;
    }

    blockquote {
        border-left: 4px solid #ccc;
        padding-left: 10px;
        font-size: 21px;
    }

    .characters {
        margin-bottom: 160px;
        font-size: 14px;
    }

    .image-upload a {
        display: flex;
        align-items: flex-end;
        column-gap: 5px;
    }

.volume-icon{
    color:grey;
}
.main-audio{
    justify-content: space-between;
}
.show-audio{
    width:69%;
}
.show-audio .audio-height{
     width:100%;
}
</style>
