<template>
    <div class="main_modal">
        <div class="customize_modal">
            <!-- Modal -->
            <div class="modal fade pr-0" data-backdrop="static" data-keyboard="false" id="exampleModalLong"
                 tabindex="-1"
                 role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg customize-modal modal-dialog-scrollable" role="document">
                    <div class="modal-content" style="max-width:750px">

                        <!---header ----->
                        <div class="modal-header pb-0">
                            <h4 class="modal-title top-heading d-inline-block">Customization</h4>
                            <button type="button" class="close d-inline-block" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <!---modal-body---->
                        <div class="modal-body customize-modal-body pb-0">
                            <h6 class="modal_heading">Article Header Image</h6>
                            <div class="d-flex  model_card">
                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="customize.layout" type="radio" value="default" id="default"
                                               class=""
                                               checked>
                                        <label for="default" class="ml-2 radio-text">Default</label>
                                    </div>
                                    <img src="../../assets/images/Default.png" alt="default">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="customize.layout" type="radio" value="hide" id="hide">
                                        <label for="hide" class="ml-2 radio-text">Hide</label>
                                    </div>
                                    <img src="../../assets/images/Hide.png" alt="Hide">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="customize.layout" type="radio" value="article_width"
                                               id="article_width">
                                        <label for="article_width" class="ml-2 radio-text">Width</label>
                                    </div>
                                    <img src="../../assets/images/Article_Width.png" alt="Article_Width">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="customize.layout" type="radio" value="banner" id="banner">
                                        <label for="banner" class="ml-2 radio-text">Banner</label>
                                    </div>
                                    <img src="../../assets/images/Banner.png" alt="Banner">
                                </div>

                                <div class="details">
                                    <div class="radio_button">
                                        <input v-model="customize.layout" type="radio" value="oversized" id="oversize">
                                        <label for="oversize" class="ml-2 radio-text">Oversize</label>
                                    </div>
                                    <img src="../../assets/images/Oversized.png" alt="Oversized">
                                </div>
                            </div>

                            <!-- <div class="subheading">
                                <h6 class="modal_heading mt-lg-4 mt-md-4  mt-sm-3">Subtitle</h6>
                                <div class="d-flex  model_card">
                                    <div class="radio_button">
                                        <input v-model="customize.customizeTitle" type="radio" value="default"
                                               id="subtitleDefault" checked>
                                        <label for="subtitleDefault" class="ml-2 radio-text">Default</label>
                                    </div>

                                    <div class="radio_button">
                                        <input v-model="customize.customizeTitle" type="radio" value="hide"
                                               id="subtitleHide">
                                        <label for="subtitleHide" class="ml-2 radio-text">Hide</label>
                                    </div>
                                </div>
                            </div> -->

                            <div class="bottom-heading d-flex">
                                <div class="thumbnail-img">
                                    <label class="modal_heading mt-lg-4 mt-md-4  mt-sm-3 d-block">Article Card Thumbnail
                                        Image</label>
                                    <img @click="openModal" data-toggle="modal"
                                         data-target="#customizationHeaderImageModal" data-dismiss="modal"
                                         aria-label="Close" alt="noImage" href="javascript:void(0)"
                                         :src="thumbnail? thumbnail : '/no-image-log.png'"
                                         width="50" height="40" class="cursor"/>
                                </div>


                                <!--                                <label class="modal_heading mt-lg-4 mt-md-4 ml-3 mt-sm-3">Mobile Thumbnail Image</label>-->
                                <!--                                <img @click="openMobileModal" data-toggle="modal"-->
                                <!--                                     alt="noImage"-->
                                <!--                                     data-target="#customizationMobileThumbnailImageModal" data-dismiss="modal"-->
                                <!--                                     aria-label="Close"-->
                                <!--                                     href="javascript:void(0)"-->
                                <!--                                     :src="mobile_thumbnail? mobile_thumbnail : '/no-image-log.png'" width="50"-->
                                <!--                                     height="40" class="cursor ml-3"/>-->
                                <!-- <p class="para mt-3 mb-0">This image will replace the Header Image on Article Page. Use
                                    this
                                    option if using two different size images for the Card Thumbnail and the Article
                                    Header.</p> -->
                            </div>

                            <div>
                                <label class="modal_heading mt-lg-4 mt-md-4  mt-sm-3 d-block">Gradient CSS</label>
                                <div class="form-group article-customize">
                                    <textarea v-model="bgColor" maxlength="1000" class="form-control customize-textarea"
                                              placeholder="Paste your color code"></textarea>
                                </div>
                                <p>
                                    <b>Tip:</b> Use light color gradients, using a darker color will hide some vital
                                    data/icons and hinder reader's experience. Use the following websites to generate
                                    gradient CSS: <br/><a href="https://www.joshwcomeau.com/gradient-generator/"
                                                          target="_blank">joshwcomeau.com</a>,
                                    <a href="https://cssgradient.io/"
                                       target="_blank">cssgradient.io</a>,
                                    <a href="https://mycolor.space/gradient3"
                                       target="_blank">mycolor.space</a>

                                </p>
                            </div>

                            <div>
                                <div class="custom-tag">
                                    <!-- <h4 class="modal-title mb-2 text-dark">Custom Tags</h4> -->
                                    <label class="modal_heading mt-lg-4 mt-md-4  mt-sm-3 d-block">Custom Tags</label>
                                </div>

                                <div class="d-flex" style="column-gap: 10px;">
                                    <div class="tag">
                                        <div class="form-group">
                                            <input v-model="name" maxlength="14" type="text" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="color-picker">
                                        <div class="form-group">
                                            <input v-model="color" type="color" class="form-control"
                                                   style="width:76px;height:40px;"/>
                                        </div>
                                    </div>
                                    <div class="emoji">
                                        <div class="form-group">
                                            <input @change="uploadEmoji" id="emojiFile" type="file" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="tag-btn">
                                        <!-- <button @click="createCustomTag" type="button" class="text-dark">
                                            {{isUpdate ? 'Update' :'Create'}}
                                        </button> -->
                                        <button @click="createCustomTag" type="button" class="btn  btn-outline-dark" id="button">{{isUpdate ? 'Update' :'Create'}}
                                        </button>
                                    </div>
                                </div>
                                <div class="show-tags">
                                    <span v-for="customTag in customTags" :key="Math.random()+'_'+customTag.id"
                                          class="mr-2">
                                        <label v-if="!customTag.deleted_at" class="top-text cursor mb-0 large-badge">
                                            <span @click="updateCustomTag(customTag)" class="badge-pill custom-tag pt-1 pb-2"
                                                  :style="'background-color: '+customTag.color">
                                                <img alt="tag-img" class="mr-1" v-if="customTag.emoji" :src="customTag.emoji" height="15" width="15" style="border:1px solid white; object-fit: cover"/>
                                                {{customTag.name}}
                                                <i @click.once="deleteCustomTag(customTag)" class="fa fa-times ml-1" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-----footer----->

                        <div class="modal-footer pt-0">
                            <button @click="updateCustomization" type="button" data-dismiss="modal" aria-label="Close"
                                    class="text-dark" id="button">Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>


    import helpers from "../../helpers";
    import Helper from "../../helper";
    import $ from 'jquery';

    export default {
        name: 'customizationModal',
        props: {
            customize: Object,
            thumbnail: String,
            mobile_thumbnail: String,
            bgColor: String,
            articleId: Number,
            customTags: Array
        },
        data() {
            return {
                isCustomization: false,
                name: "",
                color: "#000",
                emoji: "",
                customTagId: '',
                isUpdate: false
            }
        },
        methods: {
            updateCustomization() {
                this.$emit('emitCustomization', this.customize)
                this.$emit('emitBgColor', this.bgColor)
            },
            openModal() {
                this.isCustomization = true
            },
            openMobileModal() {
                this.isCustomization = true
            },
            uploadEmoji(e) {
                var fileLimit = 50;
                var fileSize = e.target.files[0].size;
                var fileSizeInKB = (fileSize / 1024);
                if (fileSizeInKB > fileLimit) {
                    $('#emojiFile').val('');
                    Helper.toast(this, 'Alert', 'The image size is too big. You can upload an Image maximum size limit of 50KB!!', 'danger')
                    return
                }else{
                    this.emoji = e.target.files[0]
                }
            },
            createCustomTag() {
                let formData = new FormData();
                formData.append("custom_tag_id", this.customTagId);
                formData.append("article_id", this.articleId);
                formData.append("is_update", this.isUpdate);
                formData.append("name", this.name);
                formData.append("color", this.color);
                formData.append("emoji", this.emoji);
                // let payload = {
                //     custom_tag_id: this.customTagId,
                //     article_id: this.articleId,
                //     is_update: this.isUpdate,
                //     name: this.name,
                //     color: this.color,
                //     emoji: this.emoji
                // }
                this.$store.dispatch('article/createCustomTag', formData).then(response => {
                    this.name = "";
                    this.color = "#000";
                    this.customTagId = "";
                    this.isUpdate = false
                    helpers.successMessages(this, response);
                }).catch((error) => {
                    helpers.errorMessage(this, error);
                });

            },
            deleteCustomTag(customTag) {
                let payload = {
                    custom_tag_id: customTag.id,
                    article_id: customTag.article_id,
                }
                this.$store.dispatch('article/deleteCustomTag', payload).then(response => {
                    this.name = "";
                    this.color = "#000";
                    this.customTagId = "";
                    this.isUpdate = false
                    helpers.successMessages(this, response);
                }).catch((error) => {
                    helpers.errorMessage(this, error);
                });

            },
            updateCustomTag(customTag){
                this.name = customTag.name;
                this.color = customTag.color;
                this.customTagId = customTag.id;
                this.isUpdate = true;
            },
        },
        watch: {
            customize: {
                deep: true,
                handler() {
                    this.updateCustomization()
                }
            }
        }
    }
</script>
<style scoped>
    .bottom-heading img {
        max-width: 230px;
        width: 100%;
        height: 130px;
    }

    .bottom-heading {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .bottom-heading .thumbnail-img {
        flex: 0 0 40%;
    }

    .bottom-heading .custom {
        flex: 0 0 60%;
    }

    .emoji {
        width: 235px;
    }

    .article-customize .customize-textarea {
        height: 100px !important;
        resize: none;
    }

    .large-badge .custom-tag {
        color: #fff;
        padding: 3px 5px;
    }
</style>



   