<template>
    <div class="preview">
        <!-- Modal -->
        <div class="modal fade" id="preview" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div  class="modal-dialog preview-modal w-100  modal-dialog-scrollable" role="document">
                <div :style="showPreviewBgColor()" class="modal-content">
                    <div class="modal-header" style="align-items: center;">
                        <!-- <h2 class="modal-title" id="exampleModalLabel">Preview</h2> -->
                        <div class="top-bottom"
                             style=" display: flex; justify-content:flex-end;flex:0 0 95%; column-gap: 10px;">
                            <button @click="collapseShow" type="button" class="btn btn-outline-secondary publish-button"
                                    data-dismiss="modal">Keep Editing
                            </button>
                            <button type="button" class="btn btn-outline-secondary publish-button mr-3"
                                    data-toggle="modal" data-target="#publishModal" data-dismiss="modal">Select
                                Categories &
                                Publish
                            </button>
                        </div>
                        <button @click="collapseShow" type="button" class="close preview-close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <Default v-if="articleDetail.layout === 'default'" :upcomingArticle="getCurrentUrl()"
                                 :articleDetail="articleDetail"/>
                        <Hide v-if="articleDetail.layout === 'hide'" :upcomingArticle="getCurrentUrl()"
                              :articleDetail="articleDetail"/>
                        <ArticleWidth v-if="articleDetail.layout === 'article_width'" :upcomingArticle="getCurrentUrl()"
                                      :articleDetail="articleDetail"/>
                        <Banner v-if="articleDetail.layout === 'banner'" :upcomingArticle="getCurrentUrl()"
                                :articleDetail="articleDetail"/>
                        <OverSized v-if="articleDetail.layout === 'oversized'" :upcomingArticle="getCurrentUrl()"
                                   :articleDetail="articleDetail"/>
                        <div class="row skim-row">
                            <div :class="layoutDetailPageClass()">
                                <div class="">
                                    <div class="skim-share-note mt-lg-0 mt-md-3 mt-sm-0">
                                        <!-- audio start -->
                                        <div v-if="articleDetail.audio"
                                             class="main-audio order-md-0 order-sm-0 order-1 cursor d-flex large-audio w-100"
                                             style="column-gap: 10px;">
                                            <div class="show-audio" v-show="isToggle">
                                                <audio controls controlsList="nodownload nofullscreen"
                                                       class="audio-file">
                                                    <source :src="articleDetail.audio ? articleDetail.audio :''"
                                                            type="audio/ogg">
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                        </div>

                                        <!-- audio end -->
                                        <div class="d-flex order-md-1 order-sm-1 order-0"
                                             style="column-gap: 10px;align-item:center;">
                                            <span @click="isToggle =!isToggle">
                                                <volumeSvg  class="cursor" v-if="articleDetail.audio"/>
                                            </span>
                                            <div v-if="checkIfExistSkim()"  id="skimToogle" class="d-flex">
                                                <b class="font-weight-bold pr-2">Skim</b>
                                                <b-form-checkbox @change="highlightedText" name="check-button" switch></b-form-checkbox>
                                            </div>
                                            <!-- <i v-if="articleDetail.audio" @click="isToggle =!isToggle" style="font-size: 17px;color:grey;"
                                               class="fa fa-volume-up pt-1 pl-1 cursor"></i> -->
<!--                                            <img v-if="articleDetail.audio" @click="isToggle =!isToggle"-->
<!--                                                 src="../../assets/images/volume.png" class="mt-1" alt="volume" width="18"-->
<!--                                                 height="18"/>-->

                                            <div v-if="articleDetail.notes.length>0" class="pr-1">
                                                <a data-toggle="modal" data-target="#showNotes-modal"
                                                   class="d-flex mt-1"
                                                   style="color:grey;align-items: baseline;">
                                                    <i style="font-size: 17px" class="far fa-file-alt pl-1"></i>
                                                </a>
                                            </div>
                                            <div class="share-icons">
                                                <a href="javascript:void(0)">
                                                    <label class="mb-0 shareText font-weight-bold"
                                                           style="color:grey; font-size: 14px;word-break: normal;">Share:</label>
                                                </a>
                                                <span class="heading-icons">
                                                    <a @click="shareFacebookArticle(articleDetail)" class=" d-inline-block  " target="_blank">
                                                        <img src="../../assets/images/facebook.png" height="17px" alt="no-image">
                                                    </a>
                                                    <a :href="shareTwitterArticle(articleDetail)" target="_blank"
                                                       class=" d-inline-block">
                                                        <img src="../../assets/images/twitter.png" height="17px" alt="no-image">
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div id="skimContent" class="blog-detail mt-2 mb-2"
                                             style="background-color: #ebebea;">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="article">
                                    <div class="article-content"
                                         v-html="updateNoteArticle">
                                    </div>
                                    <div class="divider">
                                        <hr class="line mx-auto" style="margin-top: 65px"/>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-12">
                                            <div v-for="article_suggestion in articleDetail.article_suggestions"
                                                 :key="article_suggestion.id+'_'+Math.random()" class="col-sm-12">
                                                <SelectedSuggestionDetails
                                                        v-if="checkSelectedSuggestion(article_suggestion)"
                                                        :articleSuggestion="article_suggestion"/>
                                                <hr v-if="checkSelectedSuggestion(article_suggestion)"
                                                    class="line mx-auto"
                                                    style="margin-top: 65px"/>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div class="col-12 col-lg-12 col-md-8 pt-4 mx-auto">
                                        <div class="top-nav">
                                            <nav>
                                                <ul class="nav">
                                                    <li class="nav-item ml-2"
                                                        v-for="articleCategory in uniqueCategories"
                                                        :key="articleCategory.id+'_'+Math.random()">
                                                        <router-link to="/home" class="nav-link"
                                                                     @click.native="activeClass(articleCategory.category)">
                                                            {{articleCategory.category.category_name}}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>

                                        <br/>
                                        <div class="space">
                                            <ul class="nav">
                                                <li class="nav-item" v-for="articleCategory in uniqueHashtags"
                                                    :key="articleCategory.id+'_'+Math.random()">
                                                    <router-link :to="'/hashtag/'+articleCategory.hashtag.slug"
                                                                 @click.native="activeClass(articleCategory.category)"
                                                                 class="nav-link space-nav-link">
                                                        {{articleCategory.hashtag.hash_tag_name}}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ImageCaptionModal/>
                    <ShowNotesModal :notes="articleDetail.notes"/>
                    <UniqueNoteModal/>
                    <div class="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OverSized from "../customizeLayout/Oversized";
    import Default from "../customizeLayout/Default";
    import Banner from "../customizeLayout/Banner";
    import ArticleWidth from "../customizeLayout/ArticleWidth";
    import Hide from "../customizeLayout/Hide";
    import $ from "jquery";
    import ImageCaptionModal from "../Modals/ImageCaptionModal";
    import UniqueNoteModal from "../Modals/UniqueNoteModal";
    import ShowNotesModal from "../Modals/ShowNotesModal"
    import SelectedSuggestionDetails from "../../components/ArticleDetail/SelectedSuggestionDetail";
    import volumeSvg from '../../components/SVG/VolumeSvg';
    import {mapGetters} from "vuex";

    export default {
        name: "PreviewModal",
        components: {
            OverSized,
            Default,
            ArticleWidth,
            Banner,
            Hide,
            ShowNotesModal,
            UniqueNoteModal,
            ImageCaptionModal,
            volumeSvg,
            SelectedSuggestionDetails
        },
        props: {
            // articleDetails: Object
        },
        data() {
            return {
                isToggle: false
            }
        },
        computed: {
            ...mapGetters({
                articleDetail: 'article/getArticleDetails',
            }),
            uniqueCategories: function () {
                var unique = [];
                var distinct = [];
                if (this.articleDetail.article_category) {
                    for (let i = 0; i < this.articleDetail.article_category.length; i++) {
                        if (this.articleDetail.article_category[i].category !== undefined) {
                            if (this.articleDetail.article_category[i].category.category_name_slug !== undefined) {
                                if (!unique[this.articleDetail.article_category[i].category.category_name_slug]) {
                                    distinct.push(this.articleDetail.article_category[i]);
                                    unique[this.articleDetail.article_category[i].category.category_name_slug] = 1;
                                }
                            }
                        }
                    }
                }
                return distinct
            },
            uniqueHashtags: function () {
                var unique = [];
                var distinct = [];
                if (this.articleDetail.article_category) {
                    for (let i = 0; i < this.articleDetail.article_category.length; i++) {
                        if (this.articleDetail.article_category[i].hashtag !== null) {
                            if (this.articleDetail.article_category[i].hashtag.hash_tag_name_slug !== undefined) {
                                if (!unique[this.articleDetail.article_category[i].hashtag.hash_tag_name_slug]) {
                                    distinct.push(this.articleDetail.article_category[i]);
                                    unique[this.articleDetail.article_category[i].hashtag.hash_tag_name_slug] = 1;
                                }
                            }
                        }
                    }
                }
                return distinct
            },
            updateNoteArticle: function () {
                let content = this.articleDetail.content;
                this.articleDetail.notes.map(x => {
                    content = content.replace(x.uuid, `<p><NOTES id="${x.uuid}"  data-wow="${x.title}" style="font-size: 17px" class="cursor far fa-file-alt pl-1 copy-note"></NOTES></p>`);
                });
                this.articleDetail.article_suggestions.map(x => {
                    if(x.selected_suggestion !== null){
                        if(x.selected_suggestion.status){
                            let html=this.selectedSuggestionHTML(x);
                            content = content.replace(x.uuid,html);
                        }
                    }
                });
                if(content){
                    if(this.articleDetail.font_family){
                        content = content.replaceAll('<h1>', '<h1 style="font-family:  '+this.articleDetail.font_family.h1+'!important; color: '+this.articleDetail.font_family.h1_color+'!important;">')
                        content = content.replaceAll('<h2>', '<h2 style="font-family:  '+this.articleDetail.font_family.h2+'!important; color: '+this.articleDetail.font_family.h2_color+'!important;">')
                        content = content.replaceAll('<h5>', '<h5 style="font-family:  '+this.articleDetail.font_family.pt+'!important; color: '+this.articleDetail.font_family.pt_color+'!important;">')
                        content = content.replaceAll('<blockquote>', '<blockquote style="font-family:  '+this.articleDetail.font_family.blockquote+'!important; color: '+this.articleDetail.font_family.blockquote_color+'!important;">')
                    }
                }
                return content;
            }
        },
        methods: {
            selectedSuggestionHTML(x) {
                let content = '';
                let collapseIcon;
                let collapseData;
                let headingStyle;
                let contentStyle;
                let headingAndBody;
                let endBody;
                let fontSize;
                if (x.content !== "" && x.content !== null) {
                    content = x.content
                } else if (x.selected_suggestion !== null) {
                    if (x.selected_suggestion.content) {
                        content = x.selected_suggestion.content
                    }
                } else {
                    content = '';
                }
                if(x.is_collapse){
                    collapseIcon='fa fa-angle-double-down float-right';
                    collapseData='suggestion-para collapse set-heading-body';
                    headingStyle='padding:8px 10px;width:100%;background-color: #f6f6f6;color:#000000 !important;font-family:Merriweather !important';
                    contentStyle='font-size: 18px;line-height: 2;font-weight: 500;padding: 5px !important; border: 1px solid lightgrey !important';
                    headingAndBody= "Heading & Body";
                    endBody= "Heading & Body";
                    fontSize="font-size:10px;display:none";
                }else{
                    collapseIcon='fa fa-angle-double-down d-none';
                    collapseData='suggestion-para set-heading-body';
                    headingStyle='padding:8px 10px;width:100%;color:#000000 !important;font-family:Merriweather !important;pointer-events:none';
                    contentStyle='font-size: 18px;line-height:2;font-weight: 500;padding: 5px !important';
                    headingAndBody= "Heading & Body";
                    endBody= "Heading & Body";
                    fontSize="font-size:10px;display:none";

                }
                return `<div class="selected-suggestion-details">
                                        <div class="selected-main">
                                            <div class="suggestion-detail d-flex pb-3">
                                                <div class="suggestion-profile">
                                                    <img src="${x.user.profile_pic}" alt="profile=img" class="suggestion-img rounded-circle blog-img mr-2"/>
                                                    <a href="javascript:void(0)" target="_top" class="suggestion-user cursor">
                                                    <label class="mb-0 mr-1 suggestion-user-name">${x.user.name}</label>
                                                    </a>
                                                </div>
                                                <div class="suggestion-endorsements">
                                                    <label class="suggestion-label mb-0 float-right " data-toggle="modal" data-target="${x.endorsement_count !== 0 ? '#endorsementModal_' + x.id : ''}" style="cursor: pointer;">Endorsements: ${x.endorsement_count} </label>
                                                </div>
                                            </div>
                                           <div class="suggestion-content">
                                                <h2 data-toggle="collapse" data-target="${'#collapseTwo' + x.id}" aria-expanded="true" aria-controls="${'collapseTwo' + x.id}" style="${headingStyle}" class="first-heading d-inline-block mr-2 mb-0">${x.suggestion}<i class="${collapseIcon}" aria-hidden="true" style="font-size: 14px;"></i></h2>
                                                <span class="badge badge-pill badge-secondary" style=${fontSize}>${headingAndBody}</span>
                                                <div id="${'collapseTwo' + x.id}" class="${collapseData}" style="${contentStyle}" v-html= ${content} </div>
                                                <span class="badge badge-pill badge-secondary" style=${fontSize}>${endBody}</span>
                                            </div>
                                        </div>
                                  </div>
                                   <div class="EndorsementModal">

                <div class="modal fade" id="${'endorsementModal_' + x.id}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header pb-0 endorsemenHeader">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body pt-0">
                ` + this.endorsementHtml(x) + `
                </div>
                </div>
                </div>
                </div>
                </div>`;
            },

            endorsementHtml(x) {
                let html = '';
                for (var i = 0; i < x.endorsement.length; i++) {
                    if (x.endorsement[i].status) {
                        let image = '../assets/images/no-image.png';
                        if (x.endorsement[i].user.profile_pic) {
                            image = x.endorsement[i].user.profile_pic
                        }
                        html += `<div class="pb-2">
                    <a class="cursor request-img" href="user/profile/${x.endorsement[i].user.slug}" target="_top" data-dismiss="modal" aria-label="Close">
                    <img src="${image}" class="rounded-circle profileImg" alt="userImage">
                    <label class="mb-0 mr-1 profile-name cursor pl-3">${x.endorsement[i].user.name}</label>
                </a>
                </div>`;
                    }
                }
                return html;
            },
            setHeadingBody(content,id){
                $('#'+id).html(content);
            },
            checkSelectedSuggestion(articleSuggestion) {
                if(articleSuggestion.selected_suggestion !== null){
                    if(articleSuggestion.selected_suggestion.status){
                        return !this.articleDetail.content.includes(articleSuggestion.uuid)
                    }else{
                        return false;
                    }
                }
            },
            getCurrentUrl() {
                return this.$route.name
            },
            showSkim() {
                var text = $('.article-content skim');
                if (text.length > 0) {
                    $('.skimButton').removeClass('d-none');
                }
            },
            checkIfExistSkim() {
                return $('.article-content skim').length > 0;
            },
            highlightedText() {
                var text = $('.article-content skim');
                var skim = $('#skimContent span');
                if (skim.hasClass('highLight')) {
                    skim.removeClass('highLight');
                    skim.removeAttr("style");
                    skim.empty();
                } else {
                    for (var i = 0; i < text.length; i++) {
                        skim.append("<li>" + text[i].innerText + "</li>");
                        skim.addClass('highLight');
                    }
                }
            },
            activeClass(category) {
                this.closeModal('preview');
                this.$store.dispatch("category/updateActiveCategory", category);
            },
            layoutDetailPageClass() {
                if (this.articleDetail.layout === 'default') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-12 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3   skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'hide') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3    skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'article_width') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3 mt-3  skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'banner') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3  skimButton  blog-detail';
                }
                if (this.articleDetail.layout === 'oversized') {
                    return 'col-12 col-lg-8 col-md-12 col-sm-8 mx-auto mt-xl-0 mt-lg-0 mt-sm-3  mt-3   skimButton  blog-detail';
                }
            },
            shareFacebookArticle(article) {
                let url = this.$sharePathUpcoming + article.slug
                /*global FB*/
                FB.ui({
                        method: 'share_open_graph',
                        action_type: 'og.likes',
                        action_properties: JSON.stringify({
                            object: {
                                'og:url': url,
                                'og:title': article.title,
                                'og:description': article.description,
                                'og:image': article.featured_image
                            }
                        })
                    },
                    function () {
                    });

            },
            shareTwitterArticle(article) {
                let url = this.$sharePathUpcoming + article.slug
                return 'https://twitter.com/intent/tweet?text=' + url
            },
            showPreviewBgColor(){
                if (this.articleDetail.bg_color !==''){
                    return this.articleDetail.bg_color.replace(/[\r\n]/gm, '')
                }
                return ''
            },
            collapseShow(){
                $('.collapse').addClass('show');
            },
            closeModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
        },
        mounted() {
            document.body.addEventListener('click', (event) => {
                if (event.target.tagName === 'COLLAPSE') {
                    if ($(event.target).children('i').hasClass('fa-angle-double-down')) {
                        $(event.target).children('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up')
                    } else {
                        $(event.target).children('i').addClass('fa-angle-double-down').removeClass('fa-angle-double-up')
                    }
                }
                if (event.target.tagName === 'I') {
                    if ($(event.target).hasClass('fa-angle-double-down')) {
                        $(event.target).removeClass('fa-angle-double-down').addClass('fa-angle-double-up')
                    } else {
                        $(event.target).addClass('fa-angle-double-down').removeClass('fa-angle-double-up')
                    }
                }
            });
        }
    }
</script>

<style scoped>
#preview{
        padding-right:0 !important;
    }
    .preview-modal{
        max-width: 1000px!important;
    }
    .preview .modal-header{
        border-bottom-color:transparent;
    }
    .preview #skimContent{
        background-color: transparent!important;
    }
    .skim-row {
        margin-top: 40px;
    }

    .article {
        margin-bottom: 100px;
        font-size: 18px;
        line-height: 2;
        font-weight: 500;
    }

    .skim-share-note {
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;
    }

    .divider .line {
        border-top: 1px solid black !important;
        width: 20% !important;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .top-nav .nav .nav-item .nav-link {
        color: grey !important;
        font-size: 18px;
        font-family: Roboto;
    }

    .nav-link {
        padding: 0px;
    }

    .article .space {
        display: flex;
        justify-self: flex-start;
        border-bottom: 2px solid black;
    }

    .space a {
        font-size: 18px;
        margin: 0 5px 10px;
    }

    .space a:hover {
        color: black;
    }

    .divider {
        margin-left: 10px;
        clear: both;
    }

    .space .space-nav-link {
        color: #007bff !important;
        font-family: Roboto;
    }

    .skimButton #skimToogle b {
        font-size: 14px!important;
        color: grey;
        word-break: normal;
    }

    #skimContent span li {
        font-size: 18px;
        padding-left: 15px;
    }

    .share-icons {
        display: flex;
        column-gap: 2px;
    }

    .share-icons a {
        vertical-align: text-bottom;
    }


    .show-audio .audio-file{
        width: 100%;
    }

    .show-audio{
        width: 100%;;
    }

    .audio-file {
        height: 25px;
    }
   .preview-close span{
        background: lightgray;
            padding: 5px 10px;
            border-radius: 50%;
   }
   .suggestion-content{
    cursor: pointer;
   }
</style>