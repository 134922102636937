<template>
  <div class="contact-modal">

<!-- Modal -->
<div class="modal fade pr-0" id="contactModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog contact-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mx-auto text-dark contact-heading" id="staticBackdropLabel">Contact Information</h5>
     <button data-dismiss="modal" class="close-btn-sub"><i aria-label="Close" class="fa-solid fa-xmark"></i></button>
      </div>
      <div class="modal-body">
        <p class=" text-center contact-info mb-0">Contact: muhammad@theworded.com</p>
        <p class="mb-0 text-center">
          <a target="_blank" href="https://www.facebook.com/theworded/"><i class="fa-brands fa-facebook-f mr-2 contact-icon"></i></a>
        <a target="_blank" href="https://twitter.com/theworded1"><i class="fa-brands fa-twitter mr-2 contact-icon"></i></a>
          <a target="_blank" href="https://www.youtube.com/channel/UCJrjDtSkUJahlOwYGxOKbFQ"><i class="fa-brands fa-youtube contact-icon mr-2" aria-hidden="true"></i></a>
        </p>
      </div>
     
    </div>
  </div>
</div>
  </div>
</template>

<script>
export default {
name:"ContactModal",
}
</script>

<style>
    .contact-info{
        font-family: 'RALEWAY';
        font-weight: 500;
        color: black;
    }
.contact-heading{
  font-weight: bold!important;
}
.contact-icon{
  color:grey;
}
</style>