export default {

    SET_AUTH_USER(state, response) {

        localStorage.setItem('user', JSON.stringify(response.data.data));
        state.user = response.data.data
        state.isUserLogin = true
        state.token = localStorage.setItem('auth_token', response.data.data.access_token);
        // state.user = localStorage.getItem('user')
    },

    LOGOUT_AUTH_USER(state) {
        localStorage.removeItem('user');
        localStorage.removeItem('auth_token');
        state.user = {}
        state.isUserLogin = false
    },
    UPDATE_USER_PROFILE(state, response) {
        state.user = response.data.data;
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    UPDATE_USER_PROFILE_DETAIL(state, user) {
        if(user !== undefined){
            state.profile = user
            localStorage.setItem('profile', JSON.stringify(user))
        }
    },
    GET_ALL_USERS(state,response){
        state.allUsers = response.data.data;
    },
    UPDATE_USER_IS_BANNED(state,response){
        let index = state.allUsers.findIndex(x => x.slug === response.data.data.slug);
        if (index !== -1) {
            state.allUsers[index].is_banned = response.data.data.is_banned
        }
    },
    UPDATE_USER_MONETIZE_STATUS(state,response){
        let index = state.allUsers.findIndex(x => x.slug === response.data.data.slug);
        if (index !== -1) {
            state.allUsers[index].is_monetize = response.data.data.is_monetize
        }
    },
    HEADING_REQUEST_PERMISSION(state,response){
        let index = state.allUsers.findIndex(x => x.slug === response.data.data.slug);
        if (index !== -1) {
            state.allUsers[index].is_heading = response.data.data.is_heading
        }
    },
    ADMIN_LOGIN(){
        let data = JSON.parse(localStorage.getItem('user'));
        data.is_admin_verified = true;
        localStorage.setItem('user', JSON.stringify(data));
    }


}