<template>
  <div id="UserList">
    <div class="row">
      <div class="col-12 col-sm-12">
        <table ref="myTable2" class="table table-striped table-bordered" style="width:100%">
          <thead>
          <tr>
            <th>Title</th>
            <th>Slug</th>
            <th>Writer Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="article in allArticles" :key="article.id">
            <td>{{article.title}}</td>
            <td>{{article.slug}}</td>
            <td>{{article.user.name}}</td>
            <td>{{article.status.status}}</td>
            <td>
                         <span v-if="article.is_deleted" @click="archiveArticle(article)"
                               class="btn btn-sm btn-danger cursor ml-2">Archived</span>
              <span v-else class="btn btn-sm btn-success badges cursor ml-2" @click="archiveArticle(article)">Archive</span>
              <span v-if="article.is_monetize" @click="monetize(article)"
                    class="btn btn-dark btn-sm cursor ml-2">Monetized</span>
              <span v-else @click="monetize(article)" class="btn btn-info btn-sm cursor ml-2">Monetize</span>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';
  import 'datatables.net';
  import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
  import {mapGetters} from "vuex";
  export default {
    name:"UserListing",
    data(){
      return{
      }
    },
    computed:{
      ...mapGetters({allArticles:"article/getAllArticles"})
    },
    methods:{
      allArticlesList(){
        this.$store.dispatch("article/getAllArticle").then(()=>{
          $(this.$refs.myTable2).DataTable({
            paging: true,
            searching: true,
            ordering: true
          })
        });
      },

      archiveArticle(article){
        let payload = {
          slug: article.slug
        }
        console.log("testing",payload);

        this.$store.dispatch('article/archiveArticleByAdmin',payload);
      },
      monetize(article) {
        let payload = {
          slug: article.slug,
        }
        this.$store.dispatch("article/articleMonetizeByAdmin", payload);
      },
    },
    mounted() {
      this.allArticlesList()
    }
  }

</script>
