<template>
    <audio :src="showAudio" :class="audioHeight" type="audio/ogg" controls
           controlsList="nodownload nofullscreen">
        <source :src="showAudio" type="audio/ogg">
        Your browser does not support the audio element.
    </audio>
</template>
<script>
    export default {
        name: "Audio",
        props: {
            audio: {
                type: String,
                default: ""
            },
            audioHeight: {
                type: String,
                default:"audio-height"
            }
        },
        computed: {
            showAudio(){
                return this.audio
            }
        }
    }
</script>
<style scoped>
.audio-file{
    width:100%;
}
.audio-height {
    height: 25px;
}
</style>