import $ from "jquery";

const helpers = {
  successMessages(vm, success) {
    vm.$bvToast.toast(success.message, {
      title: "Success",
      variant: "success",
      solid: true,
      toaster: "b-toaster-top-center"
    });
  },
  errorMessage(vm,error){
    let message = "";
    if (error.response.data.status === 400) {
      message = error.response.data.message;
    }
    if (error.response.data.status === 422) {
      let validationError = error.response.data.errors
      message = validationError[Object.keys(validationError)[0]][0];
    }
    if (error.response.status === 403) {
      message = error.response.data.message;
    }
    if (error.response.status === 404) {
      message= "url not found" ;
    }
    if (error.response.status === 500) {
      message = "Server Error";
    }
    vm.$bvToast.toast(message, {
          title: "Error",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center"
    });
  },
  checkCardLine(index,total){
    if(total >= 4 && index === 3){
      return false;
    }
    if(total === 3 && index === 2){
      return false;
    }
    if(total === 2 && index === 1){
      return false;
    }
    return !(total === 1 && index === 0);
  },
  fixedSideBarCategory(vm,scrollCategoryClass = '',navBarContainerId='',isScrollId = '',delta=5){
    $(window).scroll(function () {
      var navbarHeight = $('.'+scrollCategoryClass).outerHeight();
      // var navbarHeight = $('.scrollid').outerHeight();
      var currentScroll = $(this).scrollTop();
      if(Math.abs(vm.previousScroll - currentScroll) <= delta)
        return;
      if (currentScroll > vm.previousScroll && currentScroll > navbarHeight){
        // Scroll Down
        if(navBarContainerId !== ''){
          if(!$("#"+navBarContainerId).hasClass("nav-up")){
            $("#"+navBarContainerId).removeClass('nav-down').addClass('nav-up');
          }
        }
        if(isScrollId !== ''){
          if(!$("#"+isScrollId).hasClass("nav-up")) {
            $("#" + isScrollId).removeClass('nav-down').addClass('nav-up');
          }
        }
      } else {
        // Scroll Up
        if(currentScroll + $(window).height() < $(document).height()) {
          if(isScrollId !== '') {
            if(!$("#"+isScrollId).hasClass("nav-down")) {
              $("#" + isScrollId).addClass('nav-down').removeClass('nav-up');
            }
          }
          if(navBarContainerId !== ''){
            if(!$("#"+navBarContainerId).hasClass("nav-down")) {
              $("#" + navBarContainerId).addClass('nav-down').removeClass('nav-up');
            }
          }
        }
      }
      vm.previousScroll = currentScroll;
    });
  }
};
export default helpers;
