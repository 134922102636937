<template>
    <div class="tweet">
        <!-- Modal -->
        <div class="modal  pr-0 tweet-z-index" :id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title tweetTitle" id="exampleModalLabel">Insert Embed Codes</h4>
                        <button @click="clickButtonToCloseModal(id)" type="button" class="close"
                                data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
                        <div class="modal-body">
                            <div class="form-group">
                                <input
                                        v-model='tweetLink'
                                        name="tweet link"
                                        value=""
                                        id="tweetEmbed"
                                        type="text" placeholder="Embed Code" class="form-control"/>
                                <span class="socialLink">
                                      <a href="https://embed.fun/" target="_blank" class="link">embed.fun (embed equations)</a>,
                                    <a href="https://twitter.com/" target="_blank " class="link">twitter.com</a>,
                                    <a href="https://www.youtube.com/" target="_blank" class="link">youtube.com</a>,
                                    <a href="https://giphy.com/" target="_blank" class="link">giphy.com</a>,
                                    <a href="https://imgur.com/" target="_blank" class="link">imgur.com</a>,
                                    <a href="https://open.spotify.com/" target="_blank" class="link">spotify.com</a>,
                                    <a href="https://soundcloud.com/" target="_blank" class="link">soundcloud.com</a>,
                                    <a href="https://www.instagram.com/" target="_blank" class="link">instagram.com</a>,
                                    <a href="https://www.linkedin.com/" target="_blank" class="link">linkedin.com</a>,
                                    <a href="https://www.google.com/maps" target="_blank"
                                       class="link">google.com/maps</a>
                                    </span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="tweetBtn btn-secondary text-muted">Embed
                                Code
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import Helper from "../../helper";

    export default {
        name: "TweetModal",
        data() {
            return {
                tweetLink: "",
                isValidate: false,
                message: '',
                website: 0,
                element:''
            }
        },
        props:{
          id:String
        },
        methods: {
            clickButtonToCloseModal(id) {
                $("#" + id).modal('hide');
                $(".modal-backdrop").remove();
                $('body').removeClass('modal-open');
            },
            checkEmbed(embed) {
                return this.tweetLink.includes(embed);
            },
            validateBeforeSubmit() {
                if (this.tweetLink === "") {
                    return Helper.toast(this, 'Alert', 'Please enter the embed code first', 'danger')
                }
                if (
                    (this.checkEmbed('https://platform.twitter.com/widgets.js') && this.checkEmbed('<blockquote class="twitter-tweet">')) ||
                    this.checkEmbed('src="https://www.youtube.com/embed') ||
                    this.checkEmbed('src="https://giphy.com/embed') ||
                    (this.checkEmbed('<blockquote class="imgur-embed-pub"') && this.checkEmbed('src="//s.imgur.com/min/embed.js"')) ||
                    this.checkEmbed('src="https://open.spotify.com/embed') ||
                    (this.checkEmbed('src="https://w.soundcloud.com/player/?url=') && this.checkEmbed('<a href="https://soundcloud.com')) ||
                    this.checkEmbed('<iframe src="https://www.linkedin.com/embed/feed/update') ||
                    (this.checkEmbed('<blockquote class="instagram-media"') && this.checkEmbed('https://www.instagram.com')) ||
                    this.checkEmbed('<iframe src="https://www.google.com/maps/embed') ||
                    this.checkEmbed('<iframe src="https://math.embed.fun/embed')
                ) {
                    this.isValidate = true
                }
                if (this.isValidate) {
                    this.$emit('emitTweetLink', '<div class="embed-code">' + this.tweetLink + '</div><br>')
                    this.clickButtonToCloseModal(this.id)
                    this.tweetLink = ''
                    this.isValidate = false;
                    return
                }
                return Helper.toast(this, 'Alert', 'Please Enter valid Embed Code', 'danger')
            }
        },
        watch: {}
    }
</script>

<style scoped>
.tweet-z-index{
z-index: 10000000 !important;
}
    .modal-dialog {
        width: 700px;
    }

    .tweetTitle {
        font-size: 1.35rem;
        font-weight: 500;
    }

    .tweetBtn {
        background-color: #f5f5f5 !important;
        border: 1px solid #007bff !important;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        border-radius: 0.25rem
    }

    .socialLink .link {
        color: gray;
    }
</style>