<template>
  <div class="article-page">
     <ArticleUsersNavbar  title="Home"/>
  </div>
</template>

<script>
import ArticleUsersNavbar from "../components/admin/NavBar/ArticleUsersNavbar.vue";
export default {
 components:{
     ArticleUsersNavbar,
 },

}
</script>

<style>

</style>