<template>
    <div class="row main-div p-2" style="align-items: flex-start;">
        <div class="col-12">
            <div class="d-flex float-right" style="font-size:16px;">
            <span>
            <i v-if="articleSuggestion.article.status.id !== 7" @click="isRead(articleSuggestion)" :style="iconColor(articleSuggestion)" class="far fa-file-alt" data-toggle="modal" :data-target="'#articleSuggestionNote'+articleSuggestion.id"></i>
            </span>
            </div>
        </div>
        <div class="col-sm-3 all-heading-image pr-0">
            <input v-model="isSelectedSuggestions" :disabled="isDisabled"  type="checkbox"/>
            <img :src="articleSuggestion.user.profile_pic" alt="image" class="cursor rounded-circle all-rounded-img ml-2"/>
        </div>
        <div class="col-sm-9 all-heading-content pl-0">
            <p v-if="getCurrentUrl() === 'writeArticle'" style="font-size: 12px;margin-bottom: 5px;">{{articleSuggestion.user.name}}</p>
            <div v-if="this.articleSuggestion.content" class="mb-0 text-break text-wrap" style="cursor: pointer"  data-toggle="modal" :data-target="'#requestHeadingDetail'+articleSuggestion.id">{{articleSuggestion.suggestion}}</div>
            <div v-else class="mb-0 text-break text-wrap">{{articleSuggestion.suggestion}}</div>
            <div class="mb-0 total-endorse-count mt-3"><a data-toggle="modal" :data-target="articleSuggestion.endorsement_count!==0 ? '#endorsementModal'+articleSuggestion.id:''" class="endorse-link">
                {{articleSuggestion.endorsement_count}} Endorsements</a>
            </div>
        </div>
        <ArticleSuggestionNoteModal :articleSuggestion="articleSuggestion" @emitSubmitSuggestionNote="emitSubmitSuggestionNote"/>
        <HeadingRequestDetailModal v-if="articleSuggestion" :suggestion="articleSuggestion"/>
        <EndorsementUserModal  :suggestion="articleSuggestion"/>

    </div>
</template>
<script>
import ArticleSuggestionNoteModal from "../Modals/ArticleSuggestionNoteModal";
import HeadingRequestDetailModal from "../Modals/HeadingRequestDetailModal";
import EndorsementUserModal from "../Modals/EndorsemenUserModal";
import {mapGetters} from 'vuex';
    export default {
        name: 'ArticleSuggestion',
        props: {
            articleSuggestion: Object,
            checked: Boolean
        },
       components:{
        ArticleSuggestionNoteModal,
           HeadingRequestDetailModal,
           EndorsementUserModal
        },
        data() {
            return {
                isSelectedSuggestions: false,
                contentExist:false
            }
        },
        computed: {
            ...mapGetters({getAuthUser: 'auth/getAuthUser'}),
            isDisabled(){
                return this.articleSuggestion.article.status.id === 7
            }
        },
        methods:{
            isRead(suggestion){
                if(suggestion.is_notes_read > 0) {
                    let payload = {
                        suggestion_id : suggestion.id,
                    }
                    this.$store.dispatch('article/readSuggestionNotes',payload);
                }
            },
            iconColor(suggestion){
                if(suggestion.is_notes_read > 0){
                    return 'color:blue'
                }else{
                    return 'color:black'
                }
            },
            getCurrentUrl(){
                return this.$route.name
            },
            emitSubmitSuggestionNote(note){
              let payload = {
                    note:note,
                    suggestion_id:this.articleSuggestion.id,
                    user_id:this.getAuthUser.id
                }
                this.$store.dispatch('article/saveArticleSuggestionNote',payload);
            }
        },
        mounted() {
            this.isSelectedSuggestions = this.checked
            let object = {status: this.checked, id: this.articleSuggestion.id}
            this.$emit('emitIsSelectedSuggestions', object);
        },
        watch: {
            isSelectedSuggestions(val) {
                if (val === "") {
                    val = false
                }
                let object = {status: val, id: this.articleSuggestion.id}
                this.$emit('emitIsSelectedSuggestions', object);
            }
        }
    }
</script>

<style>
.main-div {
        background-color: #f8f9fa;
    }
    .all-heading-request .all-heading {
        color: rgba(0, 0, 0, 0.54);
        font-size: 28px;
        font-weight: 400;
        line-height: 1.2;
        font-family: 'Oswald';
        text-align: center;
    }
    .all-heading-request .all-heading-content {
        line-height: 1;
    }

    .all-heading-image .all-rounded-img {
        height: 40px;
        width: 40px;
    }

    .all-heading-content .total-endorse-count .endorse-link {
        font-size: 10px;
        color: #808080;
        font-family: "Raleway", sans-serif;
    }

    .all-heading-request .add-up-button {
        text-align: center;
    }
    .text-break{
    font-family: "Raleway", sans-serif;
    font-size: 14.4px;
    color:#212529;
    }
    .custom_btn{
font-family: "Raleway", sans-serif;
font-size: 12.6px;
    }
</style>