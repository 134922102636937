<template>
  <div class="selected-suggestion-details">
    <div class="selected-main">
        <div class="suggestion-detail d-flex pb-3">
            <div class="suggestion-profile">
                <img :src="articleSuggestion.user.profile_pic" alt="profile=img" class="suggestion-img rounded-circle blog-img mr-2"/>
                <a href="javascript:void(0)" target="_top" class="suggestion-user cursor">
                <label class="mb-0 mr-1 suggestion-user-name">{{articleSuggestion.user.name}}</label>
                </a>
            </div>
            <div class="suggestion-endorsements">
                <label class="suggestion-label mb-0 float-right " data-toggle="modal" :data-target="articleSuggestion.endorsement_count!==0 ? '#endorsementModal'+articleSuggestion.id:''" style="cursor: pointer;">Endorsements: {{articleSuggestion.endorsement_count}} </label>
            </div>
        </div>
        <div class="suggestion-content">
            <div v-if="articleSuggestion.is_collapse">
                <p  class="badge badge-pill badge-secondary" style="font-size:10px">Heading & Body</p>
                <h2  class="first-heading d-inline-block mr-2 mb-0 cursor" data-toggle="collapse" :data-target="'#collapseOne'+articleSuggestion.id" aria-expanded="true"
                     :aria-controls="'collapseOne'+articleSuggestion.id" style="padding:8px 10px;width:100%;background-color: #f6f6f6;">{{articleSuggestion.suggestion}}
                    <i class="fa fa-angle-double-down float-right" style="font-size:14px;margin-top: 10px" aria-hidden="true"></i></h2>
                    <p :id="'collapseOne'+articleSuggestion.id" class="suggestion-para collapse"  v-html="articleSuggestion.content ? articleSuggestion.content : articleSuggestion.selected_suggestion.content" style="padding: 5px !important; border: 1px solid lightgrey !important;"></p>
            </div>
            <div v-else>
                <h2 data-v-6837b5ff="" class="first-heading d-block">{{articleSuggestion.suggestion}}</h2>
                <p  class="badge badge-pill badge-secondary" style="font-size:10px">Heading & Body</p>
                <p class="suggestion-para" v-html="articleSuggestion.content ? articleSuggestion.content : articleSuggestion.selected_suggestion.content"></p>
                <span class="badge badge-pill badge-secondary" style="font-size:10px">End of Body</span>
            </div>
        </div>
    </div>
      <EndorsementUserModal  :suggestion="articleSuggestion"/>
  </div>
</template>

<script>
    import EndorsementUserModal from "../Modals/EndorsemenUserModal.vue";

    export default {
    name:"SelectedSuggestionDetails",
        components: {
            EndorsementUserModal,
        },
    props:{
        articleSuggestion:Object
    },

    }
</script>

<style>
.suggestion-img{
    width: 36px;
    height: 36px;
}
.suggestion-detail{
    align-items: center;
    justify-content: space-between;
    
}
.suggestion-user{
    color: #212529 !important;
}
.suggestion-detail{
    font-size: 18px;
    line-height: 2 !important;
    font-weight: 400 !important;
    font-family: "Merriweather" !important;
    color: #212529 !important;
}
.suggestion-endorsements .suggestion-label{
 font-size: 18px;
}
.suggestion-label{
    font-size: 18px;
    line-height: 2 !important;
    font-weight: 400 !important;
    font-family: "Roboto";
    color: #212529 !important;
}
 .suggestion-content .suggestion-para{
        font-size: 18px;
        line-height: 2 !important;
        font-weight: 400 !important;
        font-family: "Merriweather" !important;
        color: #212529 !important;
}
.suggestion-user .suggestion-user-name{
    font-family: "Roboto";
}
</style>