<template>
  <div class="EndorsementModal">
   
    <!-- Modal -->
    <div v-if="suggestion.endorsement.length >0" class="modal fade" :id="'endorsementModal'+ suggestion.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header pb-0 endorsemenHeader">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body pt-0" style="text-align: initial">
                       <div v-for="endorsement in suggestion.endorsement" :key="endorsement.id+' '+Math.random()" class="pb-2" >
                           <router-link v-if="endorsement.status"  target="_top" class="cursor request-img" :to="{name:'userProfile',params:{slug:endorsement.user.slug}}"  data-dismiss="modal" aria-label="Close">
                              <img v-if="endorsement.user.profile_pic" :src="endorsement.user.profile_pic" class="rounded-circle profileImg" alt="userImage">
                              <img v-else src="https://theworded.mashghol.com/img/no-image.9ce6cc12.png" class="rounded-circle profileImg" alt="userImage">
                              <label class="mb-0 mr-1 profile-name cursor pl-3">{{endorsement.user.name}}</label>
                           </router-link>
                       </div>
                    </div>
                </div>
            </div>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from 'vuex';
export default {
    name:"EndorsementUserModal",
    data(){
        return{

        }
    },
    props:{
      suggestion:Object,
    },
    computed:{
          ...mapGetters({articleDetail:'article/getArticleDetails'})
    },
    methods:{

    },

}
</script>

<style>
.endorsemenHeader{
    border-bottom: none!important;
}
.request-img .profileImg{
    height: 36px;
    width: 36px;
}
.request-img .profile-name{
    font-size: 13px;
    color:grey;
}
</style>