<template>
    <div class="allUpcoming">
        <div class="row mt-4">
            <div class="col-lg-8 col-md-12 col-sm-12 order-lg-first order-md-last">
                <div v-if="(getType==='home' || getType==='customHomePage') && activeCategory.id!==0">
                    <br>
                    <Subscriber :isLogin="isLogin" :category="activeCategory" :subscriber="activeCategory.category_name"
                                :total="activeCategory.subscribers+' subscribers '"/>
                </div>
                <SubHeading category="Upcoming" link=""/>
                <br>
                <div v-for="cardData in getAllUpcomingArticles" :key="cardData.id+'_'+Math.random()">
                    <BlogCard :isUpcoming="true" :isEdit="isEditCheck(cardData)" :cardData="cardData" @emitSkimOpenModel="emitSkimOpenModel"/>
                </div>
                <infinite-loading
                        spinner="spiral"
                        @infinite="getAllArticlesForUpcoming"></infinite-loading>
            </div>
            <div class="col-lg-4 col-m-12 col-sm-12 order-lg-last order-md-first">
                <CountrySelect :isCustom="getType" :isLogin="isLogin" @updateCountyId="updateCountyId"/>
                <br>
                <BlogCategory @emitActiveCategory="emitActiveCategory"/>
            </div>
        </div>
        <SkimModal type="upcoming" :isSkim="isSkim" :article="cardData" :isLogin="isLogin" @emitIsSkimData="emitIsSkimData" @emitCloseModel="emitCloseModel" />
    </div>
</template>

<script>
    // import Card from "../components/Cards/Card";
    import BlogCard from "../components/BlogCard/BlogCard";
    import BlogCategory from '../components/BlogCategory/BlogCategory'
    import CountrySelect from '../components/CountrySelect/CountrySelect'
    import SubHeading from "../components/Headings/SubHeading";
    import InfiniteLoading from "vue-infinite-loading";
    import SkimModal from "../components/Modals/SkimModal";
    import Subscriber from "../components/Subcribers/Subscriber";

    import {mapGetters} from 'vuex';
    import $ from "jquery";

    export default {
        name: "AllUpcoming",
        metaInfo: {
            meta: [
                { name: 'viewport', content: 'width=device-width , initial-scale=1.0' },
            ],
            title: 'The Worded',
            titleTemplate: '%s | Upcoming Article'
        },
        components: {
            BlogCard,
            SubHeading,
            InfiniteLoading,
            BlogCategory,
            CountrySelect,
            SkimModal,
            Subscriber
        },
        data() {
            return {
                category: {
                    "id": 0,
                    "user_id": 0,
                    "country_id": 0,
                    "category_name": "home",
                    "level": 0
                },
                isSkim: false,
                cardData: {
                    id: 0,
                    slug: "",
                    total_percentage_skim: 0,
                    user:{
                        id:0,
                        slug:"",
                        profile_pic:"",
                        sec_1: '',
                        name: '',
                        total_user_subscribers: 0,
                        is_subscribe:false,
                        description: '',
                        social_user: {
                            fb_url: '',
                            twitter_url: '',
                            youtube_url: ''
                        },
                    }
                },
                skimData:[]
            };
        },
        computed: {
            ...mapGetters({
                getAllUpcomingArticles: 'article/getAllUpcomingArticles',
                getAllUpcomingPage: 'article/getAllUpcomingPage',
                defaultCountry: "country/defaultCountry",
                getCategories: "category/getCategories",
                activeCategory: "category/getActiveCategory",
                isLogin: "auth/getIsUserLogin",
                getAuthUser: 'auth/getAuthUser',
                getHomeCategory: "category/getHomeCategory",
                getType: "category/getType"
            })
        },
        created() {
            document.body.style = ''
        },
        methods: {
            getAllArticlesForUpcoming($state) {
                let payload = {
                    page: this.getAllUpcomingPage,
                    $state: $state,
                    countryId: this.defaultCountry.id,
                    categoryId: this.activeCategory.id,
                    type: this.getType
                }
                this.$store.dispatch('article/getAllUpcomingArticles', payload)
            },
            emitActiveCategory() {
                this.$store.dispatch('category/updateActiveCategory', this.getHomeCategory).then(() => {
                    this.$router.push('/home')
                });
                // this.$store.dispatch("article/setCategoryPageNumber", 1);
                // this.category = category
                // this.getCategoryBreakingAndUpComing(category);
                // this.getArticles();
            },
            updateCountyId() {
                this.$store.dispatch('category/updateActiveCategory', this.getHomeCategory).then(() => {
                    this.$router.push('/home')
                });
            },
            selectedCategories(countryId) {
                let payload = {
                    id: countryId,
                    type: this.getType
                }
                this.$store.dispatch("category/getCategories", payload);
            },
            emptyCategories() {
                this.$store.dispatch("category/emptyCategories");
            },
            emptyTrendingHashtag() {
                this.$store.dispatch("category/emptyTrendingHashtag");
            },
            emitSkimOpenModel(article){
                this.isSkim = true;
                this.cardData = article;
                $('#skim-modal').modal('show');
            },
            emitCloseModel() {
                $('#skim-modal').modal('hide');
                this.isSkim = false
            },
            emitIsSkimData(data){
                this.cardData.total_views_skim = data.total_views_skim
            },
            isEditCheck(upcomingData){
                if(this.getAuthUser !== undefined){
                    if(this.getAuthUser.id !== undefined){
                        return upcomingData.user.id===this.getAuthUser.id
                    }
                }
                return false
            },
        },
        mounted() {
            this.emptyCategories();
            this.emptyTrendingHashtag();
            this.getAllArticlesForUpcoming();
            this.selectedCategories(this.defaultCountry.id);
            this.$store.dispatch("article/setCategoryPageNumber", 1);
            this.$store.dispatch("article/setUpcomingPageNumber", 1);
        }
    };
</script>
<style>
    .allUpcoming{
        padding-top: 90px;
    }
</style>

